import React from 'react';
import { createStore } from 'redux';
import Thead from '../../../Table/thead';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';


export default function Spraying({id_role, spraying, typeArea, id, user_name}) {
    const broca_list = spraying;
    return broca_list.map(item => {
        return <tr key={item.id} >
            <td>{item.name}</td>
            <td style={{
                display: 'flex', 
                flexDirection: 'row', 
                padding: '0'
            }}>{
                item.avaliations.map(desc => {
                    return <div 
                    style={{
                        display: 'flex',
                        marginTop: '0',
                        marginBottom: '0',
                        padding: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '15%',
                        height: '58px',
                        borderRight: '2px solid #E5E7EA',
                        fontSize: '14px'
                        }}> 
                    {desc.completed ? desc.avaliation : '-'}</div>
                })
                }
            </td>
            <td>{item.date}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#/">
                            <i className="fas fa-ellipsis-h" />             
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>            
                            <Link to={`/broca/detalhes/${typeArea}/${item.id}`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>                              
                        </div>
                    </li>
                </ul>		
            </td>    
        </tr>
    });
}