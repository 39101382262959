import axios from "axios";
import {URL} from '../../baseURL/baseURL'
import { refresh } from './auth'
import store from '../../store/index'
import jwt from 'jsonwebtoken'

const baseURL = URL;
// const baseURL = "http://191.252.200.82:8000/api/";
// const baseURL = "http://191.252.200.89:8000/api/";
const baseIBGE = "https://servicodados.ibge.gov.br/api/v1/localidades/";
const baseVIACEP = "https://viacep.com.br/ws/";
// const jwt_secret = 'SHNrXx9qQ81qNbzGPPw0pGgRagJ0aInG3l7X4U4ecF9KGz1P002wq0RpkTIhy8gc'
const jwt_secret = 'kVpQQnW5Ra0s6gJmQeG4PWP1xpoNPxwkUEYklPjA4654tckjZbNyBFirvRR32t9g'
let token = localStorage.getItem('token')

const app = axios.create({
    baseURL,
});

app.interceptors.request.use(
    (config) => {
        return {
        ...config,
        headers: {
            ...config.headers,
            common: {
            ...config.headers.common,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
        };
    },
    (error) => Promise.reject(error)
);

// Response interceptor for API calls
// app.interceptors.response.use((response) => {
//     return response
//     }, 
    
//     async function (error) {
//         const originalRequest = error.config;
//         if (!originalRequest._retry) {
//                 if(token){
//                     jwt.verify(token, jwt_secret, async function(err, decoded){
//                     if(err){
//                         if(err.name === 'TokenExpiredError'){                      
//                             originalRequest._retry = true;
//                             const access_token = await refresh().then(resp => {
//                                 originalRequest.headers.Authorization = 'Bearer ' + resp.data.access_token;
//                                 localStorage.setItem('token', resp.data.access_token)
//                                 store.dispatch({
//                                     type: "SET_LOGIN", 
//                                     payload:resp.data.user, 
//                                     password_confirmation:resp.data.user.password_confirmation, 
//                                     consultant:resp.data.user.consultant, 
//                                     id_user:resp.data.user.id_user
//                                 })
//                             })
//                             .then(resp => {
//                                 return app(originalRequest);
//                             })
//                         }                  
//                     }
//                 })
//             }
//         }
//         return Promise.reject(error);               
//     }
// );
  
const auth = axios.create({
    baseURL,
});

const ibge = axios.create({
    baseURL:baseIBGE,
});

const viacep = axios.create({
    baseURL:baseVIACEP,
});

export { auth, app, ibge, viacep };