const MenssageReducer = (state = {}, actions)=>{
    switch(actions.type){
        case 'menssage':
            return{
                ...state,
                menssage:actions.payload
            }
        default:
            return state;
    }
}
export default MenssageReducer;