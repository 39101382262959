const PhomaReducer = (state = {}, actions) => {
    switch (actions.type) {
        //  Phoma Admin
        case 'PhomasList':
            return {...state, phomasList: actions.payload}

        case 'GetPhomas_Desc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'GetPhomas_Asc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'qtd_per_page_Phomas':
            return {...state, qtd_per_page: actions.payload}

        case 'pesquisaPhomas':
            return {...state, pesquisa: actions.payload}

        case 'clearPesquisaPhomas':
            return {...state, pesquisa: actions.payload}

        case 'changeTypeArea':
            return {...state, type_area: actions.payload}
        // Phoma Consultor
        case 'get_areas_phomo':
            return {
                ...state,
                areas: actions.payload
            }
        case 'Phomas':
            return {
                ...state,
                phomas: actions.payload
            }
        case 'set_alert_modal_phoma':
            return {
                ...state,
                alertModal: actions.payload
            }
        default:
            return state;
    }
}
export default PhomaReducer;