import { getenderecocep, cidadesporUFAPI, getUFSAPI  } from '../../../../Services/endereco'
import { get_producer } from '../../../../Services/produtor'

/**
 * Função que pega o nome digitado
 * @param {*} event 
 */
export const changeNome = event => {
    return[{
        type:'changeNomeProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que pega o email digitado
 * @param {*} event 
 */
export const changeEmail = event => {
    return[{
        type:'changeEmailProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que pega o CPF digitado
 * @param {*} event 
 */
export const changeCPF = event => {
    return[{
        type:'changeCPFProdutor',
        payload:event.target.value.replace('.','').replace('.','').replace('-','')
    }]
}

/**
 * Função que pega a data de nascimento digitada
 * @param {*} event 
 * @returns payload
 */
export const changeDataNasc = event => {
    return[{
        type:'changeDataProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que pega o telefone digitado
 * @param {*} event 
 */
export const changeTelefone = event => {
    return[{
        type:'changeTelefoneProdutor',
        payload:event.target.value.replace('(','').replace(')','').replace(' ', '').replace('-', '')
    }]
}

/**
 * Função que pega o CEP digitado
 * @param {*} event 
 */
export const changeCep  = event => {
    return [
        dispatch => {
            dispatch({
                type: 'changeCEPProdutor',
                payload: event.target.value.replace('-', '')

            })      
    },  dispatch => {
            setTimeout(()=>{
                dispatch(
                    getToCep()
                )
            },3000)
        }
    ]
}

/**
 * Função que pega o logradouro digitado
 * @param {*} event 
 */
export const changeLogradouro = event => {
    return[{
        type:'changeLogradouroProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que pega o numero digitado
 * @param {*} event 
 */
export const changeNumero = event => {
    return[{
        type:'changeNumeroProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que pega a UF selecionado
 * @param {*} event 
 */
export const changeUF = event => {
    return[{
        type:'changeUFProdutor',
        payload:event.target.value
    },getCitys()]
}

/**
 * Função que pega a cidade selcionada
 * @param {*} event 
 */
export const changeCidade = event => {
    return[{
        type:'changeCidadeProdutor',
        payload:event.target.value

    }]
}

/**
 * Função que pega o bairro digitado
 * @param {*} event 
 */
export const changeBairro = event => {
    return[{

        type:'changeBairroProdutor',
        payload:event.target.value
    }]
}

/**
 * Função que retorna o complemento digitado
 * @param {*} event 
 */
export const changeComplemento = event => {
    return[{
        type:'changeComplementoProdutor',
        payload:event.target.value
    }]
}

export const getToCep = event => {
    return(dispatch,getState) => {
        const cep = getState().produtor.cep
        if(cep){
            getenderecocep(cep)
            .then(resp => dispatch({
                type:'getToCepProdutor',
                logradouro:resp.data.logradouro,
                bairro:resp.data.bairro,
                cidade:resp.data.localidade,
                uf:resp.data.uf,              
            }))
            .then(resp => (
                dispatch(getCitys())
            ))

        }
    }
}

export const getUFS = () => {
    return dispatch => {
        getUFSAPI()
        .then(resp => dispatch({type:'Get_UfsProdutor', payload:resp.data}))
        
    }
}

export const getCitys = () => {
    return (dispatch,getState) => {
        const state_uf = getState().produtor.uf
        cidadesporUFAPI(state_uf)
        .then(resp => dispatch({type:'Get_CitysProdutor', payload:resp.data}))
    }
}

/**
 * 
 * @param event 
 * Função que define a quantidade de itens à ser exibido por página
 */
export const qtdPerPage = event => {
    return [{
        type: 'qtdPerPageProdutor',
        payload: event.target.value
    },
    // get(1)
    ]
}

/**
 * 
 * @param event 
 * Função que pega o valor digitado no filtro
 */
export const getChangeFiltro = event => {
    return [{
        type: 'changeFiltroProdutor',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 * Função que limpa o filtro
 */
export const clearFiltro = event => {
    return [{
        type: 'ClearFiltroProdutor',
        payload: ''
    },
    //  get(1)
    ]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().produtor.order
        const th = getState().produtor.th
        const qtd_per_page = getState().produtor.qtd_per_page
        const pesquisa = getState().produtor.pesquisa || ''       
      
        get_producer(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'get_producer', payload:resp.data}))

    }]
}


//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().produtor.asc
      
        if(asc === true){
            dispatch({type:'GetProdutor_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetProdutor_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_produtor',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaProdutor',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaProdutor',
        payload:''
    },get(1)]
}

export const editView = (data) => {
   return dispatch => {
       if(data){
           dispatch({

                type:'editViewProdutor',
                nome: data.name,
                data_nascimento: data.birth_date,
                cpf: data.document,
                cep: data.zipcode,
                uf: data.state,
                cidade: data.city,
                bairro: data.neighborhood,
                logradouro: data.street,
                numero:data.number,
                complemento: data.complement,
                email: data.email,
                telefone: data.number_tel,
                
           })

       }
   }
          
}

export const clearInput = event => {
    return {

        type:'clearInputProdutor',
        nome:'', 
        data_nascimento:'', 
        cpf:'', 
        cep:'', 
        uf:'', 
        cidade:'', 
        bairro:'', 
        logradouro:'', 
        numero:'',
        complemento:'', 
        email:'', 
        telefone:''
    } 

}

