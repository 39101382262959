import React, { useRef } from 'react'
import Table from '../../../../Table/table'
import Thead from '../../../../Table/thead'
import Tbody from '../../../../Table/tbody'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import Input from '../../../../Forms/input'
import Alert from '../../../../Alerts/alert'

export default function ModelTable(props) {
    const selectRefProductAdd = useRef(null);
    const selectRefCategoryAdd = useRef(null);
    const selectRefEnterpriseAdd = useRef(null);
    const selectRefUnitAdd = useRef(null);

    return (
        <React.Fragment>
            <Table 
                className="table" 
                title={props.title}
                // component={
                //     props.action !== 'view' &&
                //     <Alert type="warning">Preencher campo "Dose" considerando um "volume de Calda" de 400 litros</Alert>

                // }
            >
                <Thead>
                    <th>Nome Comercial</th>
                    <th>Classe</th>
                    <th>Empresa</th>
                    <th>Unidade de Medida</th>
                    <th>Dose/ha</th>
                    <th>Ações</th>
                </Thead>
                <Tbody> 
                    <tr>
                        <td width="20%">      
                            <Select
                                value={props.productAdd}
                                label="Single select"
                                options={props.optionsProduct}
                                onChange={props.changeProduct}
                                isDisabled={props.disabled}
                                ref={selectRefProductAdd}              
                            />
                            
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "relative"
                                    }}
                                    value={props.productAdd ? props.productAdd.value : props.productAdd}
                                    onFocus={() => selectRefProductAdd.current.focus()}
                                    required={true}
                                />
                            
                            {/* {props.validationProduct && <div className="mt-3"><Alert type="warning">{props.validationProduct}</Alert></div>} */}
                            {props.productAdd.value === 'Novo Produto' && (
                                <div className="row mt-4">
                                    <div className="col-md-11" style={{display: 'inline-block', width: '30%'}}>
                                        <Input 
                                            type="text"
                                            placeholder="Novo Produto"
                                            value={props.valueNewProduct}
                                            onChange={props.changeNewProduct}
                                            
            
                                        />
                                    </div>
                                    <div className="col-md-1" style={{paddingTop:'3px'}}>

                                        <button disabled={props.disabledButton} type="button" className="btn btn-primary" onClick={props.onClick}><span className="fas fa-plus"></span></button>
                                    </div>

                                </div>
                                
                            
                            )}
                        </td>
                        <td width="20%">      
                            <Select
                                value={props.valueCategoryAdd}
                                label="Single select"
                                options={props.optionsCategory}
                                onChange={props.changeCategoryAdd}
                                isDisabled={props.disabled}
                                ref={selectRefCategoryAdd}              
                            />
                        
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "relative"
                                    }}
                                    value={props.valueCategoryAdd ? props.valueCategoryAdd.value : props.valueCategoryAdd}
                                    onFocus={() => selectRefCategoryAdd.current.focus()}
                                    required={true}
                                />
                            
                        </td>
                        <td width="20%">      
                            <Select
                                value={props.valueEnterprise}
                                label="Single select"
                                options={props.optionsEnterprise}
                                onChange={props.changeEnterprise}
                                isDisabled={props.disabled}
                                ref={selectRefEnterpriseAdd}              
                            />
                        
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "relative"
                                    }}
                                    value={props.valueEnterprise ? props.valueEnterprise.value : props.valueEnterprise}
                                    onFocus={() => selectRefEnterpriseAdd.current.focus()}
                                    required={true}
                                />
                            
                            {props.valueEnterprise.value === 'Nova Empresa' && (
                                <div className="row mt-4">
                                    <div className="col-md-11" style={{display: 'inline-block', width: '30%'}}>
                                        <Input 
                                            type="text"
                                            placeholder="Nova Empresa"
                                            value={props.valueNewEnterprise}
                                            onChange={props.changeNewEnterprise}
            
                                        />
                                    </div>
                                    <div className="col-md-1" style={{paddingTop:'3px'}}>

                                        <button disabled={props.disabledButton} type="button" className="btn btn-primary" onClick={props.onClick}><span className="fas fa-plus"></span></button>
                                    </div>

                                </div>               
                            )}
                        </td>
                        <td width="20%">      
                            <Select
                                value={props.valueUnitAdd}
                                label="Single select"
                                options={props.optionsUnit}
                                onChange={props.changeUnitAdd}
                                isDisabled={props.disabled} 
                                ref={selectRefUnitAdd}             
                            />
                            
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "relative"
                                    }}
                                    value={props.valueUnitAdd ? props.valueUnitAdd.value : props.valueUnitAdd}
                                    onFocus={() => selectRefUnitAdd.current.focus()}
                                    required={true}
                                />
                            
                        </td>
                        <td width="18%">
                            <NumberFormat
                                icon="fas fa-flask"
                                name="dose"
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={"."} 
                                decimalSeparator={","}
                                placeholder=""
                                customInput={Input}
                                onChange={props.changeDoseAdd} 
                                value={props.valueDoseAdd}
                                disabled={props.disabled}
                                required={true}
                                isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= (parseInt(props.valueUnitAdd.value) === 3 ? 100 : 100000000)}}          
                            />
                        </td>
                        <td>
                            <div className="input-group mb-3" style={{paddingTop:'3px'}}>
                                <button disabled={props.disabled} type="submit" className="btn btn-primary"><span className="fas fa-plus"></span></button>
                            </div>
                        </td>
                    </tr>
                    {props.children}
                </Tbody>
                
            </Table>
        </React.Fragment>
    )
}
