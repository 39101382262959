import { app } from './Api'

const post_consultant = async (data) => {
    return app.post('consultant',data)
}

const get_edit = async (id) => {
    return app.get(`consultant/${id}`)
}

const put_consultant = async (data,id) => {
    return app.put(`consultant/${id}`,data)
}

const delete_consultant = async (id) => {
    return app.delete(`consultant/${id}`)
}

const get_consultant = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`consultant?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

export { post_consultant, get_edit, put_consultant, get_consultant, delete_consultant }