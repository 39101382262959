import { getSpraying } from '../../../../../Services/pulverization'

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().pulverization.order
        const th = getState().pulverization.th
        const qtd_per_page = getState().pulverization.qtd_per_page
        const pesquisa = getState().pulverization.pesquisa || ''
        const typeArea = getState().pulverization.type_area || ''       
        const action = 'index';


        getSpraying(page,qtd_per_page,typeArea,order,th,pesquisa, action)
            .then(resp=>dispatch({type:'Spraying', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().pulverization.asc
      
        if(asc === true){
            dispatch({type:'GetSpraying_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetSpraying_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Spraying',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaSpraying',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaSpraying',
        payload:''
    },get(1)]
}