import React, {useState, useEffect} from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {useDispatch, useSelector} from 'react-redux'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ReactLoading from 'react-loading'
import {Content} from '../../Auth/styleLoading'
import {post_phoma, get_area_phomas, get_phoma} from '../../../Services/phoma'
import {post_bicho_mineiro, get_area_bicho_mineiro, get_bicho_mineiro, get_by_id} from '../../../Services/bichoMineiro'
import {get_module} from '../../../Services/moduloAvaliacao'
import {useHistory} from 'react-router'
import NumberFormat from 'react-number-format'
import {Select, MenuItem, SelectChangeEvent, Input} from '@material-ui/core'
import {useRef} from 'react'
import {Link} from "react-router-dom";

import { FaChevronDown, FaChevronUp, FaCheck } from 'react-icons/fa'; // Importe os ícones conforme necessário
import { get_edit } from '../../../Services/consultant'

function GridBichoMineiroDetalhes(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState(false)
    const [requiredPonto1, setRequiredPonto1] = useState(false)
    const [requiredPonto2, setRequiredPonto2] = useState(false)
    const [requiredPonto3, setRequiredPonto3] = useState(false)
    const [requiredPonto4, setRequiredPonto4] = useState(false)
    const [consultantName, setConsultantName] = useState('');

    const typeArea = props.match.params.typeArea;
    const type_area = props.match.params.typeArea == 1 ? 'manejo_syngenta' : 'manejo_produtor';
    const id = props.match.params.id;

    const areas = useSelector(state => state.phoma.areas)
    const id_role = useSelector(state => state.auth.user.id_role);
    const [assentmentModule, setAssentmentModule] = useState("");
    const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');
    const [pesquisa, setPesquisa] = useState('');
    
    // GET Modulo avaliação
    useEffect(() => {
        getModuloAvaliacao(assentmentModule);
    }, []);

    const getModuloAvaliacao = () => {
        get_module().then((resp) => {
            validAccess(resp.data.pests_id);
            setAssentmentModule(resp.data.pests_id);
        });
    };

    useEffect(() => { 
        getConsultant(props.match.params.id);
    }, []);
    
    const [flagPreenchido, setFlagPreenchido] = useState(
        Array.from({ length: 7 }, () => true)
    );

    //Obtém os dados do consultor
    const getConsultant = (id_user) => {
        get_edit(id_user).then((resp) => {
            console.log(resp)
            let name = resp.data[0].name ||'';
            setConsultantName(name);
        });
    };

    const validAccess = (moduleAvaliacao) => {
        if(id_role != 1 || moduleAvaliacao != 2){
            window.location = '/index';
        }        
    };


    // Constante data
    // const [dataPhome, setDataPhome] = useState("");
    const [dataPhome, setDataPhome] = useState(
        Array.from({ length: 7 }, () => "")
    );

    const [ponto1, setPonto1] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
    ]));

    const [ponto2, setPonto2] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
    ]));

    const [ponto3, setPonto3] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
    ]));

    const [ponto4, setPonto4] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, minasAtivas: null, minasInativas: null, avaliacao: 0},
    ]));
    // const selectAvaliationRef = useRef(null)
    const [avaliation, setAvaliation] = useState('')

    // Função que vai setar os valores do banco nas variaveis ou setar o default caso nao tenha
    const getUpdatedPonto = (results, pontoName) => {
        return results.map((result, index) => {
            if (result.data[pontoName] && result.data[pontoName].length === 10) {
                return result.data[pontoName]; // Retorna o array completo do ponto
            }
    
            // Caso nao tenha dados para ser retornado crio um array padrão com objetos independentes
            return Array.from({ length: 10 }, (_, subIndex) => ({
                ramo: subIndex,
                folhaTotal: null,
                minasAtivas: null,
                minasInativas: null,
                avaliacao: 0
            }));
        });
    };

    useEffect(() => {
           
            const promises = [];

            for (let avaliationId = 1; avaliationId <= 7; avaliationId++) {
                promises.push(getPhomas(avaliationId));
            }

            Promise.all(promises)
                .then(results => {
                    const updatedDataPhome = results.map(result => {
                        if (result.data.bichoMineiro1 && result.data.bichoMineiro1.length > 0) {
                            return result.data.bichoMineiro1[0].dataPhome;
                        }
                        return null;
                    });
                    
                    const updatedPonto1 = getUpdatedPonto(results, 'bichoMineiro1');
                    const updatedPonto2 = getUpdatedPonto(results, 'bichoMineiro2');
                    const updatedPonto3 = getUpdatedPonto(results, 'bichoMineiro3');
                    const updatedPonto4 = getUpdatedPonto(results, 'bichoMineiro4');

                    setDataPhome(updatedDataPhome);
                    setPonto1(updatedPonto1);
                    setPonto2(updatedPonto2);
                    setPonto3(updatedPonto3);
                    setPonto4(updatedPonto4);
                })
                .catch(err => {
                    console.error("Error:", err);
                });

    }, []);
    
    useEffect(() => {
        getAreas();
        return () => {
            getAreas()
        }
    }, []);

    useEffect(() => {
        let required = avaliation || ponto1[0].avaliation
        setAvaliation(required)
    }, [ponto1]);

    useEffect(() => {
        let required = dataPhome || ponto1[0].dataPhome
        setDataPhome(required)
    }, [ponto1]);

    useEffect(() => {
        let required = ponto1.map(value => value.folhaTotal != null)
        setRequiredPonto1(required.find(value => value == true ? true : false))
    }, [ponto1]);

    useEffect(() => {
        let required = ponto2.map(value => value.folhaTotal != null)
        setRequiredPonto2(required.find(value => value == true ? true : false))
    }, [ponto2]);

    useEffect(() => {
        let required = ponto3.map(value => value.folhaTotal != null)
        setRequiredPonto3(required.find(value => value == true ? true : false))
    }, [ponto3]);

    useEffect(() => {
        let required = ponto4.map(value => value.folhaTotal != null)
        setRequiredPonto4(required.find(value => value == true ? true : false))
    }, [ponto4]);

    // Adiciona phoma a store do redux
    useEffect(() => {
        if (ponto1.length > 0 && ponto2.length > 0 && ponto3.length > 0 && ponto4.length > 0) {
            let data = {
                ponto1,
                ponto2,
                ponto3,
                ponto4,
                area_id: areas[0]?.id,
                typeArea: typeArea,
                avaliation: avaliation,
                dataPhome: dataPhome
            }

            dispatch({type: 'phomas', payload: data})
        }
    }, [ponto1, ponto2, ponto3, ponto4, avaliation, dataPhome]);

    useEffect(() => {
        if (id_role === 2) {
            dispatch({type: 'phomas', payload: []})
        }
    }, [typeArea]);

    const getAreas = () => {
        setLoading(true)
        get_area_bicho_mineiro(id_role === 1 ? props.match.params.id : null)
            .then((resp) => {
                if (resp.data.length <= 0 && id_role === 2) {
                    dispatch({type: 'set_alert_modal_phoma', payload: 0})
                    dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                    setAlert(`Você ainda não tem um talhão cadastrado. É necessário o cadastro primeiro.`)
                    setLoading(false)
                    setTimeout(() => {
                        setAlert(false)
                        history.push(typeArea === 1 ? '/talhoes/manejo_syngenta' : '/talhoes/manejo_produtor')
                    }, 4000)
                } else {
                    setLoading(false)
                }
                return resp;
            })
            .then(resp => {
                dispatch({type: 'get_areas_phomo', payload: resp.data})
            })
            .catch(err => {
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    const getPhomas = (avaliationId) => {
        return new Promise((resolve, reject) => {
            get_by_id(typeArea, avaliationId, id)
                .then(resp => {

                    resolve(resp); 
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 3500);
                    reject(err);
                });
        });
    };
  
    
    // (handleChange) PONTOS
    const handleChangeFirstPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });
  
        const { name, value, id } = e.target;
    
        setPonto1(prevPonto1 => {
            const newPonto1 = [...prevPonto1]; // Cria copia de ponto1
            switch (name) {
                case 'folha':
                    newPonto1[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto1[index][id].minasAtivas = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto1[index][id].minasInativas = parseInt(value);
                    break;
                case 'presenca1':
                    newPonto1[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto1; // Retorna atualizado a copia do ponto1
        });
    };

    const handleChangeSecondPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto2(prevPonto2 => {
            const newPonto2 = [...prevPonto2]; // Cria copia de ponto2
            switch (name) {
                case 'folha':
                    newPonto2[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto2[index][id].minasAtivas = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto2[index][id].minasInativas = parseInt(value);
                    break;
                case 'presenca2':
                    newPonto2[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto2; // Retorna atualizado a copia do ponto2
        });
    };

    const handleChangeThirdPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto3(prevPonto3 => {
            const newPonto3 = [...prevPonto3]; // Cria copia de ponto3
            switch (name) {
                case 'folha':
                    newPonto3[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto3[index][id].minasAtivas = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto3[index][id].minasInativas = parseInt(value);
                    break;
                case 'presenca3':
                    newPonto3[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto3; // Retorna atualizado a copia do ponto3
        });
    };

    const handleChangeFourthPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto4(prevPonto4 => {
            const newPonto4 = [...prevPonto4]; // Cria copia de ponto4
            switch (name) {
                case 'folha':
                    newPonto4[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto4[index][id].minasAtivas = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto4[index][id].minasInativas = parseInt(value);
                    break;
                case 'presenca4':
                    newPonto4[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto4; // Retorna atualizado a copia do ponto4
        });
    };

    function firstPoint(index) {
        return ponto1[index].map(elem => (
            elem.ramo >= 0 ?
                <>
                    <Tbody>
                        <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 1}</td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.folhaTotal}
                                id={elem.ramo}
                                name={'folha'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    textAlign: 'center',
                                    width: '95%',
                                    minWidth: '50px',
                                    marginLeft: '3px',
                                    marginTop: '3px',
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                required={requiredPonto1}
                                disabled={id_role === 1}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.minasAtivas}
                                id={elem.ramo}
                                name={'ativas'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    minWidth: '50px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.minasInativas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                    </Tbody>
                </> : null
        ))
    }

    function secondPoint(index) {
        return ponto2[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 11}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeSecondPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                minWidth: '50px',
                                textAlign: 'center',
                                marginLeft: '3px',
                                marginTop: '3px'
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto2}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.minasAtivas}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeSecondPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.minasInativas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    function thirdPoint(index) {
        return ponto3[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 21}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeThirdPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto3}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.minasAtivas}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeThirdPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.minasInativas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    function fourthPoint(index) {
        return ponto4[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'middle', textAlign: 'center'}}>{(elem.ramo) + 31}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            type='text'
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeFourthPoint(e, index)}
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto4}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.minasAtivas}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeFourthPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                background: '#fff',
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.minasInativas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    const handleSubmit = async (e, index) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            ponto1: ponto1[index],
            ponto2: ponto2[index],
            ponto3: ponto3[index],
            ponto4: ponto4[index],
            area_id: areas[0].id,
            typeArea: typeArea,
            avaliation: index + 1,
            dataPhome: dataPhome[index],
            assentmentModule: assentmentModule
        }

        await post_bicho_mineiro(data)
            .then(resp => {
                setLoading(false)
                setSuccess(resp.data.success);
                dispatch({type: 'set_alert_modal_phoma', payload: 0})
                dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                setTimeout(() => {
                    setSuccess('');
                    // getPhomas(avaliation)
                }, 3500)
            })
            .catch(err => {
                setLoading(false)
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    // Validação para icone de checked se todos (folhaTotal) estão preenchidos.
    const validatePoints = (index) => {
        let isValid = true;
      
        for (let i = 0; i <= 9; i++) {
          if (
            ponto1[index][i].folhaTotal === null ||
            ponto2[index][i].folhaTotal === null ||
            ponto3[index][i].folhaTotal === null ||
            ponto4[index][i].folhaTotal === null
          ) {
            isValid = false;
            break;
          }
        }
      
        return isValid ? (
          <FaCheck
            style={{
              marginRight: '8px',
              color: '#4ED941',
              marginTop: '1rem',
              fontSize: '24px',
            }}
          ></FaCheck>
        ) : (
          ''
        );
      };

    //CONSTANTES REFERENTE AO CARD
    const [expandedIndices, setExpandedIndices] = useState([]);

    const toggleCard = (index) => {
        if (expandedIndices.includes(index)) {
          setExpandedIndices(expandedIndices.filter((item) => item !== index));
        } else {
          setExpandedIndices([...expandedIndices, index]);
        }
      };

    //CSS CARDS
    const cardStyle = {
        marginBottom: '30px' 
    };
    
    const iconCardStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        fontSize: '24px',
        fontWeight: 600,
    };
    
    const titleStyle = {
        fontSize: '24px',
        fontWeight: 600,
        padding: '15px',
        alignItems: 'center',
        justifyContent: 'flex-start'
    };

    function createTitleStyle(background, color, fontSize='1rem') {
        return {
            background: background,
            textAlign: 'center',
            fontSize: fontSize,
            verticalAlign: 'middle',
            fontWeight: 'bold',
            color: color
        };
    }
    const titlePonto = createTitleStyle('none', 'black', '1.2rem');
    const titleRamo = createTitleStyle('none', 'black');
    const titleTotal = createTitleStyle('#337840', 'white');
    const titleMinasAtivas = createTitleStyle('#C73939', 'white');
    const titleMinasInativas = createTitleStyle('#7FAA52', 'white');

    const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);

    useEffect(() => {
         // Atualiza a largura da tela
        const atualizarLarguraDaTela = () => {
            setLarguraDaTela(window.innerWidth);
        };

        // Evento para detectar mudanças na largura da tela
        window.addEventListener('resize', atualizarLarguraDaTela);

        return () => {
            // Remove o evento 
            window.removeEventListener('resize', atualizarLarguraDaTela);
        };
    }, []);

    return (
        <div className="wrapper">
            <Header/>
            <Sidebar/>
            <StylePage 
             title={
                typeArea == 1 ? 'Avaliação Bicho-mineiro Manejo Syngenta' : 'Avaliação Bicho-mineiro Manejo Produtor'
             } 
             userName={consultantName}
             enableBackButton={true}
             typeArea={typeArea}
             pest={"bicho_mineiro"}
            >
                
                {alert ? (
                    <div>
                        <Alert type="danger" hidden={true}>{alert}</Alert>
                    </div>
                ) : ("")}
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100}/>
                    </Content>

                ) : ('')}
                {success ? (
                    <div>
                        <Alert type="success" hidden={true}>{success}</Alert>
                    </div>
                ) : ("")}
                {error ? (
                    <div>
                        <Alert type="danger" hidden={true}>{error}</Alert>
                    </div>
                ) : ("")}
                <div style={{display: loading === true || alert !== false ? ('none') : ('block'), background: '#f8f9fa'}}>
                <div style={{background: '#f8f9fa'}}>
                    {[...Array(7)].map((_, index) => (
                        <div className="card" style={cardStyle} key={index}>
                            <div className="card-header d-flex justify-content-between">
                                <div className="align-items-center justify-content-start">
                                    <h3 className="card-title" style={titleStyle}>Avaliação {index + 1}</h3>
                                    {validatePoints(index)}
                                </div>
                                <div className="icon-container align-items-center ml-auto" style={iconCardStyle} onClick={() => toggleCard(index)}>
                                    {expandedIndices.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
                                </div>
                            </div>
                            {expandedIndices.includes(index) && (
                                <div className="card-content">
                                    <form onSubmit={handleSubmit} autocomplete="off">
                                        <div className="row" style={{marginBottom: '0.5rem'}}>

                                            <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                                <label style={{ marginRight: '1.1rem', marginLeft: '2.3rem', marginTop: '1rem', fontSize: '1.2rem' }}>Data:</label>
                                                <Input
                                                    style={{ marginTop: '0.5rem', fontSize: '1.1rem' }}
                                                    icon="fas fa-calendar"
                                                    label="Data Admissão"
                                                    name={`dataPhome_${index}`}
                                                    required={true}
                                                    type="date"
                                                    placeholder=""
                                                    value={dataPhome[index]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        const newArray = [...dataPhome]
                                                        newArray[index] = newValue
                                                        setDataPhome(newArray)
                                                
                                                        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });
                                                    }}
                                                    disabled={id_role === 1}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                        <tr>
                                                            <th colspan="4" style={titlePonto}>Ponto 1</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={titleRamo} rowspan="2">Ramo</th>
                                                            <th colspan="3" style={titleTotal}>Folhas</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={titleTotal}>Total</th>
                                                            <th style={titleMinasAtivas}>Com minas ativas</th>
                                                            <th style={titleMinasInativas}>Com minas inativas</th>
                                                        </tr>
                                                    <Thead>
                                                      
                                                    </Thead>

                                                    {firstPoint(index)}
                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='presencaPonto1'
                                                                                value='1'
                                                                                name='presenca1'
                                                                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                                                                checked={ponto1[index].map(value => value.avaliacao == 1 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2' for='presencaPonto1'>Presença</label>
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='ausenciaPonto1'
                                                                                value='0'
                                                                                name='presenca1'
                                                                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                                                                checked={ponto1[index].map(value => value.avaliacao == 0 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='ausenciaPonto1'>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Segundo Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 2</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 2</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Folhas</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {secondPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='presencaPonto2'
                                                                                value="1"
                                                                                name='presenca2'
                                                                                checked={ponto2[index].map(value => value.avaliacao == 1 ? true : false)[0]}
                                                                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='presencaPonto2'>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input type='radio'
                                                                                id='ausenciaPonto2'
                                                                                value="0"
                                                                                name='presenca2'
                                                                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                                                                checked={ponto2[index].map(value => value.avaliacao == 0 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='ausenciaPonto2'>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Terceito Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 3</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 3</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Folhas</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {thirdPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='presencaPonto3'
                                                                                value="1"
                                                                                name='presenca3'
                                                                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                                                                checked={ponto3[index].map(value => value.avaliacao == 1)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='presencaPonto3'>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='ausenciaPonto3'
                                                                                value="0"
                                                                                name='presenca3'
                                                                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                                                                checked={ponto3[index].map(value => value.avaliacao == 0)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='ausenciaPonto3'>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Quarto Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 4</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 4</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Folhas</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {fourthPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='presencaPonto4'
                                                                                value="1"
                                                                                name='presenca4'
                                                                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                                                                checked={ponto4[index].map(value => value.avaliacao == 1)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='presencaPonto4'>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id='ausenciaPonto4'
                                                                                value="0"
                                                                                name='presenca4'
                                                                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                                                                checked={ponto4[index].map(value => value.avaliacao == 0)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for='ausenciaPonto4'>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-12 text-right mb-2 mr-3">
                                                <div className="text-right mb-2 mr-3">
                                                    {/* <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>{}}>Voltar</button>                            */}
                                                    {id_role === 2 && 
                                                        (
                                                        // <button type="submit" className="btn btn-primary">Salvar</button>
                                                        <button className="btn btn-primary" onClick={(e)=>{handleSubmit(e, index)}}>Salvar</button>
                                                        )
                                                    }
                                                    {id_role === 1 && (<Link to={`/bicho_mineiro/${type_area}`}
                                                                            className="btn btn-primary">Retornar</Link>)}
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                </div>

            </StylePage>
            <Footer/>
        </div>
    )
}

export default (GridBichoMineiroDetalhes)
