import { app } from './Api'

const post_produtor = async (data) => {
    return app.post('producer',data)
}

const get_producer = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`producer?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const delete_producer = async (id) => {
    return app.delete(`producer/${id}`)
}

const get_view_producer = async (id) => {
    return app.get(`producer/${id}`)
}

const put_producer = async (data,id) => {
    return app.put(`producer/${id}`,data)
}


export { post_produtor, get_producer, delete_producer , put_producer, get_view_producer}