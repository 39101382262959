const ProductivityReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Productivitys':
            return{...state, productivitys:actions.payload}

        case 'GetProductivity_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProductivity_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Productivity':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProductivity':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProductivity':
            return{...state, pesquisa:actions.payload}

        case 'changeTypeArea':
            return{...state, type_area:actions.payload}

        default:
            return state;      
    }
}
export default ProductivityReducer;
