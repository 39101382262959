/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react'
import Style from '../../Style Page/style_page'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Select from '../../Forms/select'
import { Link } from 'react-router-dom'
import { register, resource, delete_interacao, put_interacao } from '../../Services/interacao'
import Alert from '../../Alerts/alert'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import ReactLoading from 'react-loading'
import { Content } from './styleLoading'
import { connect } from 'react-redux'
import ModalExcluir from '../../Modal/modal'


class Consultor extends Component {
    state = {loading:false,titulo:'',link:'',topicos:[], id_topico:'',error:[],success:'',alert:'', interacao:[], id:'', modal: false, action: '', titulo_edit:'', link_edit:'', id_topico_edit:''}

    componentDidMount () {
        this.setState({titulo:'',link:'',topico:'',error:[],success:'',alert:''})
        resource().then(resp => this.setState({topicos:resp.data.topicos, interacao:resp.data.interacao}))
    }

    //FUNÇÃO QUE PEGA O EVENTO DO CADASTRO DO TITULO
    changeTitulo = event => {
        const titulo = event.target.value
        this.setState({titulo:titulo})
    }
    //FUNÇÃO QUE PEGA O EVENTO DA EDIÇÃO DO TITULO
    changeTituloEdit = event => {
        const titulo = event.target.value
        this.setState({titulo_edit:titulo})
    }
    //FUNÇÃO QUE PEGA O EVENTO DO CADASTRO DO LINK
    changeLink = event => {
        const link = event.target.value
        this.setState({link:link})
    }
    //FUNÇÃO QUE PEGA O EVENTO DA EDIÇÃO DO LINK
    changeLinkEdit = event => {
        const link = event.target.value
        this.setState({link_edit:link})
    }
    //FUNÇÃO QUE PEGA O EVENTO DO CADASTRO DO TOPICO
    changeTopico = event => {
        const id_topico = event.target.value
        this.setState({id_topico:id_topico})
    }
    //FUNÇÃO QUE PEGA O EVENTO DA EDIÇÃO DO TOPICO
    changeTopicoEdit = event => {
        const id_topico = event.target.value
        this.setState({id_topico_edit:id_topico})
    }
    

    handleTopico(){
        const optionTopicos = this.state.topicos  || []
        return optionTopicos.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.name}</option>
        ))
    }

    handleForm=(e)=>{
        e.preventDefault();
        this.setState({loading:true})
        const data = {
            titulo:this.state.titulo,
            link:this.state.link,
            id_topico:this.state.id_topico,
            id_user:this.props.id_user
        }
        const dataEdit = {
            titulo:this.state.titulo_edit,
            link:this.state.link_edit,
            id_topico:this.state.id_topico_edit,
            id_user:this.props.id_user
        }
        this.state.action === '' ? 
        register(data)
            .then(resp=> {
                this.setState({loading:false, alert:'', error:[], success:resp.data.success, titulo:'', link:'', id_topico:''})
                setTimeout(()=>{
                    this.props.history.push('/index')
                },2000)
            })
            .catch(e => (
                this.setState({loading:false,success:'',error: e.response.data.error,alert:e.response.data.message ? ("Os dados fornecidos são inválidos"):('')}
                )
            ))
            :  
            put_interacao(dataEdit, this.state.id)
            .then(resp=> {
                this.setState({loading:false, alert:'', error:[], success:resp.data.success, titulo:'', link:'', id_topico:''})
                setTimeout(()=>{
                    window.location.reload()
                },2000)
            })
            .catch(e => (
                this.setState({loading:false,success:'',error: e.response.data.error,alert:e.response.data.message ? ("Os dados fornecidos são inválidos"):('')}
                )
            ))            
    }

    formatUrl(url) {
        let aux = url.split("=");
        return aux[1];
      }
    
    formatUrlVimeo(url) {
        let aux = url.split("/");
        if (aux[4] != undefined) {
          return `${aux[3]}?h=${aux[4]}`;
        } else {
          return `${aux[3]}`;
        }
    }

    onChangeModal = (id) => {
        this.setState({
            modal: true, 
            id: id
        })
    }

    onChangeModalEdit = (id, titulo, link, topico) => {
        window.scroll(-500, 0)
        this.setState({
            action: 'edit', 
            id: id,
            titulo_edit: titulo,
            link_edit: link,
            id_topico_edit: topico
        })
    }

    onChangeModalEditCancel = () => {
        this.setState({
            action: '', 
            id: '',
            titulo_edit: '',
            link_edit: '',
            id_topico_edit: '',
            titulo: '',
            link: '',
            id_topico: ''
        })
    }

   handleCloseExcluir = event => {
        this.setState({
            modal: false, 
            id: ''
        })
    }

    handleExcluir = (e) => {
        e.preventDefault();
        window.scroll(-500, 0)
        this.setState({loading:true, modal: false})
        delete_interacao(this.state.id)
        .then(resp => {
            this.setState({
                loading:false,
                modal: false, 
                alert:'', 
                error:[], 
                success:resp.data.success, 
                id:''
            })
            setTimeout(()=> {
                this.setState({success:'', modal: false})
                window.location.reload()
            },3000)      
        })
        .catch(e => {
            this.setState({
                loading: false,
                success: '',
                error: e.response.data.error,
                alert:e.response.data.message ? ("Os dados fornecidos são inválidos"):('')
            })
            setTimeout(()=> {
                this.setState({
                    error: '',
                    alert: ''})
            },4000)
        })
    }
  
    render() {
        return (
            <div className="wrapper">
            <Sidebar />
            <Header />
            <Style title={this.state.action === '' ? "Interação Online Ao Vivo" : "Edição de Interação Online Ao Vivo"} subtitle={this.state.action === '' ? "Interação Online Ao Vivo" : "Edição de Interação Online Ao Vivo"}>
                {this.state.loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {this.state.alert ? (
                    <Alert type="danger">
                        {this.state.alert}
                    </Alert>
                ):('')}
                {this.state.success ? (
                    <Alert type="success">
                        {this.state.success}
                    </Alert>
                ):('')}
                <ModalExcluir
                    show={this.state.modal}
                    onHide={this.handleCloseExcluir}
                    title="Excluir Interação"
                    content="Tem certeza que deseja excluir a interação ?"
                    cancel={this.handleCloseExcluir}
                    del={this.handleExcluir}     
                    to="/consultores"                   
                />

                {this.props.id_role === 1 ? 
                    <>
                        <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>
                            <Form onSubmit={this.handleForm}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Input label="Título" value={this.state.action === '' ? this.state.titulo : this.state.titulo_edit} onChange={this.state.action === '' ? this.changeTitulo : this.changeTituloEdit} icon="fas fa-envelope" name="titulo" type="text" placeholder="Digite o Titulo" required="true" />
                                        {this.state.error ? (<p style={{ color: 'red' }}>{this.state.error.titulo}</p>) : ('')}
                                    </div>
                                    <div className="col-md-4">
                                        <Select onChange={this.state.action === '' ? this.changeTopico : this.changeTopicoEdit} value={this.state.action === '' ? this.state.id_topico : this.state.id_topico_edit} label="Tópico" required="true">
                                            <option value="">Selecione o Topico</option>
                                            {this.handleTopico()}
                                        </Select>
                                    </div>
                                    <div className="col-md-4">
                                        <Input label="Link" value={this.state.action === '' ? this.state.link : this.state.link_edit} onChange={this.state.action === '' ? this.changeLink : this.changeLinkEdit} icon="fas fa-envelope" name="link" type="text" placeholder="Digite o Link" required="true" />
                                        {this.state.error ? (<p style={{ color: 'red' }}>{this.state.error.link}</p>) : ('')}
                                    </div>
                                </div>
                                <br></br>
                                <div className="text-right">
                                    <Link to="/interacao_online_ao_vivo" onClick={()=>this.onChangeModalEditCancel()} type="button" className="btn btn-secondary" style={{ marginRight: '10px' }}>Cancelar</Link>
                                    <button type="submit" className="btn btn-primary">{this.state.action === '' ? 'Salvar' : 'Editar'}</button>
                                </div>
                            </Form>
                        </div>
                        {this.state.action === '' ? 
                        <div class="row">
                                {this.state.topicos.map(desc => <div class="overflow-auto" style={{ marginLeft: '8px', marginRight: '8px', width: '100%', marginTop: 30 }}>
                                    <div class="card-header">
                                        <h2 class="card-title" style={{ marginLeft: 50, marginTop: 15, fontWeight: 'bold', fontSize: 22 }}>{desc.name}</h2>
                                    </div>
                                    <div class="d-flex flex-row flex-wrap justify-content-between">
                                        {this.state.interacao.map(resp => resp.id_topico === desc.id ?
                                            <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                {resp.link[8] === "w" ?
                                                    <div style={{
                                                        marginTop: 30,
                                                        // marginLeft: 15,
                                                        width: "500px",
                                                        height: "400px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}>
                                                        <iframe style={{ backgroundColor: "black" }}
                                                            allowfullscreen="allowfullscreen"
                                                            mozallowfullscreen="mozallowfullscreen"
                                                            msallowfullscreen="msallowfullscreen"
                                                            oallowfullscreen="oallowfullscreen"
                                                            webkitallowfullscreen="webkitallowfullscreen"
                                                            width="100%"
                                                            height="100%"
                                                            src={`https://www.youtube.com/embed/${this.formatUrl(resp.link)}`}>
                                                        </iframe>
                                                        <div class="d-flex flex-row flex-wrap justify-content-between">
                                                            <h6 style={{ marginTop: 15, marginLeft: 15 }}>{resp.titulo}</h6>
                                                            <ul className="nav">
                                                                <li className="nav-item dropup">
                                                                    <a className="nav-link" data-toggle="dropdown" href="#/">
                                                                        <i className="fas fa-ellipsis-h" />             
                                                                    </a>
                                                                    <div className='dropdown-menu dropdown-menu-right'>                  
                                                                        {/* <Link to={`/consultor/${desc.id_user}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link> */}
                                                                        {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                                                        <Link to={`/interacao_online_ao_vivo/${resp.id}/edit`} onClick={()=>this.onChangeModalEdit(resp.id, resp.titulo, resp.link, resp.id_topico)} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                                                        <Link to="#" className='dropdown-item' onClick={()=>this.onChangeModal(resp.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <br></br>
                                                    </div> :
                                                    <div style={{
                                                        marginTop: 30,
                                                        // marginLeft: 15,
                                                        width: "500px",
                                                        height: "400px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}>
                                                        <iframe style={{ backgroundColor: "black" }}
                                                            allowfullscreen="allowfullscreen"
                                                            mozallowfullscreen="mozallowfullscreen"
                                                            msallowfullscreen="msallowfullscreen"
                                                            oallowfullscreen="oallowfullscreen"
                                                            webkitallowfullscreen="webkitallowfullscreen"
                                                            width="100%"
                                                            height="100%"
                                                            src={`https://player.vimeo.com/video/${this.formatUrlVimeo(resp.link)}`}
                                                        >
                                                        </iframe>
                                                        <div class="d-flex flex-row flex-wrap justify-content-between">
                                                            <h6 style={{ marginTop: 15, marginLeft: 15 }}>{resp.titulo}</h6>
                                                            <ul className="nav">
                                                                    <li className="nav-item dropup">
                                                                        <a className="nav-link" data-toggle="dropdown" href="#/">
                                                                            <i className="fas fa-ellipsis-h" />             
                                                                        </a>
                                                                        <div className='dropdown-menu dropdown-menu-right'>                  
                                                                            {/* <Link to={`/consultor/${desc.id_user}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link> */}
                                                                            {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                                                            <Link to={`/interacao_online_ao_vivo/${resp.id}/edit`} onClick={()=>this.onChangeModalEdit(resp.id, resp.titulo, resp.link, resp.id_topico)} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                                                            <Link to="#/" className='dropdown-item' onClick={()=>this.onChangeModal(resp.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                                                                        </div>
                                                                    </li>
                                                            </ul>
                                                        </div>
                                                        <br></br>
                                                    </div>}
                                            </div>
                                            : '')} </div>
                                </div>
                                )}
                            </div> : ''}
                        
                        </> : 
                    <div class="row">
                        {this.state.topicos.map(desc => 
                            <div class="card overflow-auto" style={{marginLeft: '8px', marginRight: '8px', width:'100%', marginTop: 30}} >
                                <div class="card-header">
                                    <h2 class="card-title" style={{marginLeft: 50, marginTop: 15, fontWeight: 'bold', fontSize:22}}>{desc.name}</h2>
                                </div>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                {this.state.interacao.map(resp => resp.id_topico === desc.id ? 
                                    <div style={{marginLeft: 20, marginRight: 20}}>
                                        {resp.link[8] === "w" ? 
                                            <div style={{
                                                marginTop: 30,
                                                // marginLeft: 15,
                                                width: "500px",
                                                height: "400px",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>  
                                                <iframe style={{backgroundColor: "black"}}
                                                allowfullscreen="allowfullscreen"
                                                mozallowfullscreen="mozallowfullscreen"
                                                msallowfullscreen="msallowfullscreen"
                                                oallowfullscreen="oallowfullscreen"
                                                webkitallowfullscreen="webkitallowfullscreen"
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/${this.formatUrl(resp.link)}`}></iframe>
                                                <h6 style={{marginTop: 15, marginLeft: 15}}>{resp.titulo}</h6>
                                                <br></br>
                                            </div> : 
                                            <div style={{
                                                marginTop: 30,
                                                // marginLeft: 15,
                                                width: "500px",
                                                height: "400px",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                <iframe style={{backgroundColor: "black"}}
                                                allowfullscreen="allowfullscreen"
                                                mozallowfullscreen="mozallowfullscreen"
                                                msallowfullscreen="msallowfullscreen"
                                                oallowfullscreen="oallowfullscreen"
                                                webkitallowfullscreen="webkitallowfullscreen"
                                                width="100%"
                                                height="100%"
                                                src={`https://player.vimeo.com/video/${this.formatUrlVimeo(resp.link)}`}
                                                ></iframe>
                                                <h6 style={{ marginTop: 15, marginLeft: 15 }}>{resp.titulo}</h6>
                                                <br></br>
                                            </div>  
                                        }
                                    </div>                            
                                : '')
                                } </div>
                            </div>
                        )}
                    </div>}   
            </Style>
            <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    
    id_user:state.auth.user.id_user,
    id_role:state.auth.user.id_role
      
});
export default connect(mapStateToProps,null)(Consultor);

