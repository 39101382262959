import { get_areas, view_produtividade, get_produtividade } from '../../../../Services/produtividade'

export const getArea = (action) => {
    return[ (dispatch) => {
        get_areas(action)
        .then(resp => dispatch({type:'get_areas_produtividade', payload:resp.data.areas}))
    }]
}

export const getProdutividade = () => {
    return[ (dispatch,getState) => {
        const area_id = getState().produtividade.area_id
        view_produtividade(area_id)
        .then(resp => dispatch({type:'get_Produtividade', payload:resp.data}))
        .then(resp => dispatch(prod2019()))
        
    }]
}

export const prod2019 = event => {
    return[(dispatch,getState) => {
        let produtividades = getState().produtividade.produtividades
        let prod2019 = produtividades.filter(resp => resp.year === '2019')
        dispatch({type:'prod2019', 
            payload:prod2019, 
            produtividade2019_id:prod2019[0].productivity_id,
            produtividade2019:prod2019[0].scs_ha
        })
    }]
}

export const changeArea = event => {
    return[{
        type:'changeAreaProdutividade',
        payload:event.target.value
    },getProdutividade()]
}

export const changeProdutividade2019 = event => {
    return[{
        type:'change2019',
        payload:event.target.value
    }]
}

export const changeProdutividade2020 = event => {
    return[{
        type:'change2020',
        payload:event.target.value
    }]
}

export const changeProdutividade2021= event => {
    return[{
        type:'change2021',
        payload:event.target.value
    }]
}

export const changeProdutividade = event => {
    return[{
        type:'changeNewProd',
        payload:event.target.value
    }]
}

export const changeAno = event => {
    return[{
        type:'changeAnoProd',
        payload:event.target.value
    }]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().produtividade.order
        const th = getState().produtividade.th
        const qtd_per_page = getState().produtividade.qtd_per_page
        const pesquisa = getState().produtividade.pesquisa || ''   
        const typeArea = getState().produtividade.type_area || ''
      
        get_produtividade(page,qtd_per_page,typeArea,order,th,pesquisa)
        .then(resp=>dispatch({type:'Productivitys', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().produtividade.asc
      
        if(asc === true){
            dispatch({type:'GetProductivity_Asc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetProductivity_Desc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Productivity',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaProductivity',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaProductivity',
        payload:''
    },get(1)]
}