import React, { Component } from 'react'
import { Wrapper, LogoStyle } from './styles'
import { updatePasswordRemember } from '../../Services/auth'
import Alert from '../../Alerts/alert'
import ReactLoading from 'react-loading'
import Logo from '../../../Image/aliancaCafe.png';

export default class remember extends Component {

    constructor(props){
        super(props)
        this.state={password:'',confirm_password:'',error:'',success:'',loading:false, email:''}
        
    }
    componentDidMount(){
        this.setState({email: this.props.location.search.split('&email')[1]})
    }

    handleForm=(e)=>{
        e.preventDefault();
        this.setState({loading:true})
        if(this.state.password !== this.state.confirm_password){
            this.setState({loading:false,error:'As senhas não conferem !'})
        }else{
            const email = this.props.location.search.split('&email')[1];
            const token = this.props.location.search.split('&email')[0].replace('?token','')
            const data = {password:this.state.password,password_confirmation:this.state.confirm_password, email: email, token: token}  
            
            updatePasswordRemember(data)
            // axios.post("http://localhost:8000/api/auth/password/reset", data)
            .then(res => this.setState({loading:false,sucesso: 'Senha alterada com sucesso', display: 'none', return: 'block', error: ''}))
            .then(resp => (setTimeout(()=>{
                this.props.history.push('/login')
            },2000)))
            // .catch(e => this.setState({errors: e.response.data}))
            .catch(e => this.setState({loading:false,error: 'Confirme sua senha ! A senha deve conter no mínimo 8 caracteres'}))
            // this.props.history.push('/index')
        }
        
    }

    changePassword =(e)=> {
        e.preventDefault();
        const password = e.target.value
        this.setState({password:password})
    }

    changeConfirmPassword =(e)=> {
        e.preventDefault();
        const confirm_password = e.target.value
        this.setState({confirm_password:confirm_password})
    }

    render() {
        const error = this.state.error
        const sucesso = this.state.sucesso
        if(this.state.loading===true){
            return (
                <React.Fragment>
                    <div style={{position:'relative',left:'50%',top:'50%',marginLeft:'-90px',marginTop:'100px'}}>
                        <ReactLoading type="spokes" color="#004A34" height={180} width={180} />
                    </div>
                    <span style={{position:'relative',left:'50%',top:'50%',color: '#004A34',fontSize:'36px',marginLeft:'-90px'}}>Enviando...</span>
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    <Wrapper>
                        <div className="login-box">            
                            {/* /.login-logo */}
                            <div className="card">
                                    <LogoStyle>
                                        <img src={Logo} alt="Logo Aliança Café" width="100%" height="100px" className="d-block" />
                                    </LogoStyle>
                                <div className="card-body login-card-body">
                                    <div style={{display: `${this.state.display}`}}>

                                        <i style={{marginLeft:'125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                        <h5 className="login-box-msg">Altere sua senha</h5>
                                        <p className="login-box-msg">Digite sua nova senha</p>
                                    </div>
                                    {
                                        error.errors ? (
                                            <div>
                                                <Alert type="danger" hidden="true">
                                                    {error.errors}
                                                </Alert>
                                            </div>
                                        ) :('')                                         
                                    }
                                     {
                                        error ? (
                                            <div>
                                                <Alert type="danger" hidden="true">
                                                    {error}
                                                </Alert>
                                            </div>
                                        ) :('')                                         
                                    }
                                    {
                                        sucesso ? (
                                            <div>
                                                <Alert type="success" hidden="true">
                                                    {sucesso}
                                                </Alert>
                                            </div>
                                        ) :('')                                         
                                    }
                                    <form onSubmit={this.handleForm}>
                                        <div className="input-group mb-3" style={{display: `${this.state.display}`}}>
                                            <input type="email" name="email" className="form-control" value={this.state.email} disabled />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fa fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3" style={{display: `${this.state.display}`}}>
                                            <input onChange={this.changePassword} type="password" name="passoword" className="form-control" placeholder="Digite sua senha" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fa fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3" style={{display: `${this.state.display}`}}>
                                            <input onChange={this.changeConfirmPassword} type="password" name="passoword_confirm" className="form-control" placeholder="Confirme sua senha" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fa fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center" style={{display: `${this.state.display}`}}>      
                                            <button type="submit" className="btn btn-primary btn-block">Confirmar</button>
                                        </div>
                                    </form>
                                    <div className="social-auth-links text-center mb-3">
                                        
                                    </div>
                                
                                </div>
                            {/* /.login-card-body */}
                            </div>
                        </div>
                    </Wrapper>
                    {/* /.login-box */}
                </React.Fragment>
            )
        }
    }
}
