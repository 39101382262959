import { auth , app} from "./Api";

const authentication = async (data) => {
  	return auth.post("login", data);
};

const information = async () => {
    return app.post("me")
}

// const refresh = async () => {
//     return app.post("refresh")
// }

const register = async (data) => {
    return app.post("register",data);
}

const updatePassword = async (data) => {
    return app.put("userPassword/edit", data);
}

const rememberPassword = async (data) => {
    return auth.post(`password/email`,data)
}

const updatePasswordRemember = async (data) => {
    return auth.post(`password/reset`,data)
}


export { authentication, information, register, updatePassword, rememberPassword, updatePasswordRemember };