export const HeaderCSV = [
    {label: 'Nome do Consultor',key:'consultant'}, 
    {label: 'Nome do Talhão',key:'area_name'}, 
    {label: 'Produtor', key:'producer_name'},
    {label: 'Tipo Talhão', key:'type_area'}, 
    {label: 'Fazenda', key:'farm'},
    {label: 'Estado', key:'state'},
    {label: 'Cidade', key:'city_name'},
    {label: 'Cultivar', key:'variety_name'},
    {label: 'Maturação', key:'maturation_name'},
    {label: 'Irrigação', key:'irrigation_name'},
    {label: 'Tratos Culturais', key:'cultivation_name'},
    {label: 'Data do Plantio', key:'planting_date'},
    {label: 'Tamanho Fazenda (ha)', key:'farm_size_ha'},
    {label: 'Tamanho Talhão (ha)', key:'area_size_ha'},
    {label: 'Espaçamento Linhas', key:'line_spacing'},
    {label: 'Espaçamento Plantas', key:'plants_spacing'},
    {label: 'Plantas/ha', key:'plantas'},
    {label: 'Latitude', key:'latitude'},
    {label: 'Longitude', key:'longitude'},
    {label: 'Altitude', key:'altitude'},
    {label: 'Falhas (plantas/ha)', key:'failure_ha'},
    {label: 'Plantas (Útil/ha)', key:'plantas_util'},
    {label: 'Total Plantas Útil', key:'total_plantas'},

]

// parseInt(10000/(parseFloat(data.line_spacing)*parseFloat(data.plants_spacing)))