const PulverizationReducer = (state = {}, actions)=>{
    switch(actions.type){
          
        case 'Spraying':
            return{...state, spraying:actions.payload}

        case 'GetSpraying_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetSpraying_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Spraying':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaSpraying':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaSpraying':
            return{...state, pesquisa:actions.payload}

        case 'changeTypeArea':
            return{...state, type_area:actions.payload}

        case 'set_alert_modal_pulverizacao':
            return{
                ...state, 
                alertModal: actions.payload
            } 
        case 'set_alert_warning_pulverizacao':
            return{
                ...state, 
                alertWarning: actions.payload
            } 

        default:
            return state;      
    }
}
export default PulverizationReducer;
