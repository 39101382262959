export const HeaderCSV = [
    {label: 'Consultor',key:'consultant'}, 
    {label: 'Manejo', key:'manejo'}, 
    {label: 'Doença', key:'doenca'},
    {label: 'Produção', key:'production'},
    {label: 'Número de Plantas Colhidas', key:'num_plants_harvested'},
    {label: 'Rendimento Esperado', key:'expected_return'},
    {label: '% de frutos verde', key:'green_fruits'},
    {label: '% de frutos cereja', key:'cherry_fruits'},
    {label: '% de frutos seco', key:'dry_fruits'},

]