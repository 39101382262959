const ProdutorReducer = (state = {}, actions)=>{
    switch(actions.type){
        case 'changeNomeProdutor':
            return{...state, nome:actions.payload}
        
        case 'changeEmailProdutor':
            return{...state, email:actions.payload}

        case 'changeCPFProdutor':
            return{...state, cpf:actions.payload}

        case 'changeDataProdutor':
            return{...state, data_nascimento:actions.payload}

        case 'changeTelefoneProdutor':
            return{...state, telefone:actions.payload}

        case 'changeCEPProdutor':
            return{...state, cep:actions.payload}

        case 'changeLogradouroProdutor':
            return{...state, logradouro:actions.payload}

        case 'changeNumeroProdutor':
            return{...state, numero:actions.payload}
        
        case 'changeUFProdutor':
            return{...state, uf:actions.payload}

        case 'changeCidadeProdutor':
            return{...state, cidade:actions.payload}

        case 'changeBairroProdutor':
            return{...state, bairro:actions.payload}

        case 'changeComplementoProdutor':
            return{...state, complemento:actions.payload}

        case 'getToCepProdutor':
            return{...state, cidade:actions.cidade, bairro:actions.bairro, uf:actions.uf, logradouro:actions.logradouro}
        
        case 'Get_UfsProdutor':
            return{...state, ufs:actions.payload}

        case 'Get_CitysProdutor':
            return{...state, cidades:actions.payload}

        case 'GetProdutor_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProdutor_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_produtor':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProdutor':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProdutor':
            return{...state, pesquisa:actions.payload}

        case 'get_producer':
            return{...state, produtores:actions.payload}

        case 'editViewProdutor':
            return{...state,
                nome: actions.nome,
                data_nascimento: actions.data_nascimento,
                cpf: actions.cpf,
                cep: actions.cep,
                uf: actions.uf,
                cidade: actions.cidade,
                bairro: actions.bairro,
                logradouro: actions.logradouro,
                numero:actions.numero,
                complemento: actions.complemento,
                email: actions.email,
                telefone: actions.telefone
            }

        case 'clearInputProdutor':
            return{...state,
                nome: actions.nome,
                data_nascimento: actions.data_nascimento,
                cpf: actions.cpf,
                cep: actions.cep,
                uf: actions.uf,
                cidade: actions.cidade,
                bairro: actions.bairro,
                logradouro: actions.logradouro,
                numero:actions.numero,
                complemento: actions.complemento,
                email: actions.email,
                telefone: actions.telefone
            }


        default:
            return state;
    }
}
export default ProdutorReducer;