import React, {useState, useEffect} from 'react'
import StylePage from '../../../../Style Page/style_page'
import Header from '../../../../Header/header'
import Sidebar from '../../../../Sidebar/sidebar'
import Form from '../../../../Forms/form'
import Footer from '../../../../Footer/footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../../Loading/Style'
import ModelForm from '../ModelForm/ModelForm'
import ModelTable from '../ModelTable/ModelTable'
import { Link, useHistory } from "react-router-dom"
import { applications } from './Docs/data'
import { getResource, postPulverization, viewPerId, putPulverization} from '../../../../Services/pulverization'
import Applications from './Applications'
import AlertNew from '../../../../Alerts/AlertNew'
import { ContentButton } from '../../../../Button/style'
import { useDispatch, useSelector } from 'react-redux'
import { get_module } from '../../../../Services/moduloAvaliacao'

export default function Pulverization(props) {
    let history = useHistory();
    // const [ harvest, setHarvest ] = useState('')
    const [methods, setMethods] = useState([]);
    const [method, setMethod] = useState('');
    const [ application, setApplication ] = useState('')
    const [ dataApplications, setDataApplications ] = useState([])
    const [ date, setDate ] = useState('')
    const [ tailVolume, setTailVolume ] = useState('')
    const [ id_product, setIdProduct ] = useState('')
    const [ newProduct, setNewProduct ] = useState('')
    const [ id_enterprise, setIdEnterprise ] = useState('')
    const [ doseAdd, setDoseAdd ] = useState('')
    const [ enterprises, setEnterprises ] = useState([])
    const [ products, setProducts ] = useState([])
    const [ cloneEnterprises, setCloneEnterprises ] = useState([])
    const [ cloneProducts, setCloneProducts ] = useState([])
    const [ cloneCategorys, setCloneCategorys ] = useState([])
    const [ newEnterprise, setNewEnterprise ] = useState('')
    const [ categorys, setCategorys ] = useState([])
  
    const [ aplications, setAplications ] = useState([])
    const [ aplication, setAplication ] = useState([])
    const [ units_measurements, setUnitMeasurements ] = useState([])
    const [ unitAdd, setUnitAdd ] = useState('')
    const [ categoryAdd, setCategoryAdd ] = useState('')
    const [ contador, setContador ] = useState(0)
    const [ alert, setAlert ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState('')
    const [ warning, setAlertWarning ] = useState('')
    const [ error, setError ] = useState([])
    const type_area = props.match.params.typeArea === 'manejo_em_comum' ? 1 : props.match.params.typeArea === 'manejo_syngenta' ? 2 : 3
    const id_view_edit = props.match.params.id
    const id_role = useSelector(state => state.auth.user.id_role)
    const [module, setModule] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        getModule();
        return () => {
            getModule()
        }
        
    },[])
    
    const getModule = () => {
        get_module().then((resp) =>{
            setModule(resp.data.pests_id);
        });
    }

    useEffect(() => {
        getResources(type_area);
        return () => {
            getResources(type_area)
        }
        
    },[type_area])

    useEffect(() => {
        if(props.match.params.action){

            viewPulverization(id_view_edit);
            return () => {
                viewPulverization(id_view_edit)
            }
        }
        
    },[id_view_edit])

    /**
     * Record view function by ID and states updates
     * @param {int} id 
     */
    function viewPulverization(id){
        viewPerId(id)
        .then(resp =>{

            // setHarvest({
            //     label: resp.data.pulverization[0].harvest,
            //     value: resp.data.pulverization[0].harvest
            // })
            setMethod({
                label: resp.data.pulverization[0].method,
                value: resp.data.pulverization[0].id_method
            })
            setApplication({
                label: resp.data.pulverization[0].application,
                value: resp.data.pulverization[0].application
            })
            setAplication({
                label: resp.data.pulverization[0].aplication,
                value: resp.data.pulverization[0].id_aplication
            })
            setDate(resp.data.pulverization[0].application_date)
            setTailVolume(resp.data.pulverization[0].tail_volume)
            setDataApplications(resp.data.applications.map(desc=>({
                id:desc.id,
                id_product: {label: desc.name_product, value:desc.id_product },
                id_category: {label: desc.name_category, value:desc.id_category },
                id_enterprise: {label: desc.name_enterprise, value:desc.id_enterprise },
                id_unit_measurement: {label: desc.name_unit_measurement, value: desc.id_unit_measurement},
                dose: desc.amount,
                optionsProduct: products.length <= 0 ? 
                [
                    {label: '-- SELECIONE --', value: ''}, 
                    ...resp.data.product.filter(desc =>
                        parseInt(desc.id_category) !== 6 && parseInt(desc.id_category) !== 7)
                        .map(
                            desc => 
                            ({
                                label: desc.name,
                                value: desc.id,
                                id_enterprise: desc.id_enterprise,
                                id_category: desc.id_category,
                            })
                        )
                    
                ] : products ,
                optionsEnterprise: enterprises.length <= 0 ? 
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.enterprise.map(desc => ({
                        label: desc.name,
                        value: desc.id
                    })) 
                ] : enterprises,
                optionsCategory: categorys.length <= 0 ? 
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.category.filter(desc => parseInt(desc.id) !== 6 && parseInt(desc.id) !== 7) 
                    .map(desc => 
                        ({
                            label: desc.name,
                            value: desc.id
                        })
                        
                    )
                ] : categorys
            })))
            setContador(resp.data.applications.length > 0 ? resp.data.applications[0].id + 1 : 0)
        })
    }
    
    /**
     * Dependency search function
     * @param {int} type_area 
     * @param {int} product_id 
     * @param {int} enterprise_id 
     */
    function getResources(type_area,product_id, enterprise_id){
        getResource(type_area,product_id, enterprise_id)
        .then(resp => {
            
            setCategorys(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.category.filter(desc => parseInt(desc.id) !== 6 && parseInt(desc.id) !== 7).map(desc => ({
                        label: desc.name,
                        value: desc.id
                    }))
                ]
            )

            setMethods(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.methods.filter(desc => parseInt(desc.id) !== 6 && parseInt(desc.id) !== 7).map(desc => ({
                        label: desc.name,
                        value: desc.id
                    }))
                ]
            );
            
            setAplications(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.type_aplication.map(desc => ({
                        label: desc.name,
                        value: desc.id
                    }))
                ]
            )
       
            
            setUnitMeasurements(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.unit_measurement.map(desc => ({
                        label: desc.name,
                        value: desc.id
                    }))
                ]
            )
            setProducts(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.product.filter(
                        desc => parseInt(desc.id_category) !== 6 && parseInt(desc.id_category) !== 7
                    ).map(resp => 
                        ({
                            label: resp.name,
                            value: resp.id,
                            id_enterprise: resp.id_enterprise,
                            id_category: resp.id_category
                        })
                    )     
                    .concat({
                        label: 'Novo Produto',
                        value: 'Novo Produto'
                    })
                ]             
            )
            setEnterprises(
                [
                    {label: '-- SELECIONE --', value:''},
                    ...resp.data.enterprise.map(desc => ({
                        label: desc.name,
                        value: desc.id
                    }))
                    .concat({
                        label: 'Nova Empresa',
                        value: 'Nova Empresa'
                    })
                ]
            )
            setCloneCategorys(resp.data.category.filter(desc => parseInt(desc.id) !== 6 && parseInt(desc.id) !== 7).map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            setCloneProducts(resp.data.product.filter(desc => parseInt(desc.id_category) !== 6 && parseInt(desc.id_category) !== 7).map(desc => 
                ({
                    label: desc.name,
                    value: desc.id,
                    id_enterprise: desc.id_enterprise,
                    id_category: desc.id_category
                })
            )) 
            setCloneEnterprises(resp.data.enterprise.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            
        }
        )
    }
    


    /**
     * Function for adding new products and companies
     * @param {event} e 
     */
    const handleAdd = (e) => {
        if(newProduct && typeof id_product.value === 'string'){
            
            setProducts([
                {label:`${newProduct}`,value: `${newProduct}`},
                ...products
            ])
            setIdProduct({
                label:`${newProduct}`, 
                value:`${newProduct}`
            })
            setNewProduct('')
        }
        if(newEnterprise && typeof id_enterprise.value === 'string'){
            setEnterprises([
                {label: `${newEnterprise}`,value: `${newEnterprise}`},
                ...enterprises
            ])
            // setEnterprises(enterprises.concat({label: `${newEnterprise}`,value: `${newEnterprise}`}))
            setIdEnterprise({
                label:`${newEnterprise}`, 
                value:`${newEnterprise}`
            })
            setNewEnterprise('')
        }
    }

    /**
     * Function that determines the states of dataApplications and renders them
     * @param {event} e 
     */
    const handleApplications = (e) => {
        e.preventDefault();
        let contador_tr = contador
        const trs = document.querySelectorAll('.applications')

        if(id_product.label === 'Novo Produto' || id_enterprise.label === 'Nova Empresa'){
            return[
                setAlert('Confirme a adição de produtos ou empresas'),
                setTimeout(()=>{
                    setAlert('')
                },3000)
            ]      
        }

        if(trs.length > 0){
            let filter_product = dataApplications.filter(resp => resp.id_product.value === id_product.value) 
            let filter_name = dataApplications.filter(resp => `${resp.id_product.label}`.toLowerCase() === `${id_product.label}`.toLowerCase())
            if(filter_product.length > 0 || filter_name.length > 0){
                return[
                    setAlert('Não é permitido produtos iguais'),
                    setTimeout(()=>{
                        setAlert('')
                    },3000)
                ]           
            }
        }

        // desativar flag's para alertas
        dispatch({ type: 'set_alert_warning_pulverizacao', payload: 0 })
        dispatch({ type: 'set_alert_modal_pulverizacao', payload: 0 })

        let object = {}
        object.id = contador_tr
        object.id_product = id_product

        object.id_category = categoryAdd
        object.id_enterprise = id_enterprise
        object.id_unit_measurement = unitAdd
        object.dose = doseAdd
        contador_tr++
        setDataApplications(dataApplications.concat(object))
        setContador(contador_tr)
        setCategoryAdd('')
        setIdEnterprise('')
        setIdProduct('')
        setCategorys(cloneCategorys)
        setProducts(
            [
                {label: '-- SELECIONE --', value:''},      
                ...cloneProducts.concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --')) 
            ]
        )
        setEnterprises(
            [
                {label: '-- SELECIONE --', value:''},      
                ...cloneEnterprises.concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --')) 
            ]
        )
        setUnitAdd('')
        setDoseAdd('')
        object.optionsProduct = products
        object.optionsEnterprise = enterprises
        object.optionsCategory = categorys      
    }
    
    /**
     * Function that removes application registration by ID
     * @param {event} e 
     */
    const handleRemove = (e) => {
        e.preventDefault();
        const id = parseInt(e.target.parentNode.parentNode.parentNode.parentNode.id)
        setDataApplications(dataApplications.filter(desc => desc.id !== id))
             
    } 

    /**
     * Function that takes the product change event and defines the options related to the event
     * @param {event} e 
     */
    const changeProduct = e => {   
        setIdProduct(e)
        setIdEnterprise(isNaN(parseInt(e.value)) === false ? cloneEnterprises.filter(desc => parseInt(desc.value) === parseInt(e.id_enterprise))[0] : isNaN(parseInt(e.value)) === true && e.value !== 'undefined' ? id_enterprise : '')
        setCategoryAdd(isNaN(parseInt(e.value)) === false ? cloneCategorys.filter(desc => parseInt(desc.value) === parseInt(e.id_category))[0] : categoryAdd)
        setEnterprises(
            [
                {label: '-- SELECIONE --', value:''},
                ...isNaN(parseInt(e.value)) === false ? (

                    cloneEnterprises.filter(desc => parseInt(desc.value) === parseInt(e.id_enterprise)) 
                    .concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --'))
                ) : 
                cloneEnterprises
                .concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Nova Empresa'))
                .concat({
                    label: 'Nova Empresa',
                    value: 'Nova Empresa'
                })
            ]
        )
        setCategorys(
            [
                {label: '-- SELECIONE --', value:''},
                ...isNaN(parseInt(e.value)) === false ? cloneCategorys.filter(desc => parseInt(desc.value) === parseInt(e.id_category)) : cloneCategorys
            ]
        )
        
    }

    const changeCategory = (e) => {
        setCategoryAdd(e)
        setIdEnterprise(isNaN(parseInt(e.value)) === false && isNaN(parseInt(id_enterprise.value)) === false ? '' : e.label === '-- SELECIONE --' ? '' : id_enterprise)
        setIdProduct(isNaN(parseInt(e.value)) === false ? id_product : '')
        setProducts([
            {label: '-- SELECIONE --', value:''},
            
            ...isNaN(e.value) === false && e.label !== '-- SELECIONE --' ? 
            
            cloneProducts.filter(desc => parseInt(desc.id_category) === parseInt(e.value))
            .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --'))
            : cloneProducts
            .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto'))
            .concat({
                label: 'Novo Produto',
                value: 'Novo Produto'
            })
    
        ])
        setEnterprises([
            {label: '-- SELECIONE --', value:''},
            ...cloneEnterprises
            .concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Nova Empresa'))
            .concat({
                label: 'Nova Empresa',
                value: 'Nova Empresa'
            })
    
        ])
        
    }

    const changeProductApplication = (newValue: any, actionMeta: any) => {
        const id = actionMeta.name
        const product_new = newValue
        setDataApplications(
            dataApplications.map(desc => ({
                id: desc.id,
                id_product: parseInt(desc.id) === parseInt(id) ? product_new : desc.id_product,
                id_category: parseInt(desc.id) === parseInt(id) ? isNaN(parseInt(product_new.value)) === false ? cloneCategorys.filter(descC => parseInt(product_new.id_category) === parseInt(descC.value))[0] : [] : desc.id_category,
                id_enterprise: parseInt(desc.id) === parseInt(id) ? isNaN(parseInt(product_new.value)) === false ? cloneEnterprises.filter(descE => parseInt(product_new.id_enterprise) === parseInt(descE.value))[0] : [] : desc.id_enterprise,
                id_unit_measurement: parseInt(desc.id) === parseInt(id) ? [] : desc.id_unit_measurement,
                dose: desc.dose,
                optionsProduct: desc.optionsProduct,
                optionsEnterprise: parseInt(desc.id) === parseInt(id) ? (
                    [

                        {label: '-- SELECIONE --', value:''},
                        ...isNaN(parseInt(product_new.value)) === false ?(
                        cloneEnterprises.filter(descE => parseInt(product_new.id_enterprise) === parseInt(descE.value))
                        .concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --')) 

                        ) : cloneEnterprises.concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --'))
                    ]
                    
                ) : desc.optionsEnterprise,
                optionsCategory: parseInt(desc.id) === parseInt(id) ? (
                    [
                        {label:'-- SELECIONE --',value:''}, 
                        ...product_new.length > 0 ? cloneCategorys.filter(descC => parseInt(product_new.id_category) === parseInt(descC.value)) : cloneCategorys
                    ]
                    ) : desc.optionsCategory,
                }))             
        )  
    }
    
    const changeEnterpriseApplication = (newValue: any, actionMeta: any) => {
        const id = actionMeta.name
        const enterprise_new = newValue

        setDataApplications(
            dataApplications.map(desc => ({
                id: desc.id,
                id_product: parseInt(desc.id) === parseInt(id) ? isNaN(desc.id_product.value) === true ? desc.id_product : [] : desc.id_product,
                id_category: parseInt(desc.id) === parseInt(id) ? isNaN(desc.id_product.value) === true ? desc.id_category : [] : desc.id_category,
                id_enterprise: parseInt(desc.id) === parseInt(id) ? enterprise_new : desc.id_enterprise,
                id_unit_measurement: parseInt(desc.id) === parseInt(id) ? [] : desc.id_unit_measurement,
                dose: desc.dose,
                optionsProduct: parseInt(desc.id) === parseInt(id) ? (
                    [
                        {label: '-- SELECIONE --', value:''},
                        ...isNaN(parseInt(enterprise_new.value)) === false ? 
                            cloneProducts.filter(resp => parseInt(resp.id_enterprise) === parseInt(enterprise_new.value))
                            .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto')) 
                        : cloneProducts
                        .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto'))
                    ]
                ) : desc.optionsProduct,
                optionsEnterprise: desc.optionsEnterprise,
                optionsCategory: desc.optionsCategory,
            }))             
        )  
    }

    const changeCategoryApplication = (newValue: any, actionMeta: any) => {
        const id = actionMeta.name
        const category_new = newValue

        setDataApplications(
            dataApplications.map(desc => ({
                id: desc.id,
                id_product: parseInt(desc.id) === parseInt(id) ? isNaN(desc.id_product.value) === true ? desc.id_product : [] : desc.id_product,
                id_category:  parseInt(desc.id) === parseInt(id) ? category_new : desc.id_category,
                id_enterprise: parseInt(desc.id) === parseInt(id) ? isNaN(desc.id_enterprise.value) === true ? id_enterprise : [] : desc.id_enterprise,
                id_unit_measurement: desc.id_unit_measurement,
                dose: desc.dose,
                optionsProduct: parseInt(desc.id) === parseInt(id) ? (
                    [
                        {label: '-- SELECIONE --', value:''},
                        ...isNaN(parseInt(category_new.value)) === false ? (

                            cloneProducts.filter(resp => parseInt(resp.id_category) === parseInt(category_new.value)).concat(
                                products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto')
                            ) 

                        ) : cloneProducts.concat(
                                products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto')
                            ) 
                    ]
                ) : desc.optionsProduct,
                optionsEnterprise: 
                    parseInt(desc.id) === parseInt(id) ? (
                        [
                            {label: '-- SELECIONE --', value:''},
                            ...cloneEnterprises
                            .concat(enterprises.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Nova Empresa'))
                        ]
                    )
                    : desc.optionsEnterprise,
                optionsCategory: desc.optionsCategory,
            }))             
        )  
    }

    const changeUnitApplication = (newValue: any, actionMeta: any) => {
        const id = actionMeta.name
        const unit_new = newValue

        setDataApplications(
            dataApplications.map(desc => ({
                id: desc.id,
                id_product: desc.id_product,
                id_category: desc.id_category,
                id_enterprise: desc.id_enterprise,
                id_unit_measurement: parseInt(desc.id) === parseInt(id) ? unit_new : desc.id_unit_measurement,
                dose: desc.dose,
                optionsProduct: desc.optionsProduct,
                optionsEnterprise: desc.optionsEnterprise,
                optionsCategory: desc.optionsCategory,
            }))             
        )  
    }

    const changeDoseApplication = e => {
        const id = e.target.parentNode.parentNode.parentNode.id
        const value_new = parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.'))

        setDataApplications(
            dataApplications.map(desc => ({
                id: desc.id,
                id_product: desc.id_product,
                id_category: desc.id_category,
                id_enterprise: desc.id_enterprise,
                id_unit_measurement: desc.id_unit_measurement,
                dose: parseInt(desc.id) === parseInt(id) ? value_new : desc.dose,
                optionsProduct: desc.optionsProduct,
                optionsEnterprise: desc.optionsEnterprise,
                optionsCategory: desc.optionsCategory,
            }))             
        )  
        
    }

    const changeEnterprise = e => {   
        setIdEnterprise(e)
        setIdProduct(isNaN(parseInt(id_product.value)) === true ? id_product : '')
        setCategoryAdd(isNaN(parseInt(id_product.value)) === true ? categoryAdd : '')
        setProducts(
            [           
                {label: '-- SELECIONE --', value:''},
                ...isNaN(parseInt(e.value)) === false ? (
                    
                    cloneProducts.filter(desc => parseInt(desc.id_enterprise) === parseInt(e.value)) 
                    .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --'))
                
                ): 
                cloneProducts
                .concat(products.filter(desc => isNaN(parseInt(desc.value)) === true && desc.label !== '-- SELECIONE --' && desc.label !== 'Novo Produto'))
                .concat({
                    label: 'Novo Produto',
                    value: 'Novo Produto'
                })
            ]
        )

        
    }

    useEffect(() => {
        if(id_enterprise.value || id_product.value || categoryAdd.value || unitAdd.value || doseAdd){
            let obj = {id_enterprise: id_enterprise.value,id_product: id_product.value,categoryAdd: categoryAdd.value,unitAdd: unitAdd.value,doseAdd}
            

            dispatch({type: 'set_alert_modal_pulverizacao', payload: 1});
        }

    }, [id_enterprise.value || id_product.value || categoryAdd.value || unitAdd.value || doseAdd]);

    const flagAlertWarning = useSelector( state => state.pulverization.alertWarning);
    useEffect(() => {
        if( flagAlertWarning === 1 ){
            setAlertWarning('Existe aplicação preenchida à ser confirmada! Confirme ou deixe os campos em branco antes de continuar  !');
            setTimeout(()=> {
                setAlertWarning('')
            },6000)
        }
    }, [flagAlertWarning]);

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        if(id_enterprise.value || id_product.value || categoryAdd.value || unitAdd.value || doseAdd){
            return[
                setLoading(false),
                setAlertWarning('Existe aplicação preenchida à ser confirmada! Confirme ou deixe os campos em branco antes de continuar  !'),
                setTimeout(()=> {
                    setAlertWarning('')
                },6000)
            ]    
        }
        if(dataApplications.length > 0){
            for(let i = 0; i < dataApplications.length; i++){
                for(let j = i+1; j < dataApplications.length; j++){
                    if(dataApplications[i].id_product.value === dataApplications[j].id_product.value){
                        return [
                            setLoading(false),
                            setAlert('Não é permitido produtos iguais !'),
                            setTimeout(()=> {
                                setAlert('')
                            },3000)
                        ]
                    }
                }
                if(!dataApplications[i].id_product.value || !dataApplications[i].id_category.value || !dataApplications[i].id_enterprise.value || !dataApplications[i].id_unit_measurement.value || !dataApplications[i].dose){
                    return [
                        setLoading(false),
                        setAlert('Existem registros vazios em suas aplicações !'),
                        setTimeout(()=> {
                            setAlert('')
                        },3000)
                    ]
                }
            }
        } else {
            return [
                setLoading(false),
                setAlertWarning('Preencha ao menos um produto para salvar o cadastro !'),
                setTimeout(()=> {
                    setAlertWarning('')
                },3000)
            ]
        } 

        
        const object = {
            module_id: module,
            id_type_area: type_area,
            // harvest: harvest.value,
            application: application.value,
            type_aplication: aplication.value,
            id_method_aplication: method.value,
            application_date: date,
            tail_volume: isNaN(parseFloat(tailVolume)) === false ? tailVolume : '',
            applications: dataApplications.map(desc => ({
                isNewEnterprise: isNaN(parseInt(desc.id_enterprise.value)) === false ? false : true,
                isNewProduct: isNaN(parseInt(desc.id_product.value)) === false ? false : true,
                id_enterprise: isNaN(parseInt(desc.id_enterprise.value)) === false ?  parseInt(desc.id_enterprise.value) : '',
                name_enterprise: isNaN(parseInt(desc.id_enterprise.value)) === false ? '' : desc.id_enterprise.value,
                id_product: isNaN(parseInt(desc.id_product.value)) === false ?  parseInt(desc.id_product.value) : '',
                name_product: isNaN(parseInt(desc.id_product.value)) === false ? '' : desc.id_product.value,
                id_category: parseInt(desc.id_category.value),
                id_unit_measurement: parseInt(desc.id_unit_measurement.value),
                amount: desc.dose  
            }))
        }
        if(props.match.params.action === 'edit'){
            putPulverization(props.match.params.id,object)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit) 
                setTimeout(()=>{
                    setAlert('')
                },5000) 
            })  

        }else{
            postPulverization(object)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                console.log(e)
                setLoading(false)
                
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)
                setTimeout(()=>{
                    setAlert('')
                },5000)   
            })              
        }
    }


    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage 
                title={
                    props.match.params.action === 'edit' ? 
                        `${type_area === 1 ? 'Edição de Pulverização de Manejo em Comum' 
                        : type_area === 2 ? 'Edição de Pulverização de Manejo Syngenta' : 'Edição de Pulverização de Manejo Produtor'}` 
                    : props.match.params.action === 'view' ?  
                        `${type_area === 1 ? 'Visualização de Pulverização de Manejo em Comum' 
                        : type_area === 2 ? 'Visualização de Pulverização de Manejo Syngenta' : 'Visualização de Pulverização de Manejo Produtor'}` 
                    : `${type_area === 1 ? 'Cadastro de Pulverização de Manejo em Comum' 
                    : type_area === 2 ? 'Cadastro de Pulverização de Manejo Syngenta' : 'Cadastro de Pulverização de Manejo Produtor'}`
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                
                ):('')}
                {alert ? <AlertNew type="danger">{alert}</AlertNew> : success ?  <AlertNew type="success">{success}</AlertNew> : warning ? <AlertNew type="warning">{warning}</AlertNew> : ''}
               
                <div style={{width: '100%', display: loading === true ? ('none'):('block'), opacity: alert || success || warning ? 0.1 : 1}}>
                   
                    <Form onSubmit={handleForm}>
                        <ModelForm 
                            // optionsHarvest={harvests}
                            typeArea={type_area}
                            optionsMethods={methods}
                            changeMethods={(e)=>setMethod(e)}
                            valueMethod={method}
                            optionsApplication={applications}
                            optionsAplication={aplications}
                            // changeHarvest={(e)=>setHarvest(e)}
                            changeApplication={(e)=>setApplication(e)}
                            changeAplication={(e)=>setAplication(e)}
                            changeDate={(e)=>setDate(e.target.value)}
                            changeTail={(e)=>setTailVolume(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                            // valueHarvest={harvest}
                            valueApplication={application}
                            valueAplication={aplication}
                            valueDate={date}
                            valueTail={tailVolume}
                            module={module}
                            disabled={props.match.params.action === 'view' ? true : false}
                            error={error}
                        />
                        <div className="text-right">
                            <ContentButton>
                                <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                                {props.match.params.action === 'view' 
                                    ? 
                                        (<Link to={`/pulverizacao/${props.match.params.typeArea}/${props.match.params.id}/edit`}
                                            style={
                                                    {
                                                        marginRight: '10px',
                                                        cursor: id_role === 1 ? "not-allowed" : "",
                                                        color: id_role === 1 ? "#FFF" : "",
                                                        backgroundColor: id_role === 1 ? "#007BFF" : "",
                                                        borderColor: id_role === 1 ? "#007BFF" : "",
                                                        opacity: id_role === 1 ? .65 : "",
                                                        boxShadow: id_role === 1 ? "none" : "",
                                                    }
                                                } 
                                            onClick={id_role === 1 ? e=>e.preventDefault() : ""}
                                            className="btn btn-primary">Editar</Link>) 
                                    : 
                                    <button className="btn btn-primary" disabled={props.match.params.action === 'view' ? true : false} type="submit">Salvar</button>  
                                }
                                {/* <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                            */}
                            </ContentButton>
                        </div> 
                    </Form>
                    <div style={{marginBottom: '50px'}}>
                        <Form onSubmit={handleApplications}>
                            <ModelTable
                                optionsProduct={products}
                                changeProduct={changeProduct}
                                productAdd={id_product}
                                changeNewProduct={(e)=>setNewProduct(e.target.value)}
                                valueNewProduct={newProduct}
                                optionsEnterprise={enterprises}
                                changeEnterprise={changeEnterprise}
                                valueEnterprise={id_enterprise}
                                changeNewEnterprise={(e)=>setNewEnterprise(e.target.value)}
                                valueNewEnterprise={newEnterprise}
                                onClick={handleAdd}
                                optionsCategory={categorys}
                                optionsAplication={aplication}
                                optionsUnit={units_measurements}
                                changeUnitAdd={(e)=>setUnitAdd(e)}
                                changeCategoryAdd={changeCategory}
                                valueCategoryAdd={categoryAdd}
                                valueUnitAdd={unitAdd}
                                changeDoseAdd={(e)=>setDoseAdd(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                valueDoseAdd={doseAdd}
                                action={props.match.params.action}
                                disabled={props.match.params.action === 'view' ? true : false}
                            >
                                <Applications 
                                    optionsProduct={products}
                                    optionsEnterprise={enterprises}
                                    optionsCategory={categorys}
                                    optionsUnit={units_measurements}
                                    dataApplications={dataApplications}
                                    handleRemove={handleRemove}
                                    changeProductApplication={changeProductApplication}
                                    changeEnterpriseApplication={changeEnterpriseApplication}
                                    changeCategoryApplication={changeCategoryApplication}
                                    changeUnitApplication={changeUnitApplication}
                                    changeDoseApplication={changeDoseApplication}
                                    disabled={props.match.params.action === 'view' ? true : false}
                            
                                />
                            </ModelTable>
                        </Form>   
                    </div>
                </div>
            </StylePage>
            <Footer />          
        </div>
    )
}
