import {app} from "./Api";


const register = async (data) => {
    return app.post("interaction",data);
}

const resource = async () => {
    return app.get("interaction/create");
}

const delete_interacao = async (id) => {
    return app.delete(`interaction/${id}`)
}

const put_interacao = async (data,id) => {
    return app.put(`interaction/${id}`,data)
}


export { register, resource, delete_interacao, put_interacao }