/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Header from "../../../Header/header";
import Sidebar from "../../../Sidebar/sidebar";
import Footer from "../../../Footer/footer";
import Table from "../../../Table/table";
import Thead from "../../../Table/thead";
import Tbody from "../../../Table/tbody";
import Tfoot from "../../../Table/tfoot";
import StylePage from "../../../Style Page/style_page";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  get,
  order,
  change_qtd_per_page,
  changePesquisa,
  clearPesquisa,
} from "./Actions/actions";
import {
  delete_produtividade,
  get_produtividade,
} from "../../../Services/produtividade";
import { pagination } from "../../../Pagination/paginations";
import { Link } from "react-router-dom";
import Pagination from "../../../Table/pagination";
import Cabecalho from "../../../Table/cabecalho";
import Alert from "../../../Alerts/alert";
import ModalExcluir from "../../../Modal/modal";
import ReactLoading from "react-loading";
import { Content } from "../../Auth/styleLoading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Productivitys from "./Productivitys";
import { useDispatch } from "react-redux";
import { HeaderCSV } from "./Docs/HeaderCSV";
import { CSVLink } from "react-csv";
import { get_module } from "../../../Services/moduloAvaliacao";

function GridProdutividade(props) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [productivitysGet, setProductivitysGet] = useState([]);
  const [module, setModule] = useState([]);
  const dispatch = useDispatch();
  //const talhao = 'type_area.name';
  const consultor = "consultant.name";
  const doenca = "type_doenca.name";
  const fileUpload = useRef();
  //const typeArea = useSelector(state => state.produtividade.type_area)
  const changeArea = () => {
    dispatch({
      type: "changeTypeArea",
      payload: props.match.params.typeArea === "manejo_syngenta" ? 1 : 2,
    });
  };

  let idRole = localStorage.getItem("id_role");

  useEffect(() => {
    getAvaliationModule();
  }, []);

  useEffect(() => {
    isValidAcess();
  });

  useEffect(() => {
    changeArea();
    return () => {
      changeArea();
    };
  }, [props.match.params.typeArea]);

  const getProductivitys = () => {
    props.get();
  };

  useEffect(() => {
    getProductivitys();
    return () => {
      getProductivitys();
    };
  }, []);

  const isValidAcess = () => {
    if (module == 2 && idRole == 2) {
      return (window.location.href = "/index");
    }
  };

  const getAvaliationModule = () => {
    get_module().then((resp) => {
      setModule(resp.data.pests_id);
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pode ser efetuado apenas uma produtividade para cada Talhão.
    </Tooltip>
  );

  const onChangeModal = (id_prod) => {
    setModal(true);
    setId(id_prod);
  };

  const handleCloseExcluir = (event) => {
    setModal(false);
    setId("");
  };

  const handleExcluir = (e) => {
    e.preventDefault();
    setLoading(true);
    setModal(false);

    delete_produtividade(id)
      .then((resp) => {
        setSuccess(resp.data.success);
        setError("");
        setLoading(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.data.error);
        setSuccess("");
        setModal(false);
        setTimeout(() => {
          setError("");
        }, 3000);
      })
      .then((resp) => props.get());
  };

  const download = (event) => {
    get_produtividade(
      props.page,
      props.qtd_total,
      props.typeArea,
      props.order_var,
      props.th_var,
      props.pesquisa
    )
      .then((resp) => {
        setProductivitysGet(resp.data.data);
      })
      .then((resp) => {
        fileUpload.current.link.click();
      });
  };
  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <StylePage>
        {loading === true ? (
          <Content>
            <ReactLoading
              type="spinningBubbles"
              color="blue"
              height={200}
              width={100}
            />
          </Content>
        ) : (
          ""
        )}
        {success ? <Alert type="success">{success}</Alert> : ""}
        {error ? <Alert type="danger">{error}</Alert> : ""}
        <ModalExcluir
          show={modal}
          onHide={handleCloseExcluir}
          title="Excluir Produtividade"
          content="Tem certeza que deseja excluir a produtividade ?"
          cancel={handleCloseExcluir}
          del={handleExcluir}
          to="#/"
        />
        <div style={{ display: loading === true ? "none" : "block" }}>
          <Table
            view={
              <div className="col-sm-12 col-md-5">
                <div
                  className="dataTables_info"
                  id="example1_info"
                  role="status"
                  aria-live="polite"
                >
                  Exibindo de {props.from} à {props.to} do total de{" "}
                  {props.qtd_total}
                </div>
              </div>
            }
            cabecalho={
              <Cabecalho
                buttonCsv={
                  props.id_role === 1 && (
                    <React.Fragment>
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={download}
                      >
                        <span className="fas fa-file-csv"> Exportar CSV</span>
                      </button>
                      <CSVLink
                        filename={`Relatorio Produtividade-${new Date()}.csv`}
                        headers={HeaderCSV}
                        hidden={true}
                        separator={";"}
                        ref={fileUpload}
                        data={productivitysGet || []}
                      >
                        <span className="fas fa-file-csv"> Exportar CSV</span>
                      </CSVLink>
                    </React.Fragment>
                  )
                }
                to="#/"
                action={props.get}
                clear={props.clearPesquisa}
                valuePesquisa={props.pesquisa}
                value={props.qtd_per_page}
                onChange={props.change_qtd_per_page}
                changePesquisa={props.changePesquisa}
              />
            }
            pagination={
              <Pagination>
                {props.pagination(
                  props.current_page,
                  props.get,
                  props.last_page
                )}
              </Pagination>
            }
            title={
              props.match.params.typeArea === "manejo_syngenta"
                ? "Registros de Produtividades de Manejo Syngenta"
                : "Registros de Produtividades de Manejo Produtor"
            }
            button={
              props.id_role === 2 ? (
                props.qtd_total >= 1 ? (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <button type="button" className="btn btn-primary">
                      Cadastro
                    </button>
                  </OverlayTrigger>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      props.history.push(
                        `/produtividade/${props.match.params.typeArea}`
                      )
                    }
                  >
                    Cadastro
                  </button>
                )
              ) : (
                ""
              )
            }
          >
            <Thead>
              {props.id_role === 1 && (
                <th>
                  CONSULTOR
                  <Link
                    to="#/"
                    style={{ float: "right" }}
                    onClick={() => props.order(consultor)}
                  >
                    <i
                      className={`right fas fa-sort-amount-${
                        props.th === "consultant.name"
                          ? props.asc === true
                            ? "down"
                            : "up"
                          : "down"
                      }-alt`}
                    ></i>
                  </Link>
                </th>
              )}
              <th>
                DOENÇA
                <Link
                  to="#/"
                  style={{ float: "right" }}
                  onClick={() => props.order(doenca)}
                >
                  <i
                    className={`right fas fa-sort-amount-${
                      props.th === "type_doenca.name"
                        ? props.asc === true
                          ? "down"
                          : "up"
                        : "down"
                    }-alt`}
                  ></i>
                </Link>
              </th>
              <th>Ações</th>
            </Thead>
            <Tbody>
              <Productivitys
                produtividades={props.productivitys}
                onDelete={onChangeModal}
                typeArea={props.match.params.typeArea}
                id_role={props.id_role}
              />
            </Tbody>
            <Tfoot>
              {props.id_role === 1 && <th>CONSULTOR</th>}
              <th>DOENÇA</th>
              <th>Ações</th>
            </Tfoot>
          </Table>
        </div>
      </StylePage>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  productivitys: state.produtividade.productivitys.data,
  current_page: state.produtividade.productivitys.current_page,
  last_page: state.produtividade.productivitys.last_page,
  from: state.produtividade.productivitys.from,
  to: state.produtividade.productivitys.to,
  qtd_total: state.produtividade.productivitys.total,
  qtd_per_page: state.produtividade.qtd_per_page,
  pesquisa: state.produtividade.pesquisa || "",
  id_role: state.auth.user.id_role,
  asc: state.produtividade.asc,
  order_var: state.produtividade.order || "",
  th: state.produtividade.th,
  typeArea: state.produtividade.type_area,
  th_var: state.produtividade.th || "",
  page: state.produtividade.page,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagination,
      get,
      order,
      change_qtd_per_page,
      changePesquisa,
      clearPesquisa,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridProdutividade);
