import {app} from './Api'

const getPhomas = async (page, qtd_per_page, typeArea, order, th, pesquisa, action) => {
    if (typeof action === "undefined") {
        action = "phomas";
    }
    return app.get(`phoma?action=${action}&page=${page}&qtd=${qtd_per_page}&id_type_area=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_bicho_mineiro = async (data) => {
    return app.post('bicho_mineiro', data)
}

const get_bicho_mineiro = async (id_area, type_area, avaliationId) => {
    return app.get(`bicho_mineiro?action=bicho_mineiro&id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_by_id = async (type_area, avaliationId, id) => {
    return app.get(`bicho_mineiro/${id}?action=bicho_mineiro&type_area=${type_area}&avaliation_id=${avaliationId}`)
}


const get_pagination = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`bicho_mineiro/pagination?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}


const get_avaliacao = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`bicho_mineiro/get_avaliations?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}

const export_csv = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`bicho_mineiro/export_csv?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}

const export_detailed = async (typeArea, pesquisa) => {
    return await app.get(`bicho_mineiro/export_detail?id_type_area=${typeArea}&pesquisa=${pesquisa}`);
}

const delete_phoma = async (id_area, type_area, avaliationId) => {
    return app.delete(`phoma?id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_area_bicho_mineiro = async (consultant = null) => {
    if (consultant) {
        return app.get(`bicho_mineiro/get_area?consultant=${consultant}`)
    } else {
        return app.get(`bicho_mineiro/get_area`)
    }
}

export {
    getPhomas,
    post_bicho_mineiro,
    get_bicho_mineiro,
    get_pagination,
    get_area_bicho_mineiro,
    get_avaliacao,
    get_by_id,
    delete_phoma,
    export_csv,
    export_detailed
}