import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'
import Alert from '../Alerts/alert'
import { useSelector } from 'react-redux'

export default props => (
    <Modal show={props.show} onHide={props.onHide} animation={false}>
        <Modal.Header id="excluir" closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {props.success ? 
                (
                    <div>
                        <Alert type="success" hidden={true}>{props.success}</Alert>
                    </div>
                ) : ("")
            }
            {props.error ? 
                (
                    <div>
                        <Alert type="danger" hidden={true}>{props.error}</Alert>
                    </div>
                ) : ("")
            }
            {props.content}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-success" onClick={props.cancel}>
                {props.textButton}
            </Button>
            <Link to={props.to} className="btn btn-danger" onClick={props.del}>
                Sair
            </Link>
        </Modal.Footer>
    </Modal>
)
