import React from 'react'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import Input from '../../../../Forms/input'


export default function Applications({ dataApplications, changeDoseApplication, optionsCategory, optionsEnterprise, optionsUnit, changeUnitApplication, changeCategoryApplication, changeEnterpriseApplication, optionsProduct, changeProductApplication, disabled, handleRemove }) {
    const applications = dataApplications || []
    return applications.map(desc=>(
        <tr key={desc.id} className="applications" id={`${desc.id}`}>
            <td width="20%">      
                <Select
                    value={desc.id_product}
                    name={`${desc.id}`}
                    label="Single select"
                    options={desc.optionsProduct.filter(desc => desc.value !== 'Novo Produto')}
                    onChange={changeProductApplication}
                    isDisabled={disabled}              
                />        
            </td>
            <td width="20%">      
                <Select
                    value={desc.id_category}
                    label="Single select"
                    options={desc.optionsCategory}
                    name={`${desc.id}`}
                    onChange={changeCategoryApplication}
                    isDisabled={disabled}              
                />
            </td>
            <td width="20%">      
                <Select
                    value={desc.id_enterprise}
                    label="Single select"
                    name={`${desc.id}`}
                    options={desc.optionsEnterprise.filter(desc => desc.value !== 'Nova Empresa')}
                    onChange={changeEnterpriseApplication}
                    isDisabled={disabled}              
                />       
            </td>
            <td width="18%">      
                <Select
                    value={desc.id_unit_measurement}
                    label="Single select"
                    options={optionsUnit}
                    name={`${desc.id}`}
                    onChange={changeUnitApplication}
                    isDisabled={disabled}              
                />
            </td>
            <td width="18%">
                <NumberFormat
                    icon="fas fa-flask"
                    name="dose"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={"."} 
                    decimalSeparator={","}
                    placeholder=""
                    customInput={Input}
                    onChange={changeDoseApplication} 
                    value={desc.dose}
                    disabled={disabled}                 
                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= (parseInt(desc.id_unit_measurement.value) === 3 ? 100 : 100000000);}}          
                />
            </td>
            <td>
                <div className="input-group mb-3" style={{paddingTop:'3px'}}>
                <button disabled={disabled} type="button" className="btn btn-danger" onClick={handleRemove}><span className="fas fa-minus"></span></button>
                </div>
            </td>
        </tr>
    ))
    
}