/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Wrapper, LogoStyle } from "./styles";
import { authentication } from "../../Services/auth";
import Alert from "../../Alerts/alert";
import Logo from "../../../Image/aliancaCafe.png";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState("");

  useEffect(() => {
    return handleRemove();
  }, []);

  function handleRemove() {
    localStorage.removeItem("token");
    localStorage.removeItem("id_role");
    localStorage.removeItem("consultant");
    props.logout();
    // setTimeout(()=> {

    //     window.location.reload()
    // },800)
  }

  const handleForm = (e) => {
    e.preventDefault();
    const data = { email: email, password: password };
    authentication(data)
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("id_role", res.data.user.id_role);
        localStorage.setItem("consultant", res.data.user.consultant);

        props.setLogin(res.data.user);
        res.data.user.password_confirmation === false
          ? props.history.push("/alter_password")
          : res.data.user.consultant === true
          ? props.history.push("/index")
          : props.history.push(`/consultor/${res.data.user.id_user}`);
      })
      .catch((e) => setError(e.response.data.error));
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  return (
    <React.Fragment>
      <Wrapper>
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card">
            <LogoStyle>
              <img
                src={Logo}
                alt="Logo Aliança Café"
                width="100%"
                height="100px"
                className="d-block"
              />
            </LogoStyle>
            <div className="card-body login-card-body">
              {error.errors ? (
                <p className="errors" style={{ color: "#DC143C" }}>
                  {error.errors}
                </p>
              ) : (
                sucesso
              )}
              <i
                style={{ marginLeft: "125px" }}
                className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"
              ></i>
              <h5 className="login-box-msg">Faça login na sua conta</h5>
              <p className="login-box-msg">Entre com suas credenciais</p>
              {error ? (
                <div>
                  <Alert type="danger" hidden="true">
                    {error}
                  </Alert>
                </div>
              ) : (
                ""
              )}
              <form onSubmit={handleForm}>
                <div className="input-group mb-3">
                  <input
                    onChange={handleEmail}
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Digite seu E-mail"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fa fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    onChange={handlePassword}
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Digite sua Senha"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fa fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      {/* <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                            Lembrar-me
                                            </label> */}
                    </div>
                  </div>

                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      Entrar
                    </button>
                  </div>
                </div>
              </form>
              <div className="social-auth-links text-center mb-2 mt-4">
                <p className="mb-1">
                  <a href="/forgot_password">Esqueci Minha Senha</a>
                </p>
              </div>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </Wrapper>
      {/* /.login-box */}
    </React.Fragment>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
        password_confirmation: user.password_confirmation,
        consultant: user.consultant,
        id_user: user.id_user,
      }),
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(Login);
