import {getCercosporas} from "../../../../Services/cercospora";

//Função que traz os dados do banco de dados
export const get = (page) => {
    return [(dispatch, getState) => {
        if (typeof page === "undefined") {
            page = getState().cercospora.cercosporasList.current_page
        }
        const order = getState().cercospora.order
        const th = getState().cercospora.th
        const qtd_per_page = getState().cercospora.qtd_per_page
        const pesquisa = getState().cercospora.pesquisa || ''
        const typeArea = getState().cercospora.type_area || ''

        getCercosporas(page, qtd_per_page, typeArea, order, th, pesquisa)
            .then(resp => {
                dispatch({type: 'CercosporasList', payload: resp.data})
            })
    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch, getState) => {
        const asc = getState().cercospora.asc

        if (asc === true) {
            dispatch({type: 'GetCercosporas_Desc', desc: true, asc: false, order: 'desc', th: coluna})

        }
        if (asc === false) {
            dispatch({type: 'GetCercosporas_Asc', asc: true, desc: false, order: 'asc', th: coluna})

        }

    }, get(1)]
}

export const change_qtd_per_page = event => {
    return [{
        type: 'qtd_per_page_Cercosporas',
        payload: event.target.value
    }, get(1)]
}

export const changePesquisa = event => {
    return [{
        type: 'pesquisaCercosporas',
        payload: event.target.value
    }]
}

export const clearPesquisa = event => {
    return [{
        type: 'clearPesquisaCercosporas',
        payload: ''
    }, get(1)]
}