import { app } from "./Api";

const get_module = async () => {
  return app.get(`assessment_module/getResource`);
};

const post_module = async (idPests) => {
  return app.post(`assessment_module/store`, idPests);
};

export { get_module, post_module };
