import { app } from './Api'

const getSpraying = async (page,qtd_per_page,typeArea,order,th,pesquisa,action) => {
    return app.get(`pulverization?page=${page}&action=${action}&qtd=${qtd_per_page}&id_type_area=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const getResource = async (type_area_id, product_id, enterprise_id) => {
    return app.get(`pulverization/create?type_area_id=${type_area_id || ''}&product_id=${product_id || ''}&enterprise_id=${enterprise_id || ''}`)
}

const postPulverization = async (data) => {
    return app.post(`pulverization`, data)
}
const putPulverization = async (id,data) => {
    return app.put(`pulverization/${id}`, data)
}

const viewPerId = async (id) => {
    return app.get(`pulverization/${id}`)
}

const delPulverization = async (id) => {
    return app.delete(`pulverization/${id}`)
}


export { getSpraying, getResource, postPulverization, viewPerId, delPulverization, putPulverization }