import {app} from './Api'

const getPhomas = async (page, qtd_per_page, typeArea, order, th, pesquisa, action) => {
    if (typeof action === "undefined") {
        action = "phomas";
    }
    return app.get(`phoma?action=${action}&page=${page}&qtd=${qtd_per_page}&id_type_area=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_broca = async (data) => {
    return app.post('broca', data)
}
const get_pagination = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`bicho_mineiro/pagination?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}

const get_avaliacao = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`broca/get_avaliations?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}

const export_csv = async (page, qtd_per_page, typeArea, pesquisa) => {
    return await app.get(`broca/export_csv?page=${page}&qtd=${qtd_per_page}&type_area=${typeArea}&pesquisa=${pesquisa}`);
}
const export_detailed = async (typeArea, pesquisa) => {
    return await app.get(`broca/export_detail?id_type_area=${typeArea}&pesquisa=${pesquisa}`);
}
const get_broca = async (id_area, type_area, avaliationId) => {
    return app.get(`broca?action=broca&id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const delete_phoma = async (id_area, type_area, avaliationId) => {
    return app.delete(`phoma?id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_by_id = async (type_area, avaliationId, id) => {
    return app.get(`broca/${id}?action=bicho_mineiro&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_area_broca = async (consultant = null) => {
    if (consultant) {
        return app.get(`broca/get_area?consultant=${consultant}`)
    } else {
        return app.get(`broca/get_area`)
    }
}

export {
    getPhomas,
    get_by_id,
    post_broca,
    get_broca,
    get_area_broca,
    delete_phoma,
    get_avaliacao,
    get_pagination,
    export_csv,
    export_detailed
}