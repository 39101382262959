import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Alert from '../../Alerts/alert'
import Form from '../../Forms/form'
import Style from '../../Style Page/style_page'
import {updatePassword} from '../../Services/auth'
import Input from '../../Forms/input'

export default class changePassword extends Component {

    state = {password: '', confirmPassword: ''}

    logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('id_role')
        localStorage.removeItem('consultant')
    }

    changePassword = event=>{
        const password = event.target.value
        this.setState({password : password})
    }

    changeConfirmPassword = event=>{
        const confirmPassword = event.target.value
        this.setState({confirmPassword : confirmPassword})
    }

    handleForm = (e)=>{
        e.preventDefault()
        const data = {
            password : this.state.password
        }

        if (this.state.password === this.state.confirmPassword) 
        {
            updatePassword(data)
            .then(resp => {this.setState({ success : 'Senha alterada com sucesso', error : ''})})
            .then(resp => (this.logout()))
            .then( resp => (
                setTimeout(() => {
                    window.location.reload();
                }, 1500)
            ))

        }
        else
        {
            this.setState({
                error : 'As senhas não conferem'
            })
        }
    }

    render() {
        return (
            <Style title='Alterar senha' subtitle='Alteração de senha'>
                {this.state.error ? (
                    <Alert type='danger'>
                        {this.state.error}
                    </Alert>
                ):
                ('')       
                }
                
                {this.state.success ? (
                    <Alert type='success'>
                        {this.state.success}
                    </Alert>
                ):
                ('')       
                }

                <Form onSubmit={this.handleForm}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Input 
                                label='Senha' 
                                onChange={this.changePassword} 
                                icon='fas fa-lock' 
                                type='password' 
                                name='password' 
                                placeholder='Digite sua senha' 
                                value={this.state.password}
                            />
                        </div>
                        
                        <div className='col-md-6'>
                            <Input 
                                label='Confirmar Senha' 
                                onChange={this.changeConfirmPassword} 
                                icon='fas fa-lock' type='password' 
                                name='password' 
                                placeholder='Confirme sua senha'
                                value={this.state.confirmPassword}
                            />
                        </div>
                    </div> 
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary">Salvar</button>                           
                        <Link to="/index" type="button" className="btn btn-secondary" style={{marginLeft:'10px'}}>Cancelar</Link>
                    </div>         
                </Form>
            </Style>
        )
    }
}
