import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NumberFormat from 'react-number-format'
import Style from '../../../Style Page/style_page'
import Form from '../../../Forms/form'
import Input from '../../../Forms/input'
import Select from '../../../Forms/select'
import {Link} from 'react-router-dom'
import Alert from '../../../Alerts/alert'
import Sidebar from '../../../Sidebar/sidebar'
import Header from '../../../Header/header'
import Footer from '../../../Footer/footer'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import { post_produtor,  put_producer, get_view_producer, get_producer } from '../../../Services/produtor'
import { 
    changeNome, 
    changeEmail, 
    changeCPF, 
    changeDataNasc, 
    changeTelefone, 
    changeCep, 
    changeLogradouro,
    changeNumero,
    changeUF,
    changeCidade,
    changeBairro,
    changeComplemento,
    getUFS,
    editView,
    getCitys,
    get,
    clearInput

} from './Actions/actions'


class Produtor extends Component {
    state = {error:[],alert:'',success:'',action:'', capacity:true, produtores:[],loading:false}

    async componentDidMount(){
        this.props.clearInput()
        get_producer(this.props.page,this.props.qtd_per_page,this.props.order,this.props.th,this.props.pesquisa)
        .then(resp => {this.setState({produtores:resp.data})})
        .then(
            resp=>{
                if(this.props.id_role === 2 && !this.props.match.params.action){
            
                    if(this.state.produtores.total >= 2){
                        this.setState({alert:'Limite de produtor no máximo !', capacity:false})
                        setTimeout(()=>{
                            this.props.history.push('/produtores')
                        },2000)
                        
                    }
                }

            }
        )
        

        
        const action = this.props.match.params.action
        this.setState({action:action})
        this.props.getUFS()
        if(action === 'edit' || action === 'view'){
            get_view_producer(this.props.match.params.id)
            .then(resp => (
                this.props.editView(
                    resp.data[0]
                )
            ))
           
            .then(resp => (this.props.getCitys()))
        }
    }

    handleForm=(e)=>{
        this.setState({loading:true})
        const action = this.props.match.params.action
        const id = this.props.match.params.id
        e.preventDefault();
        let type_phone = ''
        if(`${this.props.telefone}`[2] === '3' ||`${this.props.telefone}`[2] === '4' || `${this.props.telefone}`[2] === '2'){
            type_phone = 1
        }else{
            type_phone = 2
        }
        const data = {
            name: this.props.nome,
            birth_date: this.props.data_nascimento,
            document: this.props.cpf,
            zipcode: this.props.cep,
            state: this.props.uf,
            city: this.props.cidade,
            neighborhood: this.props.bairro,
            street: this.props.logradouro,
            number:this.props.numero,
            complement: this.props.complemento,
            email: this.props.email,
            number_tel: this.props.telefone,
            id_type_phone: type_phone,
            consultant_id:this.props.consultant_id
        }
        if(action === 'edit'){
            put_producer(data,id)
            .then(resp => {this.setState({loading:false,success:"Produtor editado com sucesso",error:[]})})
            .then(resp => (setTimeout(()=>{
                window.location.reload()
            },2000)))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))

        }else{

            post_produtor(data)
            .then(resp => {this.setState({loading:false,success:"Produtor cadastrado com sucesso",error:[]})})
            .then(resp => (setTimeout(()=>{
                this.props.history.push('/produtores')
            },2000)))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))
        }
       

    }

    handleUfs(){
        const ufs = this.props.ufs || []
        return ufs.map(desc => (
            <option key={desc.id} value={desc.uf}>{desc.name}</option>
        ))
    }

    handleCitys(){
        const cidades = this.props.cidades  || []
        return cidades.map(desc => (
            <option key={desc.id} value={desc.name}>{desc.name}</option>
        ))
    }

  
    render() {
        return (
            <div className="wrapper">
                <Sidebar />
                <Header />

                <Style title="Cadastro de Produtor" subtitle="Cadastrar Produtor">
                    {this.state.alert ? (
                        <Alert type="danger">
                            {this.state.alert}
                        </Alert>
                    ):('') }
                    {this.state.success ? (
                        <Alert type="success">
                            {this.state.success}
                        </Alert>
                    ):('') }
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                        
                    ):('')}
                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>

                        <Form onSubmit={this.handleForm}>
                            <div className="row">              
                                <div className="col-md-6">
                                    <Input 
                                        label="Nome" 
                                        icon="fa-user" 
                                        name="nome" 
                                        type="text" 
                                        placeholder="Digite o nome do produtor"
                                        onChange={this.props.changeNome}
                                        value={this.props.nome} 
                                        disabled={this.state.action === 'view' || this.state.capacity === false  ? (true):(false)}
                                        required={true}
                                    />
                                    {this.state.error.errorsProducer ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsProducer.name}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <Input 
                                        label="Email"            
                                        icon="fa-envelope" 
                                        name="email" 
                                        type="text" 
                                        placeholder="Digite o email do produtor"
                                        onChange={this.props.changeEmail}
                                        value={this.props.email} 
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                        required={true}
                                    />
                                    {this.state.error.errorsProducer ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsProducer.email}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <NumberFormat 
                                        allowEmptyFormatting={false} 
                                        icon="fa-address-card"  
                                        mask='' 
                                        placeholder="Digite o CPF do produtor" 
                                        label="CPF"	
                                        customInput={Input}   
                                        format={"###.###.###-##"}
                                        onChange={this.props.changeCPF}
                                        value={this.props.cpf} 
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorsProducer ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsProducer.document}</p>
                                    ):(
                                        ''
                                    )}              
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        label="Data Nascimento"  
                                        icon="fa-user" 
                                        name="data_nascimento" 
                                        type="date"
                                        max={this.props.date_max}
                                        min="1900-01-01" 
                                        placeholder="Digite a data de nascimento"
                                        onChange={this.props.changeDataNasc}
                                        value={this.props.data_nascimento}
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorsProducer ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsProducer.birth_date}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <NumberFormat 
                                        allowEmptyFormatting={false} 
                                        label="Telefone"  
                                        mask='' 
                                        customInput={Input} 
                                        format={
                                            `${this.props.telefone}`[2] === '3' || `${this.props.telefone}`[2] === '4' || `${this.props.telefone}`[2] === '2' ?(
                                                "(##) ####-####"
                                            ):(
                                                "(##) #####-####"
                                            )
                                        } 
                                        value={this.props.telefone}
                                        onChange={this.props.changeTelefone}
                                        icon="fa-phone"  
                                        name="telefone" 
                                        type="text" 
                                        required={true}
                                        placeholder="Digite o telefone do produtor" 
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorPhone ? (<p style={{color:'red'}}>{this.state.error.errorPhone.number_tel}</p>):('')}                              
                                </div>
                            </div>
                            <hr></hr>
                            <br></br>
                            <h3>Endereço</h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <NumberFormat 
                                        allowEmptyFormatting={false} 
                                        icon="fa-street-view"  
                                        mask='' 
                                        placeholder="Digite o CEP" 
                                        label="CEP" 
                                        customInput={Input}  
                                        format={"#####-###"}
                                        onChange={this.props.changeCep}
                                        value={this.props.cep}
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)} 
                                    />
                                    {this.state.error.errorsAddress ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddress.zipcode}</p>
                                    ):(
                                        ''
                                    )}                              
                                </div>
                                <div className="col-md-6">
                                    <Input 
                                        label="Logradouro"  
                                        icon="fa-home" 
                                        name="logradouro" 
                                        type="text" 
                                        placeholder="Rua,avenida,etc..."
                                        onChange={this.props.changeLogradouro}
                                        value={this.props.logradouro}
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorsAddress ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddress.street}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                                <div className="col-md-2">
                                    <Input 
                                        label="Numero"  
                                        icon="fa-home" 
                                        name="numero" 
                                        type="number" 
                                        placeholder="Número"
                                        onChange={this.props.changeNumero}
                                        value={this.props.numero}
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorsAddressConsultant ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddressConsultant.number}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                            
                            </div>
                            <div className="row">
                            <div className="col-md-2">
                                    <Select required={true} disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)} label="UF" onChange={this.props.changeUF} value={this.props.uf}>
                                        <option value="">Selecione a UF</option>
                                        {this.handleUfs()}
                                    
                                    </Select>
                                    {this.state.error.errorsAddress ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddress.state}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                                <div className="col-md-3">
                                    <Select required={true} disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)} label="Cidade" onChange={this.props.changeCidade} value={this.props.cidade}>
                                        <option>Selecione a Cidade</option>
                                        {this.handleCitys()}
                                        
                                    </Select>
                                    {this.state.error.errorsAddress ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddress.city}</p>
                                    ):(
                                        ''
                                    )}
                                </div>                           
                            
                                <div className="col-md-4">
                                    <Input 
                                        label="Bairro" 
                                        icon="fa-home" 
                                        name="bairro" 
                                        type="text" 
                                        placeholder="Digite o bairro"
                                        onChange={this.props.changeBairro}
                                        value={this.props.bairro}
                                        required={true}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error.errorsAddress ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsAddress.neighborhood}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                                <div className="col-md-3">
                                    <Input 
                                        label="Complemento" 
                                        icon="fa-home" 
                                        name="complemento" 
                                        type="text" 
                                        placeholder="Complemento"
                                        onChange={this.props.changeComplemento}
                                        value={this.props.complemento}
                                        required={false}
                                        disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)}
                                    />
                                    {this.state.error ? (
                                        <p style={{color:'red'}}>{this.state.error.complemento}</p>
                                    ):(
                                        ''
                                    )}
                                </div>             
                            </div>
                            <br></br>
                            <div className="text-right">
                                <Link to="/produtores" type="button" className="btn btn-secondary" style={{marginRight:'10px'}}>Voltar</Link>
                                <button disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)} type="submit" className="btn btn-primary">Salvar</button>                           
                            </div>        
                        </Form>
                    </div>
                
                </Style>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    nome:state.produtor.nome,
    cpf:state.produtor.cpf,
    email:state.produtor.email,
    data_nascimento:state.produtor.data_nascimento,
    telefone:state.produtor.telefone,
    cep:state.produtor.cep,
    logradouro:state.produtor.logradouro,
    numero:state.produtor.numero,
    uf:state.produtor.uf,
    cidade:state.produtor.cidade,
    bairro:state.produtor.bairro,
    complemento:state.produtor.complemento,
    ufs:state.produtor.ufs,
    cidades:state.produtor.cidades,
    consultant_id:state.auth.user.consultant_id,
    produtores:state.produtor.produtores.data,
    id_role:state.auth.user.id_role,
    order:state.produtor.order,
    th:state.produtor.th,
    qtd_per_page:state.produtor.qtd_per_page,
    pesquisa:state.produtor.pesquisa || '',
    data_atual:state.produtor.data_atual,
    date_max:state.produtor.date_max,

   
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeNome, 
    changeEmail, 
    changeCPF, 
    changeDataNasc, 
    changeTelefone, 
    changeCep, 
    changeLogradouro,
    changeNumero,
    changeUF,
    changeCidade,
    changeBairro,
    changeComplemento,
    getUFS,
    editView,
    getCitys,
    get,
    clearInput
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Produtor)
