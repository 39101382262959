export const HeaderCSV = [
    {label:'Consultor',key:'name'},
    {label: 'Avaliacão 1', key: 'avaliation_1'},
    {label: 'Avaliacão 2', key: 'avaliation_2'},
    {label: 'Avaliacão 3', key: 'avaliation_3'},
    {label: 'Avaliacão 4', key: 'avaliation_4'},
    {label: 'Avaliacão 5', key: 'avaliation_5'},
    {label: 'Avaliacão 6', key: 'avaliation_6'},
    {label: 'Avaliacão 7', key: 'avaliation_7'},
    {label: 'Data', key: 'newDate'},
]