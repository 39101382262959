import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { delete_consultant, get_consultant } from '../../../Services/consultant'
import {pagination} from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/pagination'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ModalExcluir from '../../../Modal/modal'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import Consultants from './Consultants'
import { CSVLink } from "react-csv"
import { HeaderCSV } from './Docs/HeaderCSV'

function GridConsultor(props) {
    const [ loading, setLoading ] = useState(false)
    const [ modal, setModal ] = useState(false)
    const [ id, setId ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ error, setError ] = useState('')
    const [ getConsultantsAll, setGetConsultants ] = useState([])
    const cpf = 'consultant.cpf';
    const name = 'consultant.name';
    const email = 'user.email';
    const fileUpload = useRef()

    const getConsultants = () => {
        props.get();
    }
    
    useEffect(() => {
        getConsultants();
        return () => {
            getConsultants()
        }
        
    },[])
    
    const onChangeModal = (id) => {
        setModal(true)
        setId(id)
    }

    const handleCloseExcluir = event => {
        setModal(false)
        setId('')
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true)
        setModal(false)
        delete_consultant(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
            .then(resp => (
                setTimeout(()=> {
                    setSuccess('')
                },4000)
    
            ))      
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)

        })
        .then(resp => (props.get()))
    }

    const download = event => { 
        get_consultant(props.page,props.qtd_total,props.order_var,props.th_var,props.pesquisa,)
        .then(resp => {
            setGetConsultants(resp.data.data)  
        })
        .then(resp => {
            // console.log(refunds)
            fileUpload.current.link.click()
        })
        
    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <ModalExcluir
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Consultor"
                    content="Tem certeza que deseja excluir o consultor ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="/consultores"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        } 
                        cabecalho={
                            <Cabecalho 
                                buttonCsv={
                                    props.id_role === 1 && (
                                        <React.Fragment>                                 
                                            <button className="btn btn-outline-success btn-sm" onClick={download}>
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </button>
                                          
                                            <CSVLink
                                                filename={`Relatorio-${new Date()}.csv`}
                                                headers={HeaderCSV}
                                                hidden={true}
                                                separator={";"}
                                                ref={fileUpload}
                                                data={getConsultantsAll || []}
                                        
                                            >
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </CSVLink>
                                        </React.Fragment>

                                    )
                                }
                                to="#/" 
                                action={props.get} 
                                clear={props.clearPesquisa} 
                                valuePesquisa={props.pesquisa} 
                                value={props.qtd_per_page} 
                                onChange={props.change_qtd_per_page} 
                                changePesquisa={props.changePesquisa} 
                            />
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page)}</Pagination>} title="Consultores">
                        <Thead>
                            <th>CPF<Link to="/consultores" style={{float:'right'}} onClick={()=>props.order(cpf)}><i className={`right fas fa-sort-amount-${props.th === 'consultant.cpf' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>NOME<Link to="/consultores" style={{float:'right'}} onClick={()=>props.order(name)}><i className={`right fas fa-sort-amount-${props.th === 'consultant.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>EMAIL<Link to="/consultores" style={{float:'right'}} onClick={()=>props.order(email)}><i className={`right fas fa-sort-amount-${props.th === 'user.email' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>Ações</th>
                        </Thead>
                        <Tbody>
                            <Consultants consultants={props.consultores} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            <th>CPF</th>
                            <th>NOME</th>
                            <th>EMAIL</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}
const mapStateToProps = state => ({
    consultores:state.consultor.consultores.data,
    current_page:state.consultor.consultores.current_page,
    last_page:state.consultor.consultores.last_page,
    from:state.consultor.consultores.from,
    to:state.consultor.consultores.to,
    qtd_total:state.consultor.consultores.total,
    qtd_per_page:state.consultor.qtd_per_page,
    pesquisa:state.consultor.pesquisa || '',
    asc:state.consultor.asc,
    th:state.consultor.th,
    id_role:state.auth.user.id_role,
    order_var:state.consultor.order || '',
    th_var:state.consultor.th || '' ,
    page:state.consultor.page

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({pagination, get, order, change_qtd_per_page, changePesquisa, clearPesquisa}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridConsultor)
