import { app } from './Api'

const post_topic = async (data) => {
    return app.post('topic',data)
}

const get_topic = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`topic?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const delete_topic = async (id) => {
    return app.delete(`topic/${id}`)
}

const get_view_topic = async (id) => {
    return app.get(`topic/${id}`)
}

const put_topic = async (data,id) => {
    return app.put(`topic/${id}`,data)
}


export { post_topic, get_topic, delete_topic , put_topic, get_view_topic}