import React, {useEffect, useRef, useState} from "react";
import Header from "../../../Header/header";
import Sidebar from "../../../Sidebar/sidebar";
import StylePage from "../../../Style Page/style_page";
import Alert from "../../../Alerts/alert";
import {Content} from "../../Auth/styleLoading";
import ReactLoading from "react-loading";
import Table from "../../../Table/table";
import Cabecalho from "../../../Table/cabecalho";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {bindActionCreators} from "redux";
import {pagination} from "../../../Pagination/paginations";
import {connect} from 'react-redux'
import {get, order, change_qtd_per_page, changePesquisa, clearPesquisa} from "./Actions/actions";

import Tbody from "../../../Table/tbody";
import Thead from '../../../Table/thead';
import Tfoot from '../../../Table/tfoot';
import {Link} from "react-router-dom";
import Pagination from "../../../Table/pagination";
import TOrder from "../../../Table/TOrder";
import {CSVLink} from "react-csv";
import {getPhomas as getPhomasCsv, delete_phoma} from "../../../Services/phoma";
import ModalExcluir from "../../../Modal/modal";

const GridPhomaAdmin = (props) => {
    const id_role = useSelector(state => state.auth.user.id_role);

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState(false)
    const typeArea = props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2
    const [phomaGetCsv, setPhomaGetCsv] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(null);
    const headerCSV = [
        {label: 'Nome do Consultor', key: 'nome_consultor'},
        {label: 'Doença', key: 'nome_doenca'},
        {label: 'Manejo', key: 'manejo'},
        {label: 'Nº da Avaliação', key: 'avaliacao'},
        {label: 'Data', key: 'data_phoma'},
        {label: 'Ponto', key: 'ponto_phoma'},
        {label: 'Tipo de Avaliacao', key: 'tipo_avaliacao'},
        {label: 'Ramo', key: 'phoma_ramo0'},
        {label: 'Ramo', key: 'phoma_ramo1'},
        {label: 'Ramo', key: 'phoma_ramo2'},
        {label: 'Ramo', key: 'phoma_ramo3'},
        {label: 'Ramo', key: 'phoma_ramo4'},
        {label: 'Ramo', key: 'phoma_ramo5'},
        {label: 'Ramo', key: 'phoma_ramo6'},
        {label: 'Ramo', key: 'phoma_ramo7'},
        {label: 'Ramo', key: 'phoma_ramo8'},
        {label: 'Ramo', key: 'phoma_ramo9'},
        {label: 'Presença/Ausencia', key: 'doenca_phoma'},
    ]
    const fileUpload = useRef()

    const changeArea = () => {
        dispatch({type: 'changeTypeArea', payload: props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2})
    }

    const getPhomas = () => {
        return props.get();
    }
    useEffect(() => {
        changeArea();
        return () => {
            changeArea();
        }
    }, [])
    useEffect(() => {
        getPhomas();
        return () => {
            dispatch({type: 'phomas', payload: []})
        }
    }, [])
    useEffect(() => {
        if (deleting) {
            setShowDeleteModal(() => true);
        } else {
            setShowDeleteModal(() => false);
        }
    }, [deleting])

    const downloadCsv = () => {
        getPhomasCsv(props.page, props.qtd_total, props.typeArea, props.order_var, props.th_var, props.pesquisa, "csv")
            .then(resp => {
                setPhomaGetCsv(resp.data);
            })
            .then(resp => {
                fileUpload.current.link.click();
            })
    }

    const handleDeleteAvaliacao = () => {

        delete_phoma(deleting.id_area, deleting.id_type_area, deleting.avaliacao_phoma).then(resp => {
            setShowDeleteModal(false);
            setSuccess(resp.data.success);
            getPhomas();
        }).then(() => {
            setTimeout(() => {
                setSuccess(null);
            }, 3000)
        });
    }
    const handleCloseExcluir = () => {
        setDeleting(() => null);
    }

    return (<div className="wrapper">
        <ModalExcluir
            show={showDeleteModal}
            title="Excluir Avaliação"
            content="Tem certeza que deseja excluir a avaliação ?"
            cancel={handleCloseExcluir}
            del={handleDeleteAvaliacao}
            to="#/"
        />
        <Header/>
        <Sidebar/>
        <StylePage title={typeArea === 1 ? 'Avaliação Phoma Manejo Syngenta' : 'Avaliação Phoma Manejo Produtor'}>
            {alert ? (<div>
                <Alert type="danger" hidden={true}>{alert}</Alert>
            </div>) : ("")}
            {loading === true ? (<Content>
                    <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} className="mt-5"/>
                </Content>

            ) : ('')}
            {success ? (<div>
                <Alert type="success" hidden={true}>{success}</Alert>
            </div>) : ("")}
            {error ? (<div>
                <Alert type="danger" hidden={true}>{error}</Alert>
            </div>) : ("")}
            <div style={{display: loading === true || alert !== false ? ('none') : ('block')}}>
                <Table
                    view={<div className={"col-sm-12 col-md-5"}>
                        <div className="dataTables_info" id={"phoma_info"} role="status" aria-live="polite">
                            Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                        </div>
                    </div>}
                    cabecalho={
                        <Cabecalho
                            buttonCsv={
                                <>
                                    <button className="btn btn-outline-success btn-sm" onClick={downloadCsv}>
                                        <span className="fas fa-file-csv"/> Exportar CSV
                                    </button>
                                    <CSVLink
                                        filename={`Relatorio Avaliação Phoma -${new Date()}.csv`}
                                        headers={headerCSV}
                                        hidden={true}
                                        separator={";"}
                                        ref={fileUpload}
                                        data={phomaGetCsv || []}

                                    >
                                        <span className="fas fa-file-csv"> Exportar CSV</span>
                                    </CSVLink>
                                </>
                            }
                            to="#/"
                            action={props.get}
                            clear={props.clearPesquisa}
                            valuePesquisa={props.pesquisa}
                            value={props.qtd_per_page}
                            onChange={props.change_qtd_per_page}
                            changePesquisa={props.changePesquisa}
                        />
                    }
                    title={typeArea === 1 ? 'Avaliação Phoma Manejo Syngenta' : 'Avaliação Phoma Manejo Produtor'}
                    pagination={
                        <Pagination>{props.pagination(props.current_page, props.get, props.last_page)}</Pagination>}
                >
                    <Thead>
                        <th>Consultor<TOrder columnName="consultant.name"
                                             orderColumn={props.th} asc={props.asc}
                                             onOrder={props.order}/></th>
                        <th>Avaliação<TOrder columnName="phoma.avaliation"
                                             orderColumn={props.th} asc={props.asc}
                                             onOrder={props.order}/></th>
                        <th>Data<TOrder columnName="phoma.dataPhome"
                                        orderColumn={props.th} asc={props.asc}
                                        onOrder={props.order}/></th>
                        <th>Ações</th>
                    </Thead>
                    <Tbody>
                        {(props.phomas && props.phomas.length) > 0 && (
                            props.phomas.map(phoma =>
                                <React.Fragment key={Math.random(1, 493893) * 39}>
                                    {(phoma.avaliacao_phoma && phoma.data_phoma) && (
                                        <tr key={`phoma-${phoma.id}`}>
                                            <td>{phoma.nome_consultor}</td>
                                            <td>{phoma.avaliacao_phoma}</td>
                                            <td>{phoma.data_phoma}</td>
                                            <td>
                                                <ul className="nav">
                                                    <li className="nav-item dropdown">
                                                        <a className="nav-link" data-toggle="dropdown" href="#/">
                                                            <i className="fas fa-ellipsis-h"/>
                                                        </a>
                                                        <div className='dropdown-menu dropdown-menu-right'>
                                                            <Link
                                                                to={`/phomas/${props.match.params.typeArea}/${phoma.id_consultor}/${phoma.avaliacao_phoma}`}
                                                                className='dropdown-item'><i
                                                                className='fas fa-eye'></i> Detalhes</Link>
                                                            <Link to="#/" className='dropdown-item' onClick={() => {
                                                                setDeleting(phoma);
                                                                setShowDeleteModal(true);
                                                            }}><i className='fas fa-trash-alt'></i> Excluir</Link>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )
                        )}
                        {(!props.phomas || props.phomas.length === 0) && <tr>
                            <td colSpan={4} className="text-center">Nenhum Resultado Encontrado</td>
                        </tr>}
                    </Tbody>
                    <Tfoot>
                        <th>Consultor</th>
                        <th>Avaliação</th>
                        <th>Data</th>
                        <th>Ações</th>
                    </Tfoot>
                </Table>
            </div>

        </StylePage>
    </div>)
}

const mapStateToProps = state => {
    return {
        phomas: state.phoma.phomasList.data,
        current_page: state.phoma.phomasList.current_page,
        last_page: state.phoma.phomasList.last_page,
        from: state.phoma.phomasList.from,
        to: state.phoma.phomasList.to,
        qtd_total: state.phoma.phomasList.total,
        qtd_per_page: state.phoma.qtd_per_page,
        pesquisa: state.phoma.pesquisa || '',
        id_role: state.auth.user.id_role,
        asc: state.phoma.asc,
        th: state.phoma.th,
        order_var: state.phoma.order || '',
        th_var: state.phoma.th,
        page: state.phoma.page,
        typeArea: state.phoma.type_area
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        pagination,
        get,
        order,
        change_qtd_per_page,
        changePesquisa,
        clearPesquisa
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridPhomaAdmin)