import React, {useState, useEffect, useRef} from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, clearInput } from './Actions/actions'
import { delete_area, get_area } from '../../../Services/talhao'
import {pagination} from '../../../Pagination/paginations'
import {Link, useHistory} from 'react-router-dom'
import Pagination from '../../../Table/pagination'
import { useDispatch, useSelector } from 'react-redux'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ModalExcluir from '../../../Modal/modal'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { HeaderCSV } from './Docs/HeaderCSV'
import { CSVLink } from "react-csv"
import Areas from './Areas'


function GridArea(props) {
    const history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ modal, setModal ] = useState(false)
    const [ id, setId ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ error, setError ] = useState('')
    const [ getAreasAll, setGetAreas ] = useState([])
    const tipoTalhao = 'type_area.name';
    const tamanhoTalhao = 'area.area_size_ha';
    const fazenda = 'area.farm';
    const consultor = 'consultant.name'
    const cidade = 'city.name'
    const irrigacao = 'irrigation.name'
    const fileUpload = useRef()
    const dispatch = useDispatch()
    // const titleArea = props.match.params.typeArea === 'manejo_syngenta' ? "Manejo Syngenta" : 'Manejo Produtor'
    // const changeArea = () => {
    //     dispatch({type:'changeAreaType', payload:props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2})
    // }
    // const typeArea = useSelector(state => state.talhao.type_area)

    // useEffect(() => {
    //     changeArea();
    //     return () => {
    //         changeArea()   
    //     }
        
    // },[props.match.params.typeArea])
 
    useEffect(() => {
        getAreas();
        return () => {
            getAreas()
        }
        
    },[])
    
    const getAreas = () => {
        props.get();
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {`Pode ser efetuado apenas um cadastro de talhão`}
        </Tooltip>
    );

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = e => {
        e.preventDefault();
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delete_area(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
    }

    const download = event => { 
        get_area(props.page,props.qtd_total,props.order_var,props.th_var,props.pesquisa)
        .then(resp => {
            setGetAreas(resp.data.data)  
        })
        .then(resp => {
            fileUpload.current.link.click()
        })    
    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <ModalExcluir
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir talhão"
                    content="Tem certeza que deseja excluir o talhão ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }        
                        cabecalho={
                            props.id_role === 1 &&(
                                <Cabecalho 
                                    buttonCsv={
                                        <React.Fragment>
                                            <button className="btn btn-outline-success btn-sm" onClick={download}>
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </button>                          
                                            <CSVLink
                                                filename={`Relatorio-${new Date()}.csv`}
                                                headers={HeaderCSV}
                                                hidden={true}
                                                separator={";"}
                                                ref={fileUpload}
                                                data={getAreasAll || []}
                                        
                                            >
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </CSVLink>
                                        </React.Fragment>
                                    }
                                    to="#/" 
                                    action={props.get} 
                                    clear={props.clearPesquisa} 
                                    valuePesquisa={props.pesquisa} 
                                    value={props.qtd_per_page} 
                                    onChange={props.change_qtd_per_page} 
                                    changePesquisa={props.changePesquisa} 
                                />

                            )
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page)}</Pagination>} title={`Registros de Talhões`}
                        button={
                            props.id_role === 2 ? (props.qtd_total >=1 ? (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{show : 250, hide: 400}}
                                    overlay={renderTooltip}
                                >
                                    <button 
                                        type="button" 
                                        className="btn btn-primary" 
                                    >
                                        Cadastro
                                    </button>

                                </OverlayTrigger>
                            ):( 
                                <button 
                                    type="button" 
                                    className="btn btn-primary" 
                                    onClick={()=>props.history.push(`/cadastro_talhao/`)}>Cadastro
                                </button>
                                )):''
                            
                        }>
                        <Thead>
                            {/* <th>TIPO<Link to="#/" style={{float:'right'}} onClick={()=>props.order(tipoTalhao)}><i className={`right fas fa-sort-amount-${props.th === 'type_area.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th> */}
                            <th>CONSULTOR<Link to="#/" style={{float:'right'}} onClick={()=>props.order(consultor)}><i className={`right fas fa-sort-amount-${props.th === 'consultant.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>TAMANHO (HA)<Link to="#/" style={{float:'right'}} onClick={()=>props.order(tamanhoTalhao)}><i className={`right fas fa-sort-amount-${props.th === 'area.area_size_ha' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>FAZENDA<Link to="#/" style={{float:'right'}} onClick={()=>props.order(fazenda)}><i className={`right fas fa-sort-amount-${props.th === 'area.farm' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>CIDADE<Link to="#/" style={{float:'right'}} onClick={()=>props.order(cidade)}><i className={`right fas fa-sort-amount-${props.th === 'city.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>IRRIGAÇÃO<Link to="#/" style={{float:'right'}} onClick={()=>props.order(irrigacao)}><i className={`right fas fa-sort-amount-${props.th === 'irrigation.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>Ações</th>
                        </Thead>
                        <Tbody>
                            <Areas id_role={props.id_role} areas={props.talhoes} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            <th>CONSULTOR</th>
                            <th>TAMANHO (HA)</th>
                            <th>FAZENDA</th>
                            <th>CIDADE</th>
                            <th>IRRIGAÇÃO</th>
                            <th>Ações</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}
const mapStateToProps = state => ({
    talhoes:state.talhao.talhoes.data,
    current_page:state.talhao.talhoes.current_page,
    last_page:state.talhao.talhoes.last_page,
    from:state.talhao.talhoes.from,
    to:state.talhao.talhoes.to,
    qtd_total:state.talhao.talhoes.total,
    qtd_per_page:state.talhao.qtd_per_page,
    pesquisa:state.talhao.pesquisa || '',
    id_role:state.auth.user.id_role,
    asc:state.talhao.asc,
    th:state.talhao.th,
    order_var:state.talhao.order || '',
    th_var:state.talhao.th || '' ,
    page:state.talhao.page

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({pagination, get, order, change_qtd_per_page, changePesquisa, clearPesquisa, clearInput}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridArea)
