import { getUFS, cidadesporUF, getenderecocep, getUFSAPI, cidadesporUFAPI } from '../../../../Services/endereco'
import { get_consultant } from '../../../../Services/consultant'

let time = null

/**
* @param event 
*  Função que pega o nome digitado
*/
export const changeNome = event => {
    return [{
        type: 'Change_Nome',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 */
export const changeCidadeAtuacao = event => {
    return[{
        type: 'Change_CidadeAtuacao',
        payload: event.target.value
    }]
}

/**
* @param event 
* Função que pega a data de Nascimento 
*/
export const changeDataNascimento = event => {
    return [{
        type: 'Change_DataNascimento',
        payload: event.target.value
    }]
}

/**
* 
* @param event 
* Função que pega o email digitado
*/
export const changeEmail = event => {
    return [{
        type: 'Change_Email',
        payload: event.target.value
    }]
}

/**
* 
* @param event 
* Função que pega o CPF digitado
*/
export const changeCPF = event => {
    return [{
        type: 'Change_CPF',
        payload: event.target.value.replace('.', '').replace('.', '').replace('-', '')
    }]
}

/**
* 
* @param event 
* Função que pega o telefone digitado
*/
export const changeTelefone = event => {
    return [{
        type: 'Change_Telefone',
        payload: event.target.value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
    }]
}

/**
* 
* @param event 
* Função que pega a UF escolhida e chama a função que busca as cidades de acordo com a UF escolhida
*/
export const changeUF = event => {
    return [{
        type: 'Change_Uf',
        payload: event.target.value
    }, getCidades()]
}

/**
* 
* @param event
* Função que pega a Cidade selecionada
*/
export const changeCidade = event => {
    return [{
        type: 'Change_Cidade',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event
 * Função que pega o CEP digitado  e após 2 segundos busca o endereço na API
 */
export const changeCep = event => {
    return [
        // dispactch => {dispatch({type: 'loadingCep', loading:true})},
        dispatch => {
            dispatch({type: 'loadingCep', loading:true}) 
            dispatch({
                type: 'changeCep',
                payload: event.target.value.replace('-', '')

            })     
    },  dispatch => {
            clearTimeout(time)
            time = setTimeout(()=>{
                dispatch(
                    getToCep()
                )
            },3000)
        }
    ]
}

/**
 * 
 * @param event
 * Função que pega o logradouro digitado 
 */
export const changeLogradouro = event => {
    return [{
        type: 'Change_Logradouro',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event
 * Função que pega o número digitado 
 */
export const changeNumero = event => {
    return [{
        type: 'Change_Numero',
        payload: event.target.value
    }]
}

/**
 * @param event 
 * Função que pega o bairro digitado
 */
export const changeBairro = event => {
    return [{
        type: 'Change_Bairro',
        payload: event.target.value
    }]
}

/**
 * @param event 
 * Função que pega o complemento digitado
 */
export const changeComplemento = event => {
    return [{
        type: 'Change_Complemento',
        payload: event.target.value
    }]
}

/**
 * Função que consome a API com todas as UFS
 */
// export const UFS = () => {
//     return dispatch => {
//         getUFS()
//         .then(resp => dispatch({ type: 'Get_Ufs', payload: resp.data.sort(ordenar)}))
//     }
// }

export const UFS = () => {
    return dispatch => {
        getUFSAPI()
        .then(resp => dispatch({type:'Get_Ufs', payload:resp.data}))
        
    }
}

/**
 * Função que consome uma api com todas as cidades realcionadas à UF escolhida
 */
// export const getCidades = () => {
//     return (dispatch, getState) => {
//         const uf = getState().consultor.uf
//         cidadesporUF(uf)
//             .then(resp => dispatch({ type: 'Get_Cidades', payload: resp.data }))
//     }
// }

export const getCidades = () => {
    return (dispatch,getState) => {
        const state_uf = getState().consultor.uf
        cidadesporUFAPI(state_uf)
        .then(resp => dispatch({type:'Get_Cidades', payload:resp.data}))
    }
}

/**
 * 
 * @returns UF,BAIRRO,CIDADE,RUA
 * Função que consome uma API passando o CEP
 */
export const getToCep = () => {
    return[clear(),
        (dispatch, getState) => {
            
            const cep = getState().consultor.cep
            if (cep) {
                
                getenderecocep(cep)
                    .then(resp => dispatch({ type: 'Get_To_Ceps', payload: resp.data, bairro: resp.data.bairro, uf: resp.data.uf, logradouro: resp.data.logradouro, cidade: resp.data.localidade }))
                    .then(resp => dispatch(getCidades()))
    
            }
            dispatch({type: 'loadingCep', loading:false})
        }
    ] 
}



//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().consultor.order
        const th = getState().consultor.th
        const qtd_per_page = getState().consultor.qtd_per_page
        const pesquisa = getState().consultor.pesquisa || ''       
      
        get_consultant(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'get_consultant', payload:resp.data}))

    }]
}

/**
 * 
 * @param event 
 * Função que define a quantidade de itens à ser exibido por página
 */
export const qtdPerPage = event => {
    return [{
        type: 'qtdPerPage',
        payload: event.target.value
    },
    // get(1)
    ]
}

/**
 * 
 * @param event 
 * Função que pega o valor digitado no filtro
 */
export const getChangeFiltro = event => {
    return [{
        type: 'changeFiltro',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 * Função que limpa o filtro
 */
export const clearFiltro = event => {
    return [{
        type: 'ClearFiltro',
        payload: ''
    },
    //  get(1)
    ]
}

/**
 * Função que limpa os campos endereços na árvore do redux
 */
export const clear = () => {
    return [{
        type: 'Clear',
        uf: '',
        cidade: '',
        logradouro: '',
        bairro: '',
        
        

    }]
}

/**
 * Função que limpa os campos
 * 
 */
export const clearInputs = ()  => {
    return [{
        type:'clearInputs',
        nome:'',
        telefone:'',
        cpf:'',
        data_nascimento:'',
        cidade_atuacao:'',
        uf: '',
        cidade: '',
        logradouro: '',
        bairro: '',
        cep:'',
        complemento:'',
        numero:''

    }]
}

/**
 * 
 * @param {object} a 
 * @param {object} b 
 * Função para ordenar o objeto com as UFS
 */
export const ordenar = (a, b) =>{
    return a.sigla < b.sigla ? -1 : a.sigla > b.sigla ? 1 : 0;
}

/**
 * Função que pega os dados de um determinado ID
 * @param {Object} data 
 */
export const editView = (data) => {
    return dispatch => {
        if(data){
            dispatch({

                type:'editView',
                consultant_id:data.consultant_id || '',
                nome: data.name || '',
                email: data.email || '',
                cpf: data.cpf || '',
                telefone: data.telefone || '',
                cep: data.zipcode || '',
                uf: data.state || '',
                cidade: data.city || '',
                logradouro: data.street || '',
                numero: data.number || '',
                bairro: data.neighborhood || '',
                data_nascimento: data.birth_date || '',
                cidade_atuacao:data.acting_city || '',
                complemento:data.complement || ''
            })

        }
    }
           
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().consultor.asc
      
        if(asc === true){
            dispatch({type:'GetConsultor_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetConsultor_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_consultor',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaConsultor',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaConsultor',
        payload:''
    },get(1)]
}
