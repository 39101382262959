import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'


const ConsultantRouter = ({component:Component, ...rest }) =>{
   
    
    return (  
        <Route
            {...rest}
            
            render={props => {
                const token = localStorage.getItem('token')
                
                if(token){
                    // return <Component {...props} />
                    if(rest.password_confirmation === true){
                        
                        return <Component {...props} />
                    }else{
                        return <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />         
                    }
                }else{
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />  

                }     
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
        loggedIn:state.auth.loggedIn, 
        password_confirmation:state.auth.password_confirmation,
          
    }
}
export default connect(mapStateToProps)(ConsultantRouter);