import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { delete_producer, get_producer } from '../../../Services/produtor'
import {pagination} from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/pagination'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ModalExcluir from '../../../Modal/modal'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Producers from './Producers'
import { HeaderCSV } from './Docs/HeaderCSV'
import { CSVLink } from "react-csv"

function GridProducer(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const [ getProducersAll, setGetProducers ] = useState([])
    const cpf = 'producer.document';
    const name = 'producer.name';
    const email = 'producer.email';
    const fileUpload = useRef()

    
    useEffect(() => {
        getProducers();
        return () => {
            getProducers()
        }
        
    },[])
    
    const getProducers = () => {
        props.get();
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Pode ser efetuado apenas dois cadastro de produtor por consultor.
        </Tooltip>
    );

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delete_producer(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)     
        })
        .then(resp => (props.get()))
    }

    const download = event => { 
        get_producer(props.page,props.qtd_total,props.order_var,props.th_var,props.pesquisa)
        .then(resp => {
            setGetProducers(resp.data.data)  
        })
        .then(resp => {
            fileUpload.current.link.click()
        })    
    }

    return (
        <div className="wrapper">           
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <ModalExcluir
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir produtor"
                    content="Tem certeza que deseja excluir o produtor ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="/produtores"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        } 

                        cabecalho={
                            props.id_role === 1 ? (

                                <Cabecalho
                                    buttonCsv={
                                        <React.Fragment>
                                            <button className="btn btn-outline-success btn-sm" onClick={download}>
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </button>                          
                                            <CSVLink
                                                filename={`Relatorio-${new Date()}.csv`}
                                                headers={HeaderCSV}
                                                hidden={true}
                                                separator={";"}
                                                ref={fileUpload}
                                                data={getProducersAll || []}
                                        
                                            >
                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                            </CSVLink>
                                        </React.Fragment>
                                    } 
                                    to="#/" 
                                    action={props.get} 
                                    clear={props.clearPesquisa} 
                                    valuePesquisa={props.pesquisa} 
                                    value={props.qtd_per_page} 
                                    onChange={props.change_qtd_per_page} 
                                    changePesquisa={props.changePesquisa} 
                                />
                            ):('')
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page)}</Pagination>} title="Produtores"
                                                    
                        button={
                            props.id_role === 2 ? (props.qtd_total >=2 ? (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{show : 250, hide: 400}}
                                    overlay={renderTooltip}
                                >
                                    <button 
                                        type="button" 
                                        className="btn btn-primary" 
                                    >
                                        Cadastro
                                    </button>

                                </OverlayTrigger>
                            ):( 
                                <button 
                                    type="button" 
                                    className="btn btn-primary" 
                                    onClick={()=>props.history.push("/produtor")}>Cadastro
                                </button>
                                )):(
                                    <button 
                                        type="button" 
                                        className="btn btn-primary" 
                                        onClick={()=>props.history.push("/produtor")}>Cadastro
                                    </button>
                                ) 
                            
                        }>
                        <Thead>
                            <th>CPF<Link to="/produtores" style={{float:'right'}} onClick={()=>props.order(cpf)}><i className={`right fas fa-sort-amount-${props.th === 'producer.document' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>NOME<Link to="/produtores" style={{float:'right'}} onClick={()=>props.order(name)}><i className={`right fas fa-sort-amount-${props.th === 'producer.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>EMAIL<Link to="/produtores" style={{float:'right'}} onClick={()=>props.order(email)}><i className={`right fas fa-sort-amount-${props.th === 'producer.email' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>CONSULTOR</th>
                            <th>Ações</th>
                        </Thead>
                        <Tbody>
                            <Producers producers={props.produtores} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            <th>CPF</th>
                            <th>NOME</th>
                            <th>EMAIL</th>
                            <th>CONSULTOR</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            <Footer />
        </div>
    )
}
const mapStateToProps = state => ({
    produtores:state.produtor.produtores.data,
    current_page:state.produtor.produtores.current_page,
    last_page:state.produtor.produtores.last_page,
    from:state.produtor.produtores.from,
    to:state.produtor.produtores.to,
    qtd_total:state.produtor.produtores.total,
    qtd_per_page:state.produtor.qtd_per_page,
    pesquisa:state.produtor.pesquisa || '',
    id_role:state.auth.user.id_role,
    asc:state.produtor.asc,
    th:state.produtor.th,
    order_var:state.produtor.order || '',
    th_var:state.produtor.th || '' ,
    page:state.produtor.page

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({pagination, get, order, change_qtd_per_page, changePesquisa, clearPesquisa}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridProducer)
