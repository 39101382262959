import {app} from './Api'

const getPhomas = async (page, qtd_per_page, typeArea, order, th, pesquisa, action) => {
    if (typeof action === "undefined") {
        action = "phomas";
    }
    return app.get(`phoma?action=${action}&page=${page}&qtd=${qtd_per_page}&id_type_area=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_phoma = async (data) => {
    return app.post('phoma', data)
}

const get_phoma = async (id_area, type_area, avaliationId) => {
    return app.get(`phoma?action=phoma&id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const delete_phoma = async (id_area, type_area, avaliationId) => {
    return app.delete(`phoma?id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_area_phomas = async (consultant = null) => {
    if (consultant) {
        return app.get(`phoma/get_area?consultant=${consultant}`)
    } else {
        return app.get(`phoma/get_area`)
    }
}

export {
    getPhomas,
    post_phoma,
    get_phoma,
    get_area_phomas,
    delete_phoma
}