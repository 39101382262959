import './Dependencies/dependencies'
import './Dependencies/jquery-loader'
import React, { useEffect, useState, Component } from "react";
import AuthRouter from './Componentes/AuthRouter'
import Login from './Componentes/Pages/Auth/Login'
import Index from './Componentes/Pages/Index/index'
import Consultor from './Componentes/Pages/Cadastros/Consultor/Consultant'
import Register from './Componentes/Pages/Auth/register'
import ChangePassword from './Componentes/Pages/Auth/changePassword'
import AlterPasswordConfirm from './Componentes/Pages/Auth/alter_password'
import ForgotPassword from './Componentes/Pages/Auth/forgot_password'
import FirstAccessRouter from './Componentes/FirstAccessRouter'
import ConsultantRouter from './Componentes/ConsultantRouter'
import GridConsultor from './Componentes/Pages/Cadastros/Consultor/GridConsultor'
import GridTalhao from './Componentes/Pages/Cadastros/Talhao/GridArea'
import Produtor from './Componentes/Pages/Cadastros/Produtor/produtor'
import GridProdutor from './Componentes/Pages/Cadastros/Produtor/GridProducer'
import GridPhoma from './Componentes/Pages/Cadastros/Phoma/GridPhoma'
import {BrowserRouter, Route, Switch} from "react-router-dom"
import RememberPassword from './Componentes/Pages/Auth/remember_password'
import LoginRouter from './Componentes/LoginRouter';
import Area from './Componentes/Pages/Cadastros/Talhao/Area';
import GridCercospora from './Componentes/Pages/Cadastros/Cercospora/GridCercospora';
import GridTopico from './Componentes/Pages/Cadastros/Topico/GridTopico';
import Topico from './Componentes/Pages/Cadastros/Topico/topico'
import Interacao from './Componentes/Pages/Interacao/interacao'
import Pulverization from './Componentes/Pages/Cadastros/CropProtection/Pulverization/Pulverization'
import GridPulverization from './Componentes/Pages/Cadastros/CropProtection/Pulverization/GridPulverization'
import Productivity from './Componentes/Pages/Cadastros/Produtividade/Productivity'
import GridProductivity from './Componentes/Pages/Cadastros/Produtividade/GridProdutividade'
import GridPhomaAdmin from "./Componentes/Pages/Cadastros/Phoma/GridPhomaAdmin";
import {useSelector} from "react-redux";
import GridCercosporaAdmin from "./Componentes/Pages/Cadastros/Cercospora/GridCercosporaAdmin";
import ModuloAvaliacao from './Componentes/Pages/Cadastros/ModuloAvaliacao/ModuloAvaliacao';
import GridBichoMineiro from './Componentes/Pages/Cadastros/BichoMineiro/GridBichoMineiro'
import GridBroca from './Componentes/Pages/Cadastros/Broca/GridBroca'
import { get_module} from "./Componentes/Services/moduloAvaliacao";
import GridBichoMineiroAdmin from './Componentes/Pages/Cadastros/BichoMineiro/GridBichoMineiroAdmin';
import GridBichoMineiroDetalhes from './Componentes/Pages/Cadastros/BichoMineiro/GridBichoMineiroDetalhes';
import GridBrocaAdmin from './Componentes/Pages/Cadastros/Broca/GridBrocaAdmin';
import GridBrocaDetalhes from './Componentes/Pages/Cadastros/Broca/GridBrocaDetalhes';

const Routes = () => {
    const id_role = useSelector(state => state.auth.user.id_role);
    const [assentmentModule, setAssentmentModule] = useState("");
    const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');

    useEffect(() => {
        getModuloAvaliacao();
      }, []);
    
      const getModuloAvaliacao = () => {
        get_module().then((resp) => {
          setAssentmentModule(resp.data.pests_id);
        });
      };

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <AuthRouter path="/bicho_mineiro/detalhes/:typeArea/:id" component={GridBichoMineiroDetalhes}/>
                    <AuthRouter path="/broca/detalhes/:typeArea/:id" component={GridBrocaDetalhes}/>
                    <LoginRouter exact path='/' component={Login}/>

                    {/* Route from Login */}
                    <Route path="/login" component={Login}/>

                    {/* Route from Index */}
                    <AuthRouter path="/index" component={Index}/>

                    {/* Route from Register */}
                    <AuthRouter path="/register" component={Register}/>

                    {/* Routes from Passwords */}
                    <Route path="/forgot_password" component={ForgotPassword}/>
                    <Route path="/change_password" component={ChangePassword}/>
                    <Route path="/reset_form" component={RememberPassword}/>
                    <FirstAccessRouter path="/alter_password" component={AlterPasswordConfirm}/>

                    {/* Routes from Consultant */}
                    <ConsultantRouter exact path="/consultor/:id" component={Consultor}/>
                    <ConsultantRouter path="/consultor/:id/:action" component={Consultor}/>
                    <AuthRouter path="/consultores" component={GridConsultor}/>
                    <AuthRouter path="/consultor_edit/:id" component={Consultor}/>


                    {/* Routes from Producer */}
                    <AuthRouter path="/produtor" exact component={Produtor}/>
                    <AuthRouter path="/produtor/:id/:action" exact component={Produtor}/>
                    <AuthRouter path="/produtores" component={GridProdutor}/>

                    {/* Routes from Area */}
                    <AuthRouter path="/cadastro_talhao/" exact component={Area}/>
                    <AuthRouter path="/talhao/:id/:action" exact component={Area}/>
                    <AuthRouter path="/talhoes/" component={GridTalhao}/>

                    {/* Topic */}
                    <AuthRouter path="/topico" exact component={Topico}/>
                    <AuthRouter path="/topico/:id/:action" exact component={Topico}/>
                    <AuthRouter path="/topicos" component={GridTopico}/>

                    {/* Route from Interação Online */}
                    <AuthRouter path="/interacao_online_ao_vivo" component={Interacao}/>
                    <AuthRouter path="/interacao_online_ao_vivo/:id/:action" exact component={Interacao}/>

                    {/* Routes from Pulverization */}
                    <AuthRouter path="/pulverizacao/:typeArea" exact component={Pulverization}/>
                    <AuthRouter path="/pulverizacao/:typeArea/:id/:action" exact component={Pulverization}/>
                    <AuthRouter path="/pulverizacoes/:typeArea" component={GridPulverization}/>

                {/* Routes from Productivity */}
                <AuthRouter path="/produtividade/:typeArea" exact component={Productivity} />
                <AuthRouter path="/produtividade/:typeArea/:id/:action" exact component={Productivity} />
                <AuthRouter path="/produtividades/:typeArea" component={GridProductivity} />

                    {/* Routes from Soil */}
                    <AuthRouter path="/phomas/:typeArea/:consultantId/:avaliacao" component={GridPhoma}/>
                    <AuthRouter path="/cercosporas/:typeArea/:consultantId/:avaliacao" component={GridCercospora}/>
                    {/* <AuthRouter path="/phoma/:typeArea" exact component={Phoma} />
                <AuthRouter path="/phoma/:typeArea/:id/:action" exact component={Phoma} /> */}
                    {(id_role === 2 || !id_role) && (
                        <>
                            
                            {assentmentModule === 2 ?
                                <>
                                    <AuthRouter path="/bicho_mineiro/:typeArea" component={GridBichoMineiro} />
                                    <AuthRouter path="/broca/:typeArea" component={GridBroca} />
                                </>
                                    :
                                <>
                                    <AuthRouter path="/phomas/:typeArea" component={GridPhoma} />
                                    <AuthRouter path="/cercosporas/:typeArea" component={GridCercospora} />
                                </>
                            }
                            
                        </>
                    )}
                    {id_role === 1 && (
                        <>
                            <AuthRouter path="/modulo" component={ModuloAvaliacao}/>
                            {assentmentModule === 2 ? 
                            <> 
                                <AuthRouter path="/bicho_mineiro/:typeArea" component={GridBichoMineiroAdmin}/>
                                <AuthRouter path="/broca/:typeArea" component={GridBrocaAdmin} />
                            </> : 
                            <>
                                <AuthRouter path="/phomas/:typeArea" component={GridPhomaAdmin}/>
                                <AuthRouter path="/cercosporas/:typeArea" component={GridCercosporaAdmin}/>
                            </>
                            }
                        </>
                    )}
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}
export default Routes;