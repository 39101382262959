const AuthReducer = (state = {}, actions) => {
    switch (actions.type) {
        case 'SET_LOGIN':
            return {...state, loggedIn:true,user:actions.payload,password_confirmation:actions.password_confirmation,consultant:actions.consultant,id_user:actions.id_user};
       
            case 'SET_LOGOUT':
            
                return {...state, loggedIn:false,user:'',consultant:'',password_confirmation:'',id_user:''};
    
        default:
            return state;
    }
}
export default AuthReducer;