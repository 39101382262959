import React, { useState, useEffect, useMemo, useRef } from "react";
import './style.css';
import { CSVLink } from "react-csv"
import { HeaderCSV } from '../Docs/HeaderCSV'
import { HeaderCSVDetailed } from "../Docs/HeaderCSVDetailed"; 

import {get_pagination, get_avaliacao, export_detailed,export_csv} from '../../../../Services/bichoMineiro'
import {useDispatch, useSelector} from 'react-redux'


export default function Style_page (props){
    const [dataExport, setDataExport] = useState([]);
    const [dataExportDetailed, setDataExportDetailed] = useState([]);
    const pesquisa = useSelector(state => state.bicho_mineiro.pesquisa) || '';

    let qtd_per_page = useSelector(state =>state.bicho_mineiro.qtd_per_page);
    let page = useSelector(state => state.bicho_mineiro.current_page);
    const bicho_mineiro_list = useSelector(state => state.bicho_mineiro.bichoMineiroList);
    const fileUpload = useRef();
    const fileUploadDetail = useRef();

    const onClickBack = (typeArea) => {
        window.location = `/bicho_mineiro/${typeArea}`;
    }

    const downloadDetailed = (event) => {
        export_detailed(props.typeArea, pesquisa).then(resp => {
            console.log(resp.data)
            setDataExportDetailed(resp.data);
        }).then(resp => {
            fileUploadDetail.current.link.click()
        }).catch(resp => {
            console.log("ERROR", resp);
        });
    }

    const download = (event) => { 
        
        export_csv(page, qtd_per_page, props.typeArea, pesquisa).then(resp => {
            
            let res = resp.data.avaliations;
            let data = []
            
            res.forEach((item) => {
                let objEx = {...item}

                    let i = 1;
                    item.avaliations.forEach((app) => {
                        if(i == 1){
                            let obj = {'avaliation_1': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 2){
                            let obj = {'avaliation_2': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 3){
                            let obj = {'avaliation_3': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 4){
                            let obj = {'avaliation_4': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 5){
                            let obj = {'avaliation_5': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 6){
                            let obj = {'avaliation_6': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        if(i == 7){
                            let obj = {'avaliation_7': app.completed ? "Completa": "Incompleta", "newDate" : item.date == null ? '' : item.date};
                            objEx = {...objEx, ...obj}
                        }
                        i++;
                    })     
                    data.push(objEx);

            })
            setDataExport(data);
        }).then(resp => {
            fileUpload.current.link.click()
        }).catch(resp => {
            console.log("ERROR", resp);
        })
         
     }
        return (
            <div className="content-wrapper" >
                <div className="content-header"
                    style={{marginBottom: '15px', display: 'flex', flexDirection: 'row' }}
                >
                    <div className="container-fluid">
                        {props.enableBackButton === true ? (
                            <div className="row mb-2">
                                <div
                                    id='back-button'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#FFF',
                                        width: '125px',
                                        height: '40px',
                                        borderRadius: '4px',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        marginLeft: '0.5rem',
                                        padding: '10px 16px 10px 16px',
                                        background: '#64748B',
                                        fontSize: '18px',
                                        fontStyle: 'bold',
                                        fontWeight: 'bold 1rem',
                                    }}
                                    onClick={(e) => {
                                        onClickBack(props.typeArea)
                                    }}
                                >
                                        VOLTAR
                                </div>
                            </div>
                        ) :
                         <></>
                        }
                        
                        <div className="row mb-2" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height:'30px', marginBottom: '30px'}}>
                            <div className="col-sm-6" style={{width: '50%', marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                <h1>{props.title}</h1>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'start', marginBottom: '10px', width: '50%'}}>
                            {props.enableExportPDF === true ? (
                                <div className="row mb-2" id="button-space" style={{display:'flex', flexDirection: 'row'}}>
                                    <React.Fragment>
                                    <div
                                        style={{width: '200px'}}
                                        id='csv-button'
                                        onClick={downloadDetailed}
                                    >
                                        Relatório Detalhado
                                    </div>
                                    <CSVLink
                                        filename={`Relatorio-Detalhado-Bicho-Mineiro-${new Date()}.csv`}
                                        headers={HeaderCSVDetailed}
                                        hidden={true}
                                        separator={";"}
                                        ref={fileUploadDetail}
                                        data={dataExportDetailed || []}
                                
                                    >
                                        <span className="fas fa-file-csv"> Exportar CSV</span>
                                    </CSVLink>
                                    </React.Fragment>

                                    <React.Fragment>
                                    <div
                                        style={{width: '200px'}}
                                        id='csv-button'
                                        onClick={download}
                                    >
                                        Relatório de Entregas
                                    </div>
                                    <CSVLink
                                        filename={`Relatorio-de-Entregas-Bicho-Mineiro-${new Date()}.csv`}
                                        headers={HeaderCSV}
                                        hidden={true}
                                        separator={";"}
                                        ref={fileUpload}
                                        data={dataExport || []}
                                
                                    >
                                        <span className="fas fa-file-csv"> Exportar CSV</span>
                                    </CSVLink>
                                    </React.Fragment>
                                </div>
                            ) :
                            <></>
                            }
                        </div>
                        </div>
                        <div 
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            padding: '0',
                            display: 'flex',
                            justifyContent: 'left',
                            fontSize: '20px'
                        }}>
                            {props.userName ? props.userName : ''}
                        </div>
                    </div>{/* /.container-fluid */}
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h3 className="card-title">{props.subtitle}</h3>
                                    <div className="text-right">
                                        {props.button || ''}
                                    </div>
                                </div> */}
                                {props.children}
                            </div>
                        </div>
                    </div>
                </section>
               
            </div>

        )
    }
