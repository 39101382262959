import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NumberFormat from 'react-number-format'
import Style from '../../../Style Page/style_page'
import Form from '../../../Forms/form'
import Input from '../../../Forms/input'
import Select from '../../../Forms/select'
import {Link} from 'react-router-dom'
import Alert from '../../../Alerts/alert'
import Sidebar from '../../../Sidebar/sidebar'
import Header from '../../../Header/header'
import Footer from '../../../Footer/footer'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import { post_produtor,  put_producer, get_view_producer, get_producer } from '../../../Services/produtor'
import { 
    changeNome, 
    get,
    editView,
    clearInput

} from './Actions/actions'
import { get_topic, get_view_topic, post_topic, put_topic } from '../../../Services/topico'


class Topico extends Component {
    state = {error:[],alert:'',success:'',action:'', capacity:true, topicos:[],loading:false}

    async componentDidMount(){
        this.props.clearInput()
        get_topic(this.props.page,this.props.qtd_per_page,this.props.order,this.props.th,this.props.pesquisa)
        .then(resp => {this.setState({topicos:resp.data})})
        .then(
            resp=>{
                if(this.props.id_role === 2 && !this.props.match.params.action){
                    this.setState({alert:'Somente ADM tem permissão !', capacity:false})
                    setTimeout(()=>{
                        this.props.history.push('/topicos')
                    },2000)
                        
                }

            }
        )
        

        
        const action = this.props.match.params.action
        this.setState({action:action})
        if(action === 'edit' || action === 'view'){
            get_view_topic(this.props.match.params.id)
            .then(resp => (
                this.props.editView(
                    resp.data[0]
                )
            ))
        }
    }

    handleForm=(e)=>{
        this.setState({loading:true})
        const action = this.props.match.params.action
        const id = this.props.match.params.id
        e.preventDefault();

        const data = {
            name: this.props.nome
        }
        if(action === 'edit'){
            put_topic(data,id)
            .then(resp => {this.setState({loading:false,success:"Tópico editado com sucesso",error:[]})})
            .then(resp => (setTimeout(()=>{
                window.location.reload()
            },2000)))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))

        }else{

            post_topic(data)
            .then(resp => {this.setState({loading:false,success:"Tópico cadastrado com sucesso",error:[]})})
            .then(resp => (setTimeout(()=>{
                this.props.history.push('/topicos')
            },2000)))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))
        }
       

    }

  
    render() {
        return (
            <div className="wrapper">
                <Sidebar />
                <Header />

                <Style title={
                    this.state.action === 'edit' ? ("Edição de Tópico") : this.state.action === 'view' ? ("Visualização de Tópico"):("Cadastro de Tópico")} subtitle={
                    this.state.action === 'edit' ? ("Edição de Tópico") : this.state.action === 'view' ? ("Visualização de Tópico"):("Cadastro de Tópico")}>
                    {this.state.alert ? (
                        <Alert type="danger">
                            {this.state.alert}
                        </Alert>
                    ):('') }
                    {this.state.success ? (
                        <Alert type="success">
                            {this.state.success}
                        </Alert>
                    ):('') }
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                        
                    ):('')}
                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>

                        <Form onSubmit={this.handleForm}>
                            <div className="row">              
                                <div className="col-md-12">
                                    <Input 
                                        label="Nome" 
                                        icon="fa-user" 
                                        name="nome" 
                                        type="text" 
                                        placeholder="Digite o nome do tópico"
                                        onChange={this.props.changeNome}
                                        value={this.props.nome} 
                                        disabled={this.state.action === 'view' || this.state.capacity === false  ? (true):(false)}
                                        required={true}
                                        maxLength="191"
                                    />
                                    {this.state.error.errorsTopic ? (
                                        <p style={{color:'red'}}>{this.state.error.errorsTopic.name}</p>
                                    ):(
                                        ''
                                    )}
                                </div>
                            </div>
                            <br></br>
                            <div className="text-right">
                                <Link to="/topicos" type="button" className="btn btn-secondary" style={{marginRight:'10px'}}>Voltar</Link>
                                <button disabled={this.state.action === 'view' || this.state.capacity === false ? (true):(false)} type="submit" className="btn btn-primary">Salvar</button>                           
                            </div>        
                        </Form>
                    </div>
                
                </Style>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    nome:state.topico.nome,
    topicos:state.topico.topicos.data,
    id_role:state.auth.user.id_role,
    order:state.topico.order,
    th:state.topico.th,
    qtd_per_page:state.topico.qtd_per_page,
    pesquisa:state.topico.pesquisa || '',
    data_atual:state.topico.data_atual,
    date_max:state.topico.date_max,

   
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeNome, 
    get,
    editView,
    clearInput
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Topico)
