import React, { useRef } from 'react'
import Input from '../../../../Forms/input'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'

export default function ModelForm(props) {
    const selectRef = useRef(null);
    const selectRefApplication = useRef(null);
    const selectRefMethod = useRef(null);
    const selectRefTypeAplication = useRef(null);
    const current_date = useSelector(state => state.pulverization.data_atual)
    return (
        <div className="row">
            {/* <div className={`col-md-${`${props.valueApplication.value}`.split('ª ')[1] === 'Capina Mecânica' ? 4 : 3}`}>
                <label>Safra</label>
                <Select
                    value={props.valueHarvest}
                    label="Single select"
                    options={props.optionsHarvest}
                    onChange={props.changeHarvest}
                    isDisabled={props.disabled}
                    ref={selectRef}              
                />
                {props.valueHarvest.length <= 0 && 
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute"
                        }}
                        value={props.valueHarvest}
                        onFocus={() => selectRef.current.focus()}
                        required={true}
                    />
                }
            </div> */}
                {props.module === 2 ? 
                <div className={`col-md-2`}>
                    <label>Tipo Aplicação</label>
                    <Select 
                        value={props.valueMethod}
                        label="Single select"
                        options={props.optionsMethods}
                        onChange={props.changeMethods}
                        isDisabled={props.disabled}
                        
                    
                    />
                    {props.valueMethod.length <= 0 && 
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: "100%",
                                height: 0,
                                position: "absolute"
                            }}
                            value={props.valueMethod}
                            // onFocus={() => selectRefMethod.current.focus()}
                            required={true}
                        />
                    }
                    {props.error.id_method_aplication ? (<p style={{ color: 'red', marginTop: 5 }}>{props.error.id_method_aplication}</p>) : ('')}
                </div> : <></> }
            {/* <div className={`col-md-${`${props.valueApplication.value}`.split('ª ')[1] === 'Capina Mecânica' ? 4 : 3}`}></div> */}
            <div className={props.module === 2 ? `col-md-2` : `col-md-3`}>
                <label>Aplicação</label>
                <Select 
                    value={props.valueApplication}
                    label="Single select"
                    options={props.optionsApplication}
                    onChange={props.changeApplication}
                    isDisabled={props.disabled}
                    ref={selectRefApplication}
                
                />
                {props.valueApplication.length <= 0 && 
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute"
                        }}
                        value={props.valueApplication}
                        onFocus={() => selectRefApplication.current.focus()}
                        required={true}
                    />
                }
            </div>
            <div className={`col-md-${`${props.valueApplication.value}`.split('ª ')[1] === 'Capina Mecânica' ? 4 : 3}`}>
                <Input
                    label="Data"
                    type="date"
                    icon="fas fa-calendar"
                    onChange={props.changeDate}
                    value={props.valueDate}
                    min="1900-01-01"  
                    max={current_date}
                    required={true}
                    disabled={props.disabled}          
                />
            </div>
            {`${props.valueApplication.value}`.split('ª ')[1] !== 'Capina Mecânica' &&
                <div className={`col-md-2`}>
                    <NumberFormat
                        label="Calda Aplicada (L/ha)" 
                        icon="fas fa-flask"
                        name="tail_volume"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator={"."} 
                        decimalSeparator={","}
                        placeholder=""
                        customInput={Input}
                        onChange={props.changeTail} 
                        value={props.valueTail}
                        disabled={props.disabled}
                        required={`${props.valueApplication.value}`.split('ª ')[1] === 'Capina Mecânica' ? false : true}
                        isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 100000000;}}          
                    />
                </div>
            }
            <div className={`col-md-${`${props.valueApplication.value}`.split('ª ')[1] === 'Capina Mecânica' ? 4 : 3}`}>
                <label>Forma de Aplicação</label>
                <Select 
                    value={props.valueAplication}
                    label="Single select"
                    options={props.optionsAplication}
                    onChange={props.changeAplication}
                    isDisabled={props.disabled}
                    ref={selectRefTypeAplication}
                
                />
                {props.valueApplication.length <= 0 && 
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute"
                        }}
                        value={props.valueAplication}
                        onFocus={() => selectRefTypeAplication.current.focus()}
                        required={true}
                    />
                }
                {props.error.type_aplication ? (<p style={{ color: 'red', marginTop: 5 }}>{props.error.type_aplication}</p>) : ('')}
            </div>
        </div>
    )
}
