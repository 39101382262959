import { combineReducers } from "redux";
import AuthReducer from './AuthReducer';
import ConsultorReducer from './ConsultorReducer'
import TalhaoReducer from './TalhaoReducer'
import ProdutorReducer from "./ProdutorReducer"
import Phoma from "./PhomaReducer"
import Cercospora from "./CercosporaReducer"
import TopicoReducer from "./TopicoReducer";
import PulverizationReducer from "./PulverizationReducer";
import ProductivityReducer from "./ProductivityReducer";
import UserReducer from "./UserReducer";

import Menssage from "./MenssageReducer"
import BichoMineiroReducer from "./BichoMineiroReducer";
import BrocaReducer from "./BrocaReducer";

const AllReducers = combineReducers({
    auth: AuthReducer, 
    bicho_mineiro: BichoMineiroReducer,
    consultor:ConsultorReducer, 
    produtor: ProdutorReducer, 
    talhao:TalhaoReducer,
    phoma: Phoma,
    cercospora: Cercospora,
    topico:TopicoReducer,
    menssage: Menssage,
    pulverization:PulverizationReducer,
    produtividade: ProductivityReducer,
    user: UserReducer,
    broca: BrocaReducer
})

export default AllReducers;