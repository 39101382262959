import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from '../Modal/modaAlert';
import { post_cercospora } from '../Services/cercospora';
import { post_phoma } from '../Services/phoma';
import { get_module, post_module } from '../Services/moduloAvaliacao'


function NavItem(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const alertModalPhoma = useSelector(state => state.phoma.alertModal);
    const alertModalCercospora = useSelector(state => state.cercospora.alertModal);
    const alertModalPulverizacao = useSelector(state => state.pulverization.alertModal);
    const phomasStore = useSelector(state => state.phoma.phomas);
    const cercosporaStore = useSelector(state => state.cercospora.cercosporas);
    const [modal, setModal] = useState(false);
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')

    const assentmentModule = props.assentmentModule ?? -1;
    const [ phomaECercospora, setPhomaECercospora ] = useState('Phoma e Cercospora');
    const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');

    // useEffect(() => {
    //     getModuloAvaliacao();
    //     console.log(assessmentModule)
    // },[])

    // const getModuloAvaliacao = () => {
    //     get_module()
    //         .then(resp => {
    //             console.log(resp.data.pests_id);
    //             switch (resp.data.pests_id) {
    //                 case 1:
    //                         setAssessmentModule(phomaECercospora);
    //                     break;
    //                 case 2:
    //                         setAssessmentModule(bichoMineiroEBroca);
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         })      
    // }

    const handleClick = (e) =>{
        e.preventDefault();
        if( alertModalPulverizacao === 1 ){
            dispatch({ type: 'set_alert_warning_pulverizacao', payload: 1 })
        }

        else if(alertModalPhoma === 1 || alertModalCercospora === 1){
            setModal(true)
            // console.log('alertModalPhoma', alertModalPhoma);
            // console.log('alertModalCercospora', alertModalCercospora);
            console.log('alertModalPulverizacao', alertModalPulverizacao);
        } else {
           window.location.href = props.to; 
        }
    }
    
    const handleCloseModal = async(e) =>{
        e.preventDefault();
        dispatch({ type: 'set_alert_modal_phoma', payload: 0 })
        dispatch({ type: 'set_alert_modal_cercospora', payload: 0 })
        dispatch({ type: 'set_alert_modal_pulverizacao', payload: 1 })
        setModal(false)
        window.location.href = props.to;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(alertModalPhoma === 1){
            await post_phoma(phomasStore)
            .then(resp => {
                setSuccess(resp.data.success);
                setTimeout(() => {
                    setSuccess('');
                    window.location.href = props.to;
                },3500)
            })
            .catch(err => {
                setError(err.response.data.error);
                    setTimeout(() => {
                        setError('');
                    },3500)
            })
        }

        if(alertModalCercospora === 1) {
            await post_cercospora(cercosporaStore)
                .then(resp => {
                    setSuccess(resp.data.success);
                    setTimeout(() => {
                        setSuccess('');
                        window.location.href = props.to;
                    },3500)
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setTimeout(() => {
                        setError('');
                    },3500)
                })
        }
    }

    return (
        <React.Fragment>
            {modal && (
                    <Modal 
                        show={modal}
                        title={'Deseja sair da avaliação?'}
                        textButton={assentmentModule == 1 ? 'Salvar' : 'Continuar na página'}
                        cancel={assentmentModule == 1 ? (e) => handleSubmit(e) : () => setModal(false)} //BOTÃO SALVAR
                        onHide={() => setModal(false)}
                        del={(e) => handleCloseModal(e)}

                        success={success ? success : ''}
                        error={error ? error : ''}
                        
                        content={
                            <p style={{fontSize: '18px'}}>
                                Se você ainda não salvou a avalição, ao sair sem salvar irá perder todo o cadastro feito.
                            </p>
                        }
                    />
            )}
            <li className="nav-item" onClick={e => handleClick(e)}>
            {/* <li className="nav-item"> */}
                <a href={props.to} className="nav-link">
                    <i style={{marginLeft: '10px'}}><img width="20px" src={props.icon} /></i>
                    <p style={{fontSize: '12px', marginLeft: '5px'}}>
                        
                        {props.item}
                        
                    </p>
                </a>
            </li>
        </React.Fragment>
    )
}

export default NavItem