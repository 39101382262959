import React, {useState, useEffect} from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {useDispatch, useSelector} from 'react-redux'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ReactLoading from 'react-loading'
import {Content} from '../../Auth/styleLoading'
import {post_cercospora, get_area_cercosporas, get_cercospora} from '../../../Services/cercospora'
import {useHistory} from 'react-router'
import NumberFormat from 'react-number-format'
import {Select, MenuItem, SelectChangeEvent, Input} from '@material-ui/core'
import {useRef} from 'react'
import {Link} from "react-router-dom";


function GridCercospora(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState(false)
    const [requiredPonto1, setRequiredPonto1] = useState(false)
    const [requiredPonto2, setRequiredPonto2] = useState(false)
    const [requiredPonto3, setRequiredPonto3] = useState(false)
    const [requiredPonto4, setRequiredPonto4] = useState(false)
    const [requiredPonto5, setRequiredPonto5] = useState(false)
    const typeArea = props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2
    const areas = useSelector(state => state.cercospora.areas)
    const id_role = useSelector(state => state.auth.user.id_role);

    const [ponto1, setPonto1] = useState([
        {ramo: 0, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
    ])
    const [ponto2, setPonto2] = useState([
        {ramo: 0, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
    ])
    const [ponto3, setPonto3] = useState([
        {ramo: 0, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
    ])
    const [ponto4, setPonto4] = useState([
        {ramo: 0, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
    ])
    const [ponto5, setPonto5] = useState([
        {ramo: 0, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, folhaCercospora: null, folhaNo3: null, folhaNo4: null, avaliacao: 0},
    ])

    const selectAvaliationRef = useRef(null)

    const [avaliation, setAvaliation] = useState('')

    const optionsAvaliation = [
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'}
    ]

    const [dataCercospora, setDataCercospora] = useState("");

    window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
    window.addEventListener('popstate', (event) => {
        if (event.state) {
            return ''
        }
    }, false);

    const handleChange = (event) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        setAvaliation(event.target.value);
        getCercosporas(event.target.value)
    };


    useEffect(() => {
        if (areas.length > 0) {
            if (id_role === 1) {
                setAvaliation(() => parseInt(props.match.params.avaliacao));
                getCercosporas(props.match.params.avaliacao);
                return () => {
                    getCercosporas(props.match.params.avaliacao)
                }
            } else {
                getCercosporas(1);
                return () => {
                    getCercosporas(1)
                }
            }
        }
    }, [areas]);

    useEffect(() => {
        getAreas();
        return () => {
            getAreas()
        }
    }, []);

    useEffect(() => {
        let required = avaliation || ponto1[0].avaliation
        setAvaliation(required)
    }, [ponto1]);


    useEffect(() => {
        let required = dataCercospora || ponto1[0].dataCercospora
        setDataCercospora(required)
    }, [ponto1]);

    useEffect(() => {
        let required = ponto1.map(value => value.folhaTotal != null)
        setRequiredPonto1(required.find(value => value == true ? true : false))
    }, [ponto1]);

    useEffect(() => {
        let required = ponto2.map(value => value.folhaTotal != null)
        setRequiredPonto2(required.find(value => value == true ? true : false))
    }, [ponto2]);

    useEffect(() => {
        let required = ponto3.map(value => value.folhaTotal != null)
        setRequiredPonto3(required.find(value => value == true ? true : false))
    }, [ponto3]);

    useEffect(() => {
        let required = ponto4.map(value => value.folhaTotal != null)
        setRequiredPonto4(required.find(value => value == true ? true : false))
    }, [ponto4]);

    useEffect(() => {
        let required = ponto5.map(value => value.folhaTotal != null)
        setRequiredPonto5(required.find(value => value == true ? true : false))
    }, [ponto5]);

    // Adiciona cercospora a store do redux
    useEffect(() => {
        if (ponto1.length > 0 && ponto2.length > 0 && ponto3.length > 0 && ponto4.length > 0 && ponto5.length > 0) {
            let data = {
                ponto1,
                ponto2,
                ponto3,
                ponto4,
                ponto5,
                area_id: areas[0]?.id,
                typeArea: typeArea,
                avaliation: avaliation,
                dataCercospora: dataCercospora
            }

            dispatch({type: 'cercosporas', payload: data})
        }
    }, [ponto1, ponto2, ponto3, ponto4, ponto5, dataCercospora, avaliation]);

    useEffect(() => {
        if (id_role === 2) {
            dispatch({type: 'cercosporas', payload: []})
        }
    }, [typeArea]);

    const getAreas = () => {
        setLoading(true)
        get_area_cercosporas(id_role === 1 ? props.match.params.consultantId : null)
            .then((resp) => {
                if (resp.data.length <= 0 && id_role === 2) {
                    dispatch({type: 'set_alert_modal_phoma', payload: 0})
                    dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                    setAlert(`Você ainda não tem um talhão cadastrado. É necessário o cadastro primeiro.`)
                    setLoading(false)
                    setTimeout(() => {
                        setAlert(false)
                        history.push(typeArea === 1 ? '/talhoes/manejo_syngenta' : '/talhoes/manejo_produtor')
                    }, 4000)
                } else {
                    setLoading(false)
                }
                console.log(resp.data.length <= 0)
                return resp
            })
            .then(resp => {
                dispatch({type: 'get_areas_cercospora', payload: resp.data})
            })
            .catch(err => {
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    const getCercosporas = (avaliationId) => {
        get_cercospora(areas[0].id, typeArea, avaliationId)
            .then(resp => {
                if (resp.data.cercospora1.length === 4 &&
                    resp.data.cercospora2.length === 4 &&
                    resp.data.cercospora3.length === 4 &&
                    resp.data.cercospora4.length === 4 &&
                    resp.data.cercospora5.length === 4
                ) {
                    setPonto1(resp.data.cercospora1)
                    setPonto2(resp.data.cercospora2)
                    setPonto3(resp.data.cercospora3)
                    setPonto4(resp.data.cercospora4)
                    setPonto5(resp.data.cercospora5)
                    setDataCercospora(resp.data.cercospora1[0].dataCercospora)

                    let data = {
                        ponto1: resp.data.cercospora1,
                        ponto2: resp.data.cercospora2,
                        ponto3: resp.data.cercospora3,
                        ponto4: resp.data.cercospora4,
                        ponto5: resp.data.cercospora5,
                        area_id: areas[0].id,
                        typeArea: typeArea,
                        avaliation: resp.data.cercospora1[0].avaliation,
                        dataCercospora: resp.data.cercospora1[0].dataCercospora
                    }

                    dispatch({type: 'cercosporas', payload: data})
                } else {
                    setPonto1([
                        {
                            ramo: 0,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 1,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 2,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 3,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                    ])
                    setPonto2([
                        {
                            ramo: 0,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 1,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 2,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 3,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                    ])
                    setPonto3([
                        {
                            ramo: 0,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 1,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 2,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 3,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                    ])
                    setPonto4([
                        {
                            ramo: 0,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 1,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 2,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 3,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                    ])
                    setPonto5([
                        {
                            ramo: 0,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 1,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 2,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                        {
                            ramo: 3,
                            folhaTotal: null,
                            folhaCercospora: null,
                            folhaNo3: null,
                            folhaNo4: null,
                            avaliacao: 0
                        },
                    ])
                }
            })
            .catch(err => {
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    const handleChangeFirstPoint = (e) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        switch (e.target.name) {
            case 'folha':
                let itens = ponto1.map(elem => elem);
                let valor = e.target.value
                let ramo = e.target.id
                itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
                setPonto1(itens)
                break;
            case 'cercospora':
                let item = ponto1.map(elem => elem);
                let valorCercospora = e.target.value;
                let ramoCercospora = e.target.id;
                item[ramoCercospora].folhaCercospora = parseInt(valorCercospora);
                setPonto1(item)
                break;
            case 'folhaNo3':
                let itemFolhaNo3 = ponto1.map(elem => elem);
                let valorFolhaNo3 = e.target.value;
                let ramoFolhaNo3 = e.target.id
                itemFolhaNo3[ramoFolhaNo3].folhaNo3 = parseInt(valorFolhaNo3);
                setPonto1(itemFolhaNo3)
                break;
            case 'folhaNo4':
                let itemFolhaNo4 = ponto1.map(elem => elem);
                let valorFolhaNo4 = e.target.value;
                let ramoFolhaNo4 = e.target.id
                itemFolhaNo4[ramoFolhaNo4].folhaNo4 = parseInt(valorFolhaNo4);
                setPonto1(itemFolhaNo4)
                break;
            case 'presenca1':
                let checkPresenca = ponto1.map(elem => elem);
                checkPresenca.map(elem => elem.avaliacao = +e.target.value)
                setPonto1(checkPresenca);
                break;
            default:
                break;
        }
    }

    const handleChangeSecondPoint = (e) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        switch (e.target.name) {
            case 'folha':
                let itens = ponto2.map(elem => elem);
                let valor = e.target.value
                let ramo = e.target.id
                itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
                setPonto2(itens)
                break;
            case 'cercospora':
                let item = ponto2.map(elem => elem);
                let valorCercospora = e.target.value
                let ramoCercospora = e.target.id
                item[ramoCercospora].folhaCercospora = parseInt(valorCercospora);
                setPonto2(item)
                break;
            case 'folhaNo3':
                let itemFolhaNo3 = ponto2.map(elem => elem);
                let valorFolhaNo3 = e.target.value
                let ramoFolhaNo3 = e.target.id
                itemFolhaNo3[ramoFolhaNo3].folhaNo3 = parseInt(valorFolhaNo3);
                setPonto2(itemFolhaNo3)
                break;
            case 'folhaNo4':
                let itemFolhaNo4 = ponto2.map(elem => elem);
                let valorFolhaNo4 = e.target.value;
                let ramoFolhaNo4 = e.target.id
                itemFolhaNo4[ramoFolhaNo4].folhaNo4 = parseInt(valorFolhaNo4);
                setPonto2(itemFolhaNo4)
                break;
            case 'presenca2':
                let checkPresenca = ponto2.map(elem => elem);
                checkPresenca.map(elem => elem.avaliacao = +e.target.value)
                setPonto2(checkPresenca);
                break;
            default:
                break;
        }
    }

    const handleChangeThirdPoint = (e) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        switch (e.target.name) {
            case 'folha':
                let itens = ponto3.map(elem => elem);
                let valor = e.target.value
                let ramo = e.target.id
                itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
                setPonto3(itens)
                break;
            case 'cercospora':
                let item = ponto3.map(elem => elem);
                let valorCercospora = e.target.value
                let ramoCercospora = e.target.id
                item[ramoCercospora].folhaCercospora = parseInt(valorCercospora);
                setPonto3(item)
                break;
            case 'folhaNo3':
                let itemFolhaNo3 = ponto3.map(elem => elem);
                let valorFolhaNo3 = e.target.value
                let ramoFolhaNo3 = e.target.id
                itemFolhaNo3[ramoFolhaNo3].folhaNo3 = parseInt(valorFolhaNo3);
                setPonto3(itemFolhaNo3)
                break;
            case 'folhaNo4':
                let itemFolhaNo4 = ponto3.map(elem => elem);
                let valorFolhaNo4 = e.target.value;
                let ramoFolhaNo4 = e.target.id
                itemFolhaNo4[ramoFolhaNo4].folhaNo4 = parseInt(valorFolhaNo4);
                setPonto3(itemFolhaNo4)
                break;
            case 'presenca3':
                let checkPresenca = ponto3.map(elem => elem);
                checkPresenca.map(elem => elem.avaliacao = +e.target.value)
                setPonto3(checkPresenca);
                break;
            default:
                break;
        }
    }

    const handleChangeFourthPoint = (e) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        switch (e.target.name) {
            case 'folha':
                let itens = ponto4.map(elem => elem);
                let valor = e.target.value
                let ramo = e.target.id
                itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
                setPonto4(itens)
                break;
            case 'cercospora':
                let item = ponto4.map(elem => elem);
                let valorCercospora = e.target.value
                let ramoCercospora = e.target.id
                item[ramoCercospora].folhaCercospora = parseInt(valorCercospora);
                setPonto4(item)
                break;
            case 'folhaNo3':
                let itemFolhaNo3 = ponto4.map(elem => elem);
                let valorFolhaNo3 = e.target.value
                let ramoFolhaNo3 = e.target.id
                itemFolhaNo3[ramoFolhaNo3].folhaNo3 = parseInt(valorFolhaNo3);
                setPonto4(itemFolhaNo3)
                break;
            case 'folhaNo4':
                let itemFolhaNo4 = ponto4.map(elem => elem);
                let valorFolhaNo4 = e.target.value;
                let ramoFolhaNo4 = e.target.id
                itemFolhaNo4[ramoFolhaNo4].folhaNo4 = parseInt(valorFolhaNo4);
                setPonto4(itemFolhaNo4)
                break;
            case 'presenca4':
                let checkPresenca = ponto4.map(elem => elem);
                checkPresenca.map(elem => elem.avaliacao = +e.target.value)
                setPonto4(checkPresenca);
                break;

            default:
                break;
        }
    }

    const handleChangeFifthPoint = (e) => {
        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
        switch (e.target.name) {
            case 'folha':
                let itens = ponto5.map(elem => elem);
                let valor = e.target.value
                let ramo = e.target.id
                itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
                setPonto5(itens)
                break;
            case 'cercospora':
                let item = ponto5.map(elem => elem);
                let valorCercospora = e.target.value
                let ramoCercospora = e.target.id
                item[ramoCercospora].folhaCercospora = parseInt(valorCercospora);
                setPonto5(item)
                break;
            case 'folhaNo3':
                let itemFolhaNo3 = ponto5.map(elem => elem);
                let valorFolhaNo3 = e.target.value
                let ramoFolhaNo3 = e.target.id
                itemFolhaNo3[ramoFolhaNo3].folhaNo3 = parseInt(valorFolhaNo3);
                setPonto5(itemFolhaNo3)
                break;
            case 'folhaNo4':
                let itemFolhaNo4 = ponto5.map(elem => elem);
                let valorFolhaNo4 = e.target.value;
                let ramoFolhaNo4 = e.target.id
                itemFolhaNo4[ramoFolhaNo4].folhaNo4 = parseInt(valorFolhaNo4);
                setPonto5(itemFolhaNo4)
                break;
            case 'presenca5':
                let checkPresenca = ponto5.map(elem => elem);
                checkPresenca.map(elem => elem.avaliacao = +e.target.value)
                setPonto5(checkPresenca);
                break;

            default:
                break;
        }
    }

    function firstPoint() {
        return ponto1.map(elem => (
            elem.ramo >= 0 ?
                <>
                    <Tbody>
                        <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 1}</td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.folhaTotal}
                                id={elem.ramo}
                                name={'folha'}
                                onChange={handleChangeFirstPoint}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    textAlign: 'center',
                                    width: '95%',
                                    minWidth: '50px',
                                    marginLeft: '3px',
                                    marginTop: '3px',
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                required={requiredPonto1}
                                disabled={id_role === 1}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.folhaCercospora}
                                id={elem.ramo}
                                name={'cercospora'}
                                onChange={handleChangeFirstPoint}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                        {avaliation === 3 || avaliation === 4 ?
                            <>
                                <td style={{textAlign: 'center'}}>
                                    <NumberFormat
                                        value={elem.folhaNo3}
                                        id={elem.ramo}
                                        name={'folhaNo3'}
                                        onChange={handleChangeFirstPoint}
                                        type='text'
                                        autoComplete="off"
                                        onFocus={(e) => e.target.select()}
                                        className="form-control"
                                        fixedDecimalScale={true}
                                        decimalScale={0}
                                        style={{
                                            border: 'none',
                                            outline: 'none',
                                            width: '95%',
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            marginLeft: '3px',
                                            marginTop: '3px'
                                        }}
                                        allowNegative={false}
                                        isAllowed={(values) => {
                                            const {floatValue, formattedValue} = values;
                                            return formattedValue === "" || floatValue <= 99999999;
                                        }}
                                        disabled={id_role === 1}
                                    />
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <NumberFormat
                                        value={elem.folhaNo4}
                                        id={elem.ramo}
                                        name={'folhaNo4'}
                                        onChange={handleChangeFirstPoint}
                                        type='text'
                                        autoComplete="off"
                                        onFocus={(e) => e.target.select()}
                                        className="form-control"
                                        fixedDecimalScale={true}
                                        decimalScale={0}
                                        style={{
                                            border: 'none',
                                            outline: 'none',
                                            width: '95%',
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            marginLeft: '3px',
                                            marginTop: '3px'
                                        }}
                                        allowNegative={false}
                                        isAllowed={(values) => {
                                            const {floatValue, formattedValue} = values;
                                            return formattedValue === "" || floatValue <= 99999999;
                                        }}
                                        disabled={id_role === 1}
                                    />
                                </td>
                            </>
                            : null}

                    </Tbody>
                </> : null
        ))
    }

    function secondPoint() {
        return ponto2.map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 5}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={handleChangeSecondPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto2}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaCercospora}
                            id={elem.ramo}
                            name={'cercospora'}
                            onChange={handleChangeSecondPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    {avaliation === 3 || avaliation === 4 ?
                        <>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo3}
                                    id={elem.ramo}
                                    name={'folhaNo3'}
                                    onChange={handleChangeSecondPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo4}
                                    id={elem.ramo}
                                    name={'folhaNo4'}
                                    onChange={handleChangeSecondPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                        </>
                        : null}

                </Tbody>
            </>
        ))
    }

    function thirdPoint() {
        return ponto3.map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 9}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={handleChangeThirdPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto3}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaCercospora}
                            id={elem.ramo}
                            name={'cercospora'}
                            onChange={handleChangeThirdPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    {avaliation === 3 || avaliation === 4 ?
                        <>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo3}
                                    id={elem.ramo}
                                    name={'folhaNo3'}
                                    onChange={handleChangeThirdPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo4}
                                    id={elem.ramo}
                                    name={'folhaNo4'}
                                    onChange={handleChangeThirdPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                        </>
                        : null}

                </Tbody>
            </>
        ))
    }

    function fourthPoint() {
        return ponto4.map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 13}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            type='text'
                            id={elem.ramo}
                            name={'folha'}
                            onChange={handleChangeFourthPoint}
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto4}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaCercospora}
                            id={elem.ramo}
                            name={'cercospora'}
                            onChange={handleChangeFourthPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    {avaliation === 3 || avaliation === 4 ?
                        <>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo3}
                                    id={elem.ramo}
                                    name={'folhaNo3'}
                                    onChange={handleChangeFourthPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo4}
                                    id={elem.ramo}
                                    name={'folhaNo4'}
                                    onChange={handleChangeFourthPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                        </>
                        : null}

                </Tbody>
            </>
        ))
    }

    function fifthPoint() {
        return ponto5.map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 17}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            type='text'
                            id={elem.ramo}
                            name={'folha'}
                            onChange={handleChangeFifthPoint}
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto5}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaCercospora}
                            id={elem.ramo}
                            name={'cercospora'}
                            onChange={handleChangeFifthPoint}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    {avaliation === 3 || avaliation === 4 ?
                        <>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo3}
                                    id={elem.ramo}
                                    name={'folhaNo3'}
                                    onChange={handleChangeFifthPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                            <td style={{textAlign: 'center'}}>
                                <NumberFormat
                                    value={elem.folhaNo4}
                                    id={elem.ramo}
                                    name={'folhaNo4'}
                                    onChange={handleChangeFifthPoint}
                                    type='text'
                                    autoComplete="off"
                                    onFocus={(e) => e.target.select()}
                                    className="form-control"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '95%',
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        marginLeft: '3px',
                                        marginTop: '3px'
                                    }}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, formattedValue} = values;
                                        return formattedValue === "" || floatValue <= 99999999;
                                    }}
                                    disabled={id_role === 1}
                                />
                            </td>
                        </>
                        : null}

                </Tbody>
            </>
        ))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            ponto1,
            ponto2,
            ponto3,
            ponto4,
            ponto5,
            area_id: areas[0].id,
            typeArea: typeArea,
            avaliation: avaliation,
            dataCercospora: dataCercospora
        }

        post_cercospora(data)
            .then(resp => {
                setLoading(false)
                setSuccess(resp.data.success);
                dispatch({type: 'set_alert_modal_phoma', payload: 0})
                dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                setTimeout(() => {
                    setSuccess('');
                    getCercosporas(avaliation)
                }, 3500)
            })
            .catch(err => {
                setLoading(false)
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    return (
        <div className="wrapper">
            <Header/>
            <Sidebar/>
            <StylePage
                title={typeArea === 1 ? 'Avaliação Cercospora Manejo Syngenta' : 'Avaliação Cercospora Manejo Produtor'}>

                {alert ? (
                    <div>
                        <Alert type="danger" hidden={true}>{alert}</Alert>
                    </div>
                ) : ("")}
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100}/>
                    </Content>

                ) : ('')}
                {success ? (
                    <div>
                        <Alert type="success" hidden={true}>{success}</Alert>
                    </div>
                ) : ("")}
                {error ? (
                    <div>
                        <Alert type="danger" hidden={true}>{error}</Alert>
                    </div>
                ) : ("")}
                <div style={{display: loading === true || alert !== false ? ('none') : ('block')}}>
                    <form onSubmit={handleSubmit} autocomplete="off">
                        <div className="row" style={{marginBottom: '3rem'}}>
                            <div className="col-md-2">
                                <label style={{margin: '1rem'}}>Avaliação</label>
                                <Select
                                    style={{marginLeft: '1rem', textAlign: 'center'}}
                                    fullWidth
                                    name="avaliation"
                                    required={true}
                                    value={avaliation}
                                    ref={selectAvaliationRef}
                                    label="Avaliação"
                                    options={optionsAvaliation}
                                    onChange={handleChange}
                                    disabled={id_role === 1}

                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>

                                {!avaliation &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={avaliation}
                                        onFocus={() => selectAvaliationRef.current.focus()}
                                        required={true}
                                    />
                                }
                            </div>
                            <div className="col-md-3">
                                <label style={{marginLeft: '4rem', marginTop: '1rem', display: 'flex'}}>Data</label>
                                <Input
                                    style={{marginLeft: '4rem', marginTop: '0.5rem'}}
                                    icon="fas fa-calendar"
                                    label="Data Admissão"
                                    name="dataCercospora"
                                    required={true}
                                    type="date"
                                    placeholder=""
                                    //disabled={props.match.params.action === "view" ? true : false}
                                    onChange={(e) => {
                                        dispatch({type: 'set_alert_modal_cercospora', payload: 1})
                                        setDataCercospora(e.target.value);
                                    }}
                                    value={dataCercospora}
                                    disabled={id_role === 1}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Table
                                    cardHeader={'noHeader'}
                                    component={
                                        <div className='row justify-content-center'>
                                            <p style={{fontWeight: 'bold', fontSize: 16}}>Ponto 1</p>
                                        </div>
                                    }
                                    cabecalho={
                                        <Cabecalho cabecalho={true}>
                                        </Cabecalho>
                                    }>
                                    <Thead>
                                        <th>Ramo</th>
                                        <th style={{background: '#009700'}}>Folhas Total</th>
                                        <th style={{background: '#ff3f3f'}}>Folhas com Cercospora</th>
                                        {avaliation === 3 || avaliation === 4 ?
                                            <>
                                                <th style={{background: '#74d175'}}>Frutos no 3 nó</th>
                                                <th style={{background: '#74d175'}}>Frutos no 4 nó</th>
                                            </>
                                            : null}
                                    </Thead>

                                    {firstPoint()}
                                    <Tfoot
                                        estiloBackground='#f49e00 '
                                        component={
                                            <>
                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                <td colSpan={avaliation === 3 || avaliation === 4 ? 4 : 2}>
                                                    <div className='row justify-content-around'>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='presencaPonto1'
                                                                value='1'
                                                                name='presenca1'
                                                                onChange={handleChangeFirstPoint}
                                                                checked={ponto1.map(value => value.avaliacao ? true : false)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='presencaPonto1'>Presença</label>

                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='ausenciaPonto1'
                                                                value='0'
                                                                name='presenca1'
                                                                onChange={handleChangeFirstPoint}
                                                                checked={ponto1.map(value => value.avaliacao ? false : true)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='ausenciaPonto1'>Ausência</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }>
                                    </Tfoot>
                                </Table>
                            </div>

                            {/* Segundo Ponto */}
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Table
                                    cardHeader={'noHeader'}
                                    component={
                                        <div className='row justify-content-center'>
                                            <p style={{fontWeight: 'bold', fontSize: 16}}>Ponto 2</p>
                                        </div>
                                    }
                                    cabecalho={
                                        <Cabecalho cabecalho={true}>
                                        </Cabecalho>
                                    }>
                                    <Thead>
                                        <th>Ramo</th>
                                        <th style={{background: '#009700'}}>Folhas Total</th>
                                        <th style={{background: '#ff3f3f'}}>Folhas com Cercospora</th>
                                        {avaliation === 3 || avaliation === 4 ?
                                            <>
                                                <th style={{background: '#74d175'}}>Frutos no 3 nó</th>
                                                <th style={{background: '#74d175'}}>Frutos no 4 nó</th>
                                            </>
                                            : null}
                                    </Thead>

                                    {secondPoint()}

                                    <Tfoot
                                        estiloBackground='#f49e00 '
                                        component={
                                            <>
                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                <td colSpan={avaliation === 3 || avaliation === 4 ? 4 : 2}>
                                                    <div className='row justify-content-around'>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='presencaPonto2'
                                                                value="1"
                                                                name='presenca2'
                                                                checked={ponto2.map(value => value.avaliacao ? true : false)[0]}
                                                                onChange={handleChangeSecondPoint}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='presencaPonto2'>Presença</label>

                                                        </div>
                                                        <div>
                                                            <input type='radio'
                                                                   id='ausenciaPonto2'
                                                                   value="0"
                                                                   name='presenca2'
                                                                   onChange={handleChangeSecondPoint}
                                                                   checked={ponto2.map(value => value.avaliacao ? false : true)[0]}
                                                                   disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='ausenciaPonto2'>Ausência</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }>
                                    </Tfoot>
                                </Table>
                            </div>

                            {/* Terceito Ponto */}
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Table
                                    cardHeader={'noHeader'}
                                    component={
                                        <div className='row justify-content-center'>
                                            <p style={{fontWeight: 'bold', fontSize: 16}}>Ponto 3</p>
                                        </div>
                                    }
                                    cabecalho={
                                        <Cabecalho cabecalho={true}>
                                        </Cabecalho>
                                    }>
                                    <Thead>
                                        <th>Ramo</th>
                                        <th style={{background: '#009700'}}>Folhas Total</th>
                                        <th style={{background: '#ff3f3f'}}>Folhas com Cercospora</th>
                                        {avaliation === 3 || avaliation === 4 ?
                                            <>
                                                <th style={{background: '#74d175'}}>Frutos no 3 nó</th>
                                                <th style={{background: '#74d175'}}>Frutos no 4 nó</th>
                                            </>
                                            : null}
                                    </Thead>

                                    {thirdPoint()}

                                    <Tfoot
                                        estiloBackground='#f49e00 '
                                        component={
                                            <>
                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                <td colSpan={avaliation === 3 || avaliation === 4 ? 4 : 2}>
                                                    <div className='row justify-content-around'>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='presencaPonto3'
                                                                value="1"
                                                                name='presenca3'
                                                                onChange={handleChangeThirdPoint}
                                                                checked={ponto3.map(value => value.avaliacao ? true : false)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='presencaPonto3'>Presença</label>

                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='ausenciaPonto3'
                                                                value="0"
                                                                name='presenca3'
                                                                onChange={handleChangeThirdPoint}
                                                                checked={ponto3.map(value => value.avaliacao ? false : true)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='ausenciaPonto3'>Ausência</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }>
                                    </Tfoot>
                                </Table>
                            </div>

                            {/* Quarto Ponto */}
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Table
                                    cardHeader={'noHeader'}
                                    component={
                                        <div className='row justify-content-center'>
                                            <p style={{fontWeight: 'bold', fontSize: 16}}>Ponto 4</p>
                                        </div>
                                    }
                                    cabecalho={
                                        <Cabecalho cabecalho={true}>
                                        </Cabecalho>
                                    }>
                                    <Thead>
                                        <th>Ramo</th>
                                        <th style={{background: '#009700'}}>Folhas Total</th>
                                        <th style={{background: '#ff3f3f'}}>Folhas com Cercospora</th>
                                        {avaliation === 3 || avaliation === 4 ?
                                            <>
                                                <th style={{background: '#74d175'}}>Frutos no 3 nó</th>
                                                <th style={{background: '#74d175'}}>Frutos no 4 nó</th>
                                            </>
                                            : null}
                                    </Thead>

                                    {fourthPoint()}

                                    <Tfoot
                                        estiloBackground='#f49e00 '
                                        component={
                                            <>
                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                <td colSpan={avaliation === 3 || avaliation === 4 ? 4 : 2}>
                                                    <div className='row justify-content-around'>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='presencaPonto4'
                                                                value="1"
                                                                name='presenca4'
                                                                onChange={handleChangeFourthPoint}
                                                                checked={ponto4.map(value => value.avaliacao ? true : false)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='presencaPonto4'>Presença</label>

                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='ausenciaPonto4'
                                                                value="0"
                                                                name='presenca4'
                                                                onChange={handleChangeFourthPoint}
                                                                checked={ponto4.map(value => value.avaliacao ? false : true)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='ausenciaPonto4'>Ausência</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }>
                                    </Tfoot>
                                </Table>
                            </div>

                            {/* Quinto Ponto */}
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Table
                                    cardHeader={'noHeader'}
                                    component={
                                        <div className='row justify-content-center'>
                                            <p style={{fontWeight: 'bold', fontSize: 16}}>Ponto 5</p>
                                        </div>
                                    }
                                    cabecalho={
                                        <Cabecalho cabecalho={true}>
                                        </Cabecalho>
                                    }>
                                    <Thead>
                                        <th>Ramo</th>
                                        <th style={{background: '#009700'}}>Folhas Total</th>
                                        <th style={{background: '#ff3f3f'}}>Folhas com Cercospora</th>
                                        {avaliation === 3 || avaliation === 4 ?
                                            <>
                                                <th style={{background: '#74d175'}}>Frutos no 3 nó</th>
                                                <th style={{background: '#74d175'}}>Frutos no 4 nó</th>
                                            </>
                                            : null}
                                    </Thead>

                                    {fifthPoint()}

                                    <Tfoot
                                        estiloBackground='#f49e00 '
                                        component={
                                            <>
                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                <td colSpan={avaliation === 3 || avaliation === 4 ? 4 : 2}>
                                                    <div className='row justify-content-around'>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='presencaPonto5'
                                                                value="1"
                                                                name='presenca5'
                                                                onChange={handleChangeFifthPoint}
                                                                checked={ponto5.map(value => value.avaliacao ? true : false)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='presencaPonto5'>Presença</label>

                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='ausenciaPonto5'
                                                                value="0"
                                                                name='presenca5'
                                                                onChange={handleChangeFifthPoint}
                                                                checked={ponto5.map(value => value.avaliacao ? false : true)[0]}
                                                                disabled={id_role === 1}
                                                            />
                                                            <label className='ml-2'
                                                                   for='ausenciaPonto5'>Ausência</label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }>
                                    </Tfoot>
                                </Table>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 text-right mb-2 mr-3">
                                <div className="text-right mb-2 mr-3">
                                    {/* <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>{}}>Voltar</button>                            */}
                                    {id_role === 2 && <button type="submit" className="btn btn-primary">Salvar</button>}
                                    {id_role === 1 &&
                                        <Link to={`/cercosporas/${props.match.params.typeArea}`}
                                              className="btn btn-primary">Retornar</Link>}
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </StylePage>
            <Footer/>
        </div>
    )
}

export default (GridCercospora)
