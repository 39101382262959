export const HeaderCSV = [
    {label: 'Nome do Consultor',key:'name'}, 
    {label: 'CPF', key:'cpf'}, 
    {label: 'Email', key:'email'},
    {label: 'Telefone', key:'phone'},
    {label: 'Data de Nascimento', key:'birth_date'},
    {label: 'Cidade de Atuação', key:'acting_city'},
    {label: 'CEP', key:'zipcode'},
    {label: 'Rua', key:'street'},
    {label: 'Número', key:'number_home'},
    {label: 'Complemento', key:'complement'},
    {label: 'Bairro', key:'neighborhood'},
    {label: 'Estado', key:'state'},
    {label: 'Cidade', key:'city'},
]