const CercosporaReducer = (state = {}, actions)=>{
    switch(actions.type){
        //  Cercospora Admin
        case 'CercosporasList':
            return {...state, cercosporasList: actions.payload}

        case 'GetCercosporas_Desc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'GetCercosporas_Asc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'qtd_per_page_Cercosporas':
            return {...state, qtd_per_page: actions.payload}

        case 'pesquisaCercosporas':
            return {...state, pesquisa: actions.payload}

        case 'clearPesquisaCercosporas':
            return {...state, pesquisa: actions.payload}

        case 'changeTypeArea':
            return {...state, type_area: actions.payload}
        // Cercospora Consultor
        case 'get_areas_cercospora':
            return{
                ...state,
                areas:actions.payload
            }
        case 'cercosporas':
            return{
                ...state,
                cercosporas:actions.payload
            }
        case 'set_alert_modal_cercospora':
            return{
                ...state,
                alertModal: actions.payload
            }
        default:
            return state;
    }
}
export default CercosporaReducer;