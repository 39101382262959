export const HeaderCSVDetailed =  [
  {label: 'Nome do Consultor', key: 'nome_consultor'},
  {label: 'Praga', key: 'nome_doenca'},
  {label: 'Manejo', key: 'manejo'},
  {label: 'Nº da Avaliação', key: 'avaliacao'},
  {label: 'Data', key: 'data_phoma'},
  {label: 'Ponto', key: 'ponto_phoma'},
  {label: 'Tipo de Avaliacao', key: 'tipo_avaliacao'},
  {label: 'Ramo', key: 'phoma_ramo0'},
  {label: 'Ramo', key: 'phoma_ramo1'},
  {label: 'Ramo', key: 'phoma_ramo2'},
  {label: 'Ramo', key: 'phoma_ramo3'},
  {label: 'Ramo', key: 'phoma_ramo4'},
  {label: 'Ramo', key: 'phoma_ramo5'},
  {label: 'Ramo', key: 'phoma_ramo6'},
  {label: 'Ramo', key: 'phoma_ramo7'},
  {label: 'Ramo', key: 'phoma_ramo8'},
  {label: 'Ramo', key: 'phoma_ramo9'},
  {label: 'Presença/Ausencia', key: 'doenca_phoma'},
]