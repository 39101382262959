const TopicoReducer = (state = {}, actions)=>{
    switch(actions.type){
        case 'changeNomeTopico':
            return{...state, nome:actions.payload}

        case 'GetTopico_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetTopico_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_topico':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaTopico':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaTopico':
            return{...state, pesquisa:actions.payload}

        case 'get_topic':
            return{...state, topicos:actions.payload}

        case 'editViewTopico':
            return{...state,
                nome: actions.nome
            }

        case 'clearInputTopico':
            return{...state,
                nome: actions.nome
            }


        default:
            return state;
    }
}
export default TopicoReducer;