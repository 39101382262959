import React, { Component } from 'react'
import './style.css';

const onClickBack = (typeArea) => {
    window.location = `/bicho_mineiro/${typeArea}`;
}
export default class style_page extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        {this.props.enableBackButton === true ? (
                            <div className="row mb-2">
                                <div
                                    id='back-button'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#FFF',
                                        width: '125px',
                                        height: '40px',
                                        borderRadius: '4px',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        marginLeft: '0.5rem',
                                        padding: '10px 16px 10px 16px',
                                        background: '#64748B',
                                        fontSize: '18px',
                                        fontStyle: 'bold',
                                        fontWeight: 'bold 1rem', 
                                    }}
                                    onClick={(e) => {
                                        let type = this.props.typeArea == 1 ? 'manejo_syngenta' : 'manejo_produtor'
                                        window.location = this.props.pest === "broca" ? `/broca/${type}` : `/bicho_mineiro/${type}`
                                    }}
                                >
                                        VOLTAR
                                </div>
                            </div>
                        ) :
                         <></>
                        }
                        
                        <div className="row mb-2" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height:'30px'}}>
                            <div className="col-sm-6" style={{width: '50%', marginBottom: '10px'}}>
                                <h1>{this.props.title}</h1>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'start', marginBottom: '10px', width: '50%'}}>
                            {this.props.enableExportPDF === true ? (
                                <div className="row mb-2" style={{display:'flex'}}>
                                    <div
                                        id='csv-button'
                                    >
                                        Exportar PDF
                                    </div>

                                    <div
                                        id='csv-button'
                                        onClick={(e) => {
                                            onClickBack(this.props.typeArea)
                                        }}
                                    >
                                        Exportar CSV
                                    </div>
                                </div>
                            ) :
                            <></>
                            }
                        </div>
                        </div>
                        <div 
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            padding: '0',
                            display: 'flex',
                            justifyContent: 'left',
                            fontSize: '20px'
                        }}>
                            {this.props.userName ? this.props.userName : ''}
                        </div>
                    </div>{/* /.container-fluid */}
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h3 className="card-title">{this.props.subtitle}</h3>
                                    <div className="text-right">
                                        {this.props.button || ''}
                                    </div>
                                </div> */}
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </section>
               
            </div>

        )
    }
}
