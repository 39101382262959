import React, { Component } from 'react'
import { Wrapper, LogoStyle } from './styles'
import { rememberPassword } from '../../Services/auth'
import ReactLoading from 'react-loading'
import Alert from '../../Alerts/alert'
import Logo from '../../../Image/aliancaCafe.png';

export default class ForgotPassword extends Component {

    constructor(props){
        super(props)
        this.state={email:'',password:'',errors:{},sucesso:"", display:'true', return:'none'}
        
    }


    handleForm=(e)=>{
        e.preventDefault();
        this.setState({loading:true})
        const email = this.state.email
        const data = {
            email:email
        }
     

        rememberPassword(data)
        .then(res => this.setState({sucesso: res.data.message,loading:false, display: 'none', return: 'block', errors: ''}))
        .catch(e => this.setState({errors: e.response.data,loading:false}))
    }

  
    handleEmail = (e) =>{
        e.preventDefault();
        const email = e.target.value
        this.setState({email:email})
        
    }
    
    render() {        
        const error = this.state.errors
        const sucesso = this.state.sucesso
        if(this.state.loading===true){
            return (
                <React.Fragment>
                    <div style={{position:'relative',left:'50%',top:'50%',marginLeft:'-90px',marginTop:'100px'}}>
                        <ReactLoading type="spokes" color="#004A34" height={180} width={180} />
                    </div>
                    <span style={{position:'relative',left:'50%',top:'50%',color: '#004A34',fontSize:'36px',marginLeft:'-90px'}}>Enviando...</span>
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    <Wrapper>
                        <div className ="card">
                            <div className="login-logo">
                                <LogoStyle>
                                    <img src={Logo} alt="Logo Aliança Café" width="85%" height="90px" className="d-block" />
                                </LogoStyle>
                            </div>
                            <div className="card-body login-card-body">
                                {error.error ?( <Alert type="danger">{error.error}</Alert>):("")}
                                {sucesso ?( <Alert type="success">{sucesso}</Alert>):("")}
                                <div style={{display: `${this.state.display}`}}>
                                    <p class="login-box-msg">Voçê esqueceu sua senha? Digite seu e-mail abaixo para redefinir sua senha.</p>

                                </div>
                                <form onSubmit={this.handleForm}>
                                    <div className="input-group mb-3" style={{display: `${this.state.display}`}}>
                                        <input type="email" class="form-control" placeholder="Digite seu E-mail" onChange={this.handleEmail}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">   
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <button type="submit" class="btn btn-primary btn-block" style={{display: `${this.state.display}`}} id="btnLogar">Solicitar Nova Senha</button>
                                        </div>
                                    </div>                          
                                </form>
                            </div>
                        </div>
                    </Wrapper>
                    {/* /.login-box */}
                </React.Fragment>
            )
        }
    }
}
