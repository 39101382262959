import { get_topic } from '../../../../Services/topico'

/**
 * Função que pega o nome digitado
 * @param {*} event 
 */
export const changeNome = event => {
    return[{
        type:'changeNomeTopico',
        payload:event.target.value
    }]
}


/**
 * 
 * @param event 
 * Função que define a quantidade de itens à ser exibido por página
 */
export const qtdPerPage = event => {
    return [{
        type: 'qtdPerPageTopico',
        payload: event.target.value
    },
    // get(1)
    ]
}

/**
 * 
 * @param event 
 * Função que pega o valor digitado no filtro
 */
export const getChangeFiltro = event => {
    return [{
        type: 'changeFiltroTopico',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 * Função que limpa o filtro
 */
export const clearFiltro = event => {
    return [{
        type: 'ClearFiltroTopico',
        payload: ''
    },
    //  get(1)
    ]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().topico.order
        const th = getState().topico.th
        const qtd_per_page = getState().topico.qtd_per_page
        const pesquisa = getState().topico.pesquisa || ''       
      
        get_topic(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'get_topic', payload:resp.data}))

    }]
}


//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().topico.asc
      
        if(asc === true){
            dispatch({type:'GetTopico_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetTopico_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_topico',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaTopico',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaTopico',
        payload:''
    },get(1)]
}

export const editView = (data) => {
   return dispatch => {
       if(data){
           dispatch({

                type:'editViewTopico',
                nome: data.name         
           })

       }
   }
          
}

export const clearInput = event => {
    return {

        type:'clearInputTopico',
        nome:''
    } 

}

