const BichoMineiroReducer = (state = {}, actions)=>{
    switch(actions.type){
        //  BichoMineiro Admin
        case 'BichoMineiroList':
            return {...state, bichoMineiroList: actions.payload}
        case 'BichoMineiroAvaliation':
            return {...state, bichoMineiroAvaliation: actions.payload}

        case 'GetBichoMineiro_Desc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'GetBichoMineiro_Asc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'qtd_per_page_BichoMineiro':
            return {...state, qtd_per_page: actions.payload}

        case 'pesquisaBichoMineiro':
            return {...state, pesquisa: actions.payload}

        case 'clearPesquisaBichoMineiro':
            return {...state, pesquisa: actions.payload}

        case 'changeTypeArea':
            return {...state, type_area: actions.payload}

        case 'BichoMineiro_total':
            return {...state, total: actions.payload}

        case 'BichoMineiroList_to':
            return {...state, to: actions.payload}

        case 'BichoMineiroList_current_page':
            return {...state, current_page: actions.payload}

        case 'BichoMineiroList_from':
            return {...state, from: actions.payload}

        case 'BichoMineiroList_last_page':
            return {...state, last_page: actions.payload}

        // Cercospora Consultor
        case 'get_areas_cercospora':
            return{
                ...state,
                areas:actions.payload
            }
        case 'BichoMineiro':
            return{
                ...state,
                BichoMineiro:actions.payload
            }
        case 'set_alert_modal_cercospora':
            return{
                ...state,
                alertModal: actions.payload
            }
        default:
            return state;
    }
}
export default BichoMineiroReducer;