export const HeaderCSV = [
    {label:'Nome do Consultor',key:'name'}, 
    {label: 'Manejo', key:'applications.type_lavoura'}, 
    {label: 'Tipo de Aplicação', key:'method'}, 
    // {label: 'Safra', key:'harvest'},
    {label: 'Nº da Aplicação', key:'application'},
    {label: 'Data', key:'application_date'},
    {label: 'Calda Aplicada', key:'tail_volume'},
    {label: 'Nome Comercial', key:'applications.product_name'},
    {label: 'Classe', key:'applications.category_name'},
    {label: 'Empresa', key:'applications.enterprise_name'},
    {label: 'Unidade', key:'applications.unit_measurement'},
    {label: 'Dose da Receita', key:'applications.amount'},
    {label: 'Dose Aplicada', key:'applications.dose'},
    {label: 'Forma de Aplicação', key:'applications.type_name'},
   
]