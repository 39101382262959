import React, { useState, useEffect, useMemo, useRef } from "react";
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import Pagination from "../../../Table/pagination";
import ModelTable from "../CropProtection/ModelTable/ModelTable";
import StylePage from './style_page/Style_page'
import {useDispatch, useSelector} from 'react-redux'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ReactLoading from 'react-loading'
import {Content} from '../../Auth/styleLoading'
import {get_pagination, get_avaliacao} from '../../../Services/bichoMineiro'

import {get_module} from '../../../Services/moduloAvaliacao'
import {useHistory} from 'react-router'
import { getBichoMineiroAvaliacao, order, change_qtd_per_page, changePesquisa, clearPesquisa, getPagination } from './Actions/actions'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {pagination} from '../../../Pagination/paginations'
import NumberFormat from 'react-number-format'
import {Select, MenuItem, SelectChangeEvent, Input} from '@material-ui/core'
import {Link} from "react-router-dom";
import Spraying from "./Spraying";

function GridBichoMineiroAdmin (props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false) 
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState(false)

    const typeArea = props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2;
    const areas = useSelector(state => state.phoma.areas);
    const id_role = useSelector(state => state.auth.user.id_role);

    const [assentmentModule, setAssentmentModule] = useState("");
    const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');
    const pesquisa = useSelector(state => state.bicho_mineiro.pesquisa);

    const type = useSelector(state => state.bicho_mineiro.type_area);
    let qtd_per_page = useSelector(state =>state.bicho_mineiro.qtd_per_page);
    let page = useSelector(state => state.bicho_mineiro.page);
    const bicho_mineiro_list = useSelector(state => state.bicho_mineiro.bichoMineiroList);
    
    const to = useSelector(state => state.bicho_mineiro.to);
    const from = useSelector(state => state.bicho_mineiro.from);
    const last_page = useSelector(state => state.bicho_mineiro.last_page);
    const current_page = useSelector(state => state.bicho_mineiro.current_page);
    const total = useSelector(state => state.bicho_mineiro.total);

    

    const changeTypeArea = () => {
        dispatch({type: 'changeTypeArea', payload: props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2});
    }

  
    // GET Modulo avaliação

    
    useEffect(() => {
        changeTypeArea();
        return () => {
            changeTypeArea();
        }
    });
    useEffect(() => {
        getModuloAvaliacao(assentmentModule);
      }, []);

      useEffect(() => {
        getBichoMineiroAvaliacoes();
        return () => {
          getBichoMineiroAvaliacoes();
          
        };
      }, []);

    const getModuloAvaliacao = () => {
        get_module().then((resp) => {
            validAccess(resp.data.pests_id);
            setAssentmentModule(resp.data.pests_id);
        });
    };
    // const getPagination = (page, qtd_per_page, typeArea, pesquisa) => {
    //     get_pagination(page, qtd_per_page, typeArea, pesquisa).then(resp => {
    //         console.log(resp)
    //         setPerPage(resp.data.per_page);
    //         setTo(resp.data.to);
    //         setTotal(resp.data.total);
    //         setFrom(resp.data.from);
    //         setCurrentPage(resp.data.current_page);
    //         setLastPage(resp.data.last_page);
    //     }); 
    // }
    const getBichoMineiroAvaliacoes = (page) => {
        props.getBichoMineiroAvaliacao(page);
        props.getPagination(page, qtd_per_page, typeArea, pesquisa);
    };

    const getPaginantionVBichoMineiro = () => {
        props.getPagination();
    }

    const validAccess = (moduleAvaliacao) => {
        if(id_role != 1 || moduleAvaliacao != 2){
            window.location = '/index';
        }        
    };

    const validDate = (date) => {
        let pattern = /^\d{2}\/\d{2}\/\d{4}$/;
        return pattern.test(date);
    }

    const changePesquisa = (e) => {
        e.preventDefault();
        dispatch({type: 'pesquisaBichoMineiro', payload: e.target.value});
    }

    const clearPesquisa = () => {
        dispatch({type: 'pesquisaBichoMineiro', payload: ''});
        getBichoMineiroAvaliacoes(1);
    }

    const onChangeQtdPerPage = (event) => {
        dispatch({type: 'qtd_per_page_BichoMineiro', payload: event.target.value});
        getBichoMineiroAvaliacoes(1);
    }
  
    window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
    window.addEventListener('popstate', (event) => {
        if (event.state) {
            return ''
        } 
    }, false);
    

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage title={typeArea === 1 ? 'Avaliação Bicho-mineiro Manejo Syngenta' : 'Avaliação Bicho-mineiro Manejo Produtor'} 
            enableExportCSV={true}
            enableExportPDF={true}
            typeArea={typeArea}
            >
            
                {alert ? (
                    <div>
                        <Alert type="danger" hidden={true}>{alert}</Alert>
                    </div>
                ) : ("")}
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100}/>
                    </Content>

                ) : ('')}
                {success ? (
                    <div>
                        <Alert type="success" hidden={true}>{success}</Alert>
                    </div>
                ) : ("")}
                {error ? (
                    <div>
                        <Alert type="danger" hidden={true}>{error}</Alert>
                    </div>
                ) : ("")}

                <div style={{display: loading === true || alert !== false ? ('none') : ('block'), background: '#f8f9fa'}}>
                    <div style={{background: '#f8f9fa'}}>
                        <Table
                            cardHeader={'noHeader'}
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {from} à {to} do total de {total}
                                    </div>
                                </div>
                            }
                            cabecalho={<Cabecalho
                                action={() => getBichoMineiroAvaliacoes(1)} 
                                clear={clearPesquisa} 
                                valuePesquisa={pesquisa} 
                                value={qtd_per_page} 
                                onChange={e => onChangeQtdPerPage(e)} 
                                changePesquisa={e => changePesquisa(e)}

                            />}

                            pagination={<Pagination>{pagination(current_page,getBichoMineiroAvaliacoes, last_page)}</Pagination>}
                             title={""}
                        > 
                      
                            <Thead>
                                <th>Consultor</th>
                                <th>Avaliações</th>
                                <th>Data</th>
                                <th>Ações</th>
                            </Thead>
                                <Tbody>
                                    <Spraying typeArea={typeArea} id_role={id_role} spraying={bicho_mineiro_list}  />
                                </Tbody>

                            <Tfoot>
                                <th>Consultor</th>
                                <th>Avaliações</th>
                                <th>Data</th>
                                <th>Ações</th>
                            </Tfoot>
                        </Table>
                        
                    </div>
                
                </div>                
            </StylePage>
            <Footer/>
        </div>
    );
}

const mapStateToProps = state => ({
    current_page:state.bicho_mineiro.current_page,
    last_page:state.bicho_mineiro.last_page,
    from:state.bicho_mineiro.from,
    to:state.bicho_mineiro.to,
    qtd_total:state.bicho_mineiro.total,
    qtd_per_page:state.bicho_mineiro.qtd_per_page,
    pesquisa:state.bicho_mineiro.pesquisa || '',
    id_role:state.auth.user.id_role,
    page:state.bicho_mineiro.page,
    typeArea:state.bicho_mineiro.type_area  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        getBichoMineiroAvaliacao,
        getPagination,
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa, 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridBichoMineiroAdmin);