import {createStore, applyMiddleware} from "redux";
import AllReducers from './reducers/AllReducers'
import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'


//Função que pega a data atual como default ;

var data = new Date();
var dia = data.getDate();
var mes = data.getMonth();
var ano = data.getFullYear();

if (mes + 1 < 10) {
    var compMes = 0
} else {
    compMes = ''
}

if (dia < 10) {
    var compDia = 0;
}
if (dia >= 10) {
    compDia = '';
}
var str_date = ano + '-' + compMes + (mes + 1) + '-' + compDia + (dia)
var str_date_full = (ano - 16) + '-' + compMes + (mes + 1) + '-' + compDia + (dia)


const initialStates = {
    auth: {
        loggedIn: false,
        user: {},


    },
    consultor: {
        asc: true,
        desc: false,
        order: 'asc',
        th: 'consultant.name',
        qtd_per_page: 10,
        consultores: [],
        loadingCep: false
    },
    talhao: {
        asc: true,
        desc: false,
        order: 'asc',
        th: 'area.name',
        qtd_per_page: 10,
        talhoes: [],
        espacamento_linhas: 0,
        espacamento_plantas: 0,
        data_atual: str_date

    },
    pulverization: {
        spraying: [],
        asc: true,
        desc: false,
        order: 'asc',
        th: 'pulverization.created_at',
        qtd_per_page: 10,
        data_atual: str_date,
        alertModal: 0,
        alertWarning: 0
    },
    produtor: {
        asc: true,
        desc: false,
        order: 'asc',
        th: 'producer.name',
        qtd_per_page: 10,
        produtores: [],
        data_atual: str_date,
        date_max: str_date_full
    },
    topico: {
        asc: true,
        desc: false,
        order: 'asc',
        th: 'topic.name',
        qtd_per_page: 10,
        topicos: []
    },
    phoma: {
        areas: [],
        phomas: [],
        phomasList: [],
        asc: true,
        desc: false,
        order: 'asc',
        th: 'consultant.name',
        qtd_per_page: 10,
        data_atual: str_date,
        page: 1,
        alertModal: 0,
        alertWarning: 0
    },
    cercospora: {
        areas: [],
        cercosporas: [],
        cercosporasList: [],
        asc: true,
        desc: false,
        order: 'asc',
        th: 'consultant.name',
        qtd_per_page: 10,
        data_atual: str_date,
        page: 1,
        alertWarning: 0,
        alertModal: 0
    },
    bicho_mineiro: {
        pesquisa: '',
        areas: [],
        bichoMineiro: [],
        bichoMineiroList: [],
        asc: true,
        desc: false,
        order: 'asc',
        th: 'consultant.name',
        qtd_per_page: 10,
        alertWarning: 0,
        alertModal: 0,
        from: 1,
        total: 10,
        to: 1,
        current_page: 1,
        last_page: 1,
    },
    broca: {
        pesquisa: '',
        areas: [],
        broca: [],
        brocaList: [],
        asc: true,
        desc: false,
        order: 'asc',
        th: 'consultant.name',
        qtd_per_page: 10,
        alertWarning: 0,
        alertModal: 0,
        from: 1,
        total: 10,
        to: 1,
        current_page: 1,
        last_page: 1,
    },
    menssage: {
        menssage: ''
    },
    produtividade: {
        productivitys: [],
        asc: true,
        desc: false,
        order: 'asc',
        qtd_per_page: 10,
        th: 'productivity.id',
        data_atual: str_date
    },
    user: {
        nome: '',
        email: '',
        cpf: '',
        rg: '',
        perfil: 2,
        telefone: '',
        nome_mae: '',
        nome_pai: '',
        naturalidade: '',
        sexo: '',
        inscricao: '',
        cep: '',
        uf: '',
        cidade: '',
        logradouro: '',
        numero: 0,
        bairro: '',
        numero_cnh: '',
        numero_funcional: 0,
        vencimento_funcional: '',
        telefone: '',
        data_nascimento: ''
    },
}
const store = applyMiddleware(thunk, multi, promise)(createStore)(AllReducers, initialStates,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;