import React, { Component } from 'react'

export default class tfoot extends Component {
    render() {
        return (
            <tfoot>
                <tr style={{background: this.props.estiloBackground ? this.props.estiloBackground : ''}}>
                    {this.props.component ? this.props.component : this.props.children}
                </tr>
            </tfoot>
        )
    }
}
