import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'

const AuthRouter = ({component:Component, ...rest }) =>{   
    return (  
        <Route
            {...rest}
            
            render={props => {
                const token = localStorage.getItem('token')         
                if(token){
                    if(rest.password_confirmation === true && rest.consultant === true){
                        
                        return <Component {...props} />
                    }else{
                        return <Redirect
                            to={{
                                pathname: `/consultor/${rest.id_user}`,
                                state: { from: props.location }
                            }}
                        />         
                    }
                }else{
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />  

                }     
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
        loggedIn:state.auth.loggedIn, 
        password_confirmation:state.auth.password_confirmation,
        consultant:state.auth.consultant,
        id_user:state.auth.id_user    
    }
}
export default connect(mapStateToProps)(AuthRouter);