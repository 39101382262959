import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NumberFormat from 'react-number-format'
import Style from '../../../Style Page/style_page'
import Form from '../../../Forms/form'
import Input from '../../../Forms/input'
import Select from '../../../Forms/select'
import Sidebar from '../../../Sidebar/sidebar'
import Header from '../../../Header/header'
import Footer from '../../../Footer/footer'
import { useHistory } from 'react-router-dom'
import { post_consultant,get_edit,put_consultant } from '../../../Services/consultant'
import Alert from '../../../Alerts/alert'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import {clearInputs,editView,changeDataNascimento,changeUF,UFS,getCidades,changeCep,getToCep,changeCPF,changeTelefone,changeNome,changeEmail,changeBairro,changeNumero,changeLogradouro,changeCidade,clear,changeCidadeAtuacao,changeComplemento} from './Actions/actions'

function Consultant(props) {
    const [ error, setError ] = useState([]);
    const [ alert, setAlert ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ action, setAction ] = useState('')
    let history = useHistory();

    useEffect(() => {
        verifyConsultant()
        return () => {
            verifyConsultant()
        }
    }, [])

    useEffect(() => {
        getEdit()
        return () => {
            getEdit()
        }
    }, [])

    function verifyConsultant(){
        const id = props.match.params.id
        const action = props.match.params.action
        setAction(action)

        if(props.id_role === 2){
            if(id !== props.id_user){
                props.history.push(`/consultor/${props.id_user}/edit`)
                get_edit(props.id_user)
                .then(resp => (
                    props.editView(
                        resp.data[0]
                    )
                ))
                
            }
        
        }
    }

    function getEdit(){
        const id = props.match.params.id
        get_edit(id)
        .then(resp => (
            props.editView(
                resp.data[0]
            )
        ))
        .then(resp=>(
            props.UFS()

        ))
        .then(resp =>(
            props.getCidades()
        ))
    }

    function ufs(){
        const ufs = props.ufs || []
        return ufs.map(desc => (
            <option key={desc.id} value={desc.uf}>{desc.name}</option>
        ))
    }

    function cidades(){
        const cidades = props.cidades  || []
        return cidades.map(desc => (
            <option key={desc.id} value={desc.name}>{desc.name}</option>
        ))
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        let type_number = '';

        if (props.telefone) {
            if (props.telefone[2] === '2' || props.telefone[2] === '3' || props.telefone[2] === '4'){
                type_number = 1;
            } else if(props.telefone[2] === '7' || props.telefone[2] === '8' || props.telefone[2] === '9'){
                type_number = 2
            }
        }

        const data = {
            name:props.name,
            email:props.consultant === true ?(props.email):(props.emailAuth),
            cpf:props.cpf,
            zipcode:props.cep,
            state:props.uf,
            city:props.cidade,
            street:props.logradouro,
            number:props.numero,
            neighborhood:props.bairro,
            number_tel:props.telefone,
            birth_date:props.data_nascimento,
            id_user:props.id_user,
            complement: props.complemento,
            acting_city: props.cidade_atuacao,
            id_type_phone:type_number
        }
        if(props.consultant === false){
           
            post_consultant(data)
            .then(resp => {window.location.reload()})
            .then(resp => {
                setLoading(false)
                setSuccess("Consultor cadastrado com sucesso")
                setError([])
                setAlert('')
                
            })
                
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? ("Os dados fornecidos são inválidos"):(""))

            })
            return;

        }else if(props.consultant === true && props.id_user === props.match.params.id){

            put_consultant(data,props.consultant_id) 
            .then(resp => {
                setLoading(false)
                setSuccess("Dados editados com sucesso")
                setError([])
                setAlert('')
            })
            .then(resp => (setTimeout(()=>{
                window.location.reload()
            },1500)))
            .catch(e => {
                setLoading(false)
                setError(e.response.data.errors)
                setAlert(e.response.data.message ? ("Os dados fornecidos são inválidos"):(""))
            })
        }else if(props.consultant === true && props.id_user !== props.match.params.id){

            put_consultant(data,props.consultant_id_user) 
            .then(resp => {
                setLoading(false)
                setSuccess("Dados editados com sucesso")
                setError([])
                setAlert('')
            })      
            .then(resp => (setTimeout(()=>{
                window.location.reload()
            },1500)))
            .catch(e => {
                setLoading(false)
                setError(e.response.data.error)
                setAlert(e.response.data.message ? ("Os dados fornecidos são inválidos"):(""))
            })
        }
            
        
    }
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <Style 
                title="Consultor" 
                subtitle={action === 'view' ? ("Visualização de Consultor"):action === 'edit' ? ("Edição de Consultor"):("Cadastro de Consultor")}
            >
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('') }
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('') }
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>


                    <Form onSubmit={handleForm}>
                        <div className="row">              
                            <div className="col-md-4">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Nome *" value={props.name} onChange={props.changeNome} icon="fa-user" name="nome" type="text" placeholder="Digite o nome do consultor" required={true}/>
                                {error.errorsConsultant ? (<p style={{color:'red'}}>{error.errorsConsultant.name}</p>):('')}
                            </div>
                            <div className="col-md-4">
                                <NumberFormat disabled={action === 'view' || props.loadingCep === true ? (true):(false)} allowEmptyFormatting={false} icon="fa-address-card"  mask='' placeholder="Digite o CPF do consultor" label="CPF *"	customInput={Input} value={props.cpf} onChange={props.changeCPF}  format={"###.###.###-##"} />
                                {error.errorsConsultant ? (<p style={{color:'red'}}>{error.errorsConsultant.cpf}</p>):('')}              
                            </div>
                            <div className="col-md-4">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Data Nascimento *" min="1900-01-01" max="3000-01-01" value={props.data_nascimento} onChange={props.changeDataNascimento} icon="fa-user" name="data_nascimento" type="date" placeholder="Digite a data de nascimento"/>
                                {error.errorsConsultant ? (<p style={{color:'red'}}>{error.errorsConsultant.birth_date}</p>):('')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Cidade de Atuação" value={props.cidade_atuacao} onChange={props.changeCidadeAtuacao} icon="fa-street-view" name="cidade_atuacao" type="text" placeholder="Digite o nome da cidade de atuação"/>
                                {error.errorsAddressConsultant ? (<p style={{color:'red'}}>{error.errorsAddressConsultant.acting_city}</p>):('')}
                            </div>
                            <div className="col-md-4">
                                <Input label="Email" onChange={props.changeEmail} value={props.consultant === true ? (props.email):(props.emailAuth)} icon="fa-envelope" name="email" type="text" placeholder="Digite o email do consultor" disabled="disabled"/>
                            </div>
                            <div className="col-md-4">
                                <NumberFormat disabled={action === 'view' || props.loadingCep === true ? (true):(false)} allowEmptyFormatting={false} label="Telefone *"  mask='' customInput={Input} onChange={props.changeTelefone}  format={`${props.telefone}`[2] === '3' || `${props.telefone}`[2] === '4' || `${props.telefone}`[2] === '2' ?("(##) ####-####"):("(##) #####-####")} icon="fa-phone" value={props.telefone} name="telefone" type="text" placeholder="Digite o telefone do consultor" />
                                {error.errorPhone ? (<p style={{color:'red'}}>{error.errorPhone.number_tel}</p>):('')}                                
                            </div>
                        </div>
                        <hr></hr>
                        <br></br>
                        <h3>Endereço</h3>
                        <div className="row">
                            <div className="col-md-4">
                                <NumberFormat disabled={action === 'view'  ? (true):(false)} allowEmptyFormatting={false} icon="fa-street-view"  mask='' placeholder="Digite o CEP *" label="CEP" customInput={Input} value={props.cep} onChange={props.changeCep}  format={"#####-###"} />
                                {error.errorsAddress ? (<p style={{color:'red'}}>{error.errorsAddress.zipcode}</p>):('')}                              
                            </div>
                            <div className="col-md-6">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Logradouro" value={props.logradouro} onChange={props.changeLogradouro} icon="fa-home" name="logradouro" type="text" placeholder="Rua,avenida,etc..."/>
                                {error.errorsAddress ? (<p style={{color:'red'}}>{error.errorsAddress.street}</p>):('')}
                            </div>
                            <div className="col-md-2">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Numero" value={props.numero} onChange={props.changeNumero} icon="fa-home" name="numero" type="number" placeholder="Número"/>
                                {error.errorsAddressConsultant ? (<p style={{color:'red'}}>{error.errorsAddressConsultant.number}</p>):('')}
                            </div>
                            {/* <div className="col-md-1">
                                <div className="input-group mb-3" style={{paddingTop:'30px'}}>
                                    <button type="button" onClick={()=>props.getToCep()} className="btn btn-secondary">Buscar</button>
                                </div>
                            </div> */}
                        
                        </div>
                        <div className="row">
                        <div className="col-md-2">
                                <Select disabled={action === 'view' || props.loadingCep === true ? (true):(false)} onChange={props.changeUF} value={props.uf} label="UF">
                                    <option value="">Selecione a UF</option>
                                    {ufs()}
                                </Select>
                                {error.errorsAddress ? (<p style={{color:'red'}}>{error.errorsAddress.state}</p>):('')}
                            </div>
                            <div className="col-md-3">
                                <Select disabled={action === 'view' || props.loadingCep === true ? (true):(false)} onChange={props.changeCidade} value={props.cidade} label="Cidade">
                                    <option>Selecione a Cidade</option>
                                    {cidades()}
                                </Select>
                                {error.errorsAddress ? (<p style={{color:'red'}}>{error.errorsAddress.city}</p>):('')}
                            </div>                           
                        
                            <div className="col-md-4">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Bairro" value={props.bairro} onChange={props.changeBairro} icon="fa-home" name="bairro" type="text" placeholder="Digite o bairro"/>
                                {error.errorsAddress ? (<p style={{color:'red'}}>{error.errorsAddress.neighborhood}</p>):('')}
                            </div>
                            <div className="col-md-3">
                                <Input disabled={action === 'view' || props.loadingCep === true ? (true):(false)} label="Complemento"value={props.complemento} onChange={props.changeComplemento} icon="fa-home" name="complemento" type="text" placeholder="Complemento"/>
                                {error ? (<p style={{color:'red'}}>{error.complement}</p>):('')}
                            </div>
                            
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>                           
                            <button disabled={action === 'view' || props.loadingCep === true ? (true):(false)} type="submit" className="btn btn-primary">Salvar</button>                           
                        </div>        
                    </Form>
                </div>
            </Style>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    ufs:state.consultor.ufs,
    cidades:state.consultor.cidades,
    countrys:state.consultor.countrys,
    telefone:state.consultor.telefone,
    name:state.consultor.nome,
    email:state.consultor.email,
    emailAuth:state.auth.user.email,
    cep:state.consultor.cep,
    uf:state.consultor.uf,
    cidade:state.consultor.cidade,
    logradouro:state.consultor.logradouro,
    numero:state.consultor.numero,
    bairro:state.consultor.bairro,
    data_nascimento:state.consultor.data_nascimento,
    cidade_atuacao:state.consultor.cidade_atuacao,
    id_user:state.auth.id_user,
    complemento:state.consultor.complemento,
    cpf:state.consultor.cpf,
    consultant_id:state.auth.user.consultant_id,
    consultant:state.auth.consultant,
    loadingCep:state.consultor.loadingCep,
    id_role:state.auth.user.id_role,
    consultant_id_user:state.consultor.consultant_id,
    
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({clearInputs,editView,changeDataNascimento,changeUF,UFS,getCidades,changeCep,getToCep,changeCPF,changeTelefone,changeNome,changeEmail,changeBairro,changeNumero,changeLogradouro,changeCidade,clear,changeCidadeAtuacao,changeComplemento}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Consultant)
