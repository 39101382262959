import {app} from './Api'

const getCercosporas = async (page, qtd_per_page, typeArea, order, th, pesquisa, action) => {
    if (typeof action === "undefined") {
        action = "cercosporas";
    }
    return app.get(`cercospora?action=${action}&page=${page}&qtd=${qtd_per_page}&id_type_area=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_cercospora = async (data) => {
    return app.post('cercospora', data)
}

const get_cercospora = async (id_area, type_area, avaliationId) => {
    return app.get(`cercospora?action=cercospora&id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

const get_area_cercosporas = async (consultant) => {
    return app.get(`cercospora/get_area?consultant=${consultant}`)
}

const delete_cercospora = async (id_area, type_area, avaliationId) => {
    return app.delete(`cercospora?id_area=${id_area}&type_area=${type_area}&avaliation_id=${avaliationId}`)
}

export {
    post_cercospora,
    get_cercospora,
    get_area_cercosporas,
    getCercosporas,
    delete_cercospora
}