import { ibge,app,viacep } from "./Api";

const getUFS = async () => {
  return ibge.get("estados");
};

const cidadesporUF = async (uf) => {
  return ibge.get(`estados/${uf}/municipios`);
};

const getenderecocep = async (cep) => {
    return viacep.get(`${cep}/json/`);
};

const getUFSAPI = async () => {
  return app.get("state");
};

const cidadesporUFAPI = async (state_uf) => {
  return app.get(`city?state_uf=${state_uf}`);
};




export { getUFS, cidadesporUF, getenderecocep, getUFSAPI, cidadesporUFAPI };