import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/index'
import {Provider} from 'react-redux'
import jwt from 'jsonwebtoken'
import {URL} from './baseURL/baseURL'
import { information } from './Componentes/Services/auth'
import ReactLoading from 'react-loading'
import { Content } from './Componentes/Pages/Auth/styleLoading'

const jwt_secret = 'kVpQQnW5Ra0s6gJmQeG4PWP1xpoNPxwkUEYklPjA4654tckjZbNyBFirvRR32t9g'

let token = localStorage.getItem('token')

if(token){
    jwt.verify(token, jwt_secret, function(err, decoded){
        if(err){    
            console.log(err)
            if(err.name !== 'NotBeforeError'){
                localStorage.removeItem('token')
                localStorage.removeItem('id_role')
                localStorage.removeItem('consultant')
                token = null;
            }   
                    
        }else{
            if(decoded.iss !== URL+'/login'){
                console.log(decoded.iss)
                localStorage.removeItem('token')
                localStorage.removeItem('id_role')
                localStorage.removeItem('consultant')
                token = null;
            }
        }
        
    // jwt.verify(token, jwt_secret, function(err, decoded){
    //     if(err){
    //         // if(err.name !== 'TokenExpiredError'){      
    //         //     localStorage.removeItem('token')
    //         //     localStorage.removeItem('id_role')
    //         //     localStorage.removeItem('consultant')
    //         //     token = null;
    //         // }
    //         localStorage.removeItem('token')
    //         localStorage.removeItem('id_role')
    //         localStorage.removeItem('consultant')
    //         token = null;

            
    //     }else{
    //         // if(decoded.iss !== URL+'/login' && decoded.iss !== URL+'/refresh'){
    //         //     localStorage.removeItem('token')
    //         //     localStorage.removeItem('id_role')
    //         //     localStorage.removeItem('consultant')
    //         //     token = null;
    //         // }
    //         if(decoded.iss !== URL+'/login'){
    //             localStorage.removeItem('token')
    //             localStorage.removeItem('id_role')
    //             localStorage.removeItem('consultant')
    //             token = null;
    //         }
    //     }
        
    });
}

const render = () => {
    ReactDOM.render(
        <React.Fragment>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Fragment>
        
    , document.getElementById('root'));
}

const renderLoad = () => {
    ReactDOM.render(
        <Content>
            <ReactLoading type="spinningBubbles" color="blue" height={300} width={300} />
        </Content>,
        document.getElementById('root')
    );
}
if(token){
    renderLoad() 
    information() 
    .then(res => {
        store.dispatch({type: "SET_LOGIN", payload:res.data, password_confirmation:res.data.password_confirmation, consultant:res.data.consultant, id_user:res.data.id_user})
    })
    .then(res => {
        render()
    })
    // .catch(res => {
    //     setTimeout(()=> {
    //         render()
    //     },4000)
    // })
}else{
    render();
}
