const BrocaReducer = (state = {}, actions)=>{
    switch(actions.type){
        //  Broca Admin
        case 'BrocaList':
            return {...state,brocaList: actions.payload}
        case 'BrocaAvaliation':
            return {...state,brocaAvaliation: actions.payload}

        case 'GetBroca_Desc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'GetBroca_Asc':
            return {...state, desc: actions.desc, asc: actions.asc, order: actions.order, th: actions.th}

        case 'qtd_per_page_Broca':
            return {...state, qtd_per_page: actions.payload}

        case 'pesquisaBroca':
            return {...state, pesquisa: actions.payload}

        case 'clearPesquisaBroca':
            return {...state, pesquisa: actions.payload}

        case 'changeTypeArea':
            return {...state, type_area: actions.payload}

        case 'Broca_total':
            return {...state, total: actions.payload}

        case 'BrocaList_to':
            return {...state, to: actions.payload}

        case 'BrocaList_current_page':
            return {...state, current_page: actions.payload}

        case 'BrocaList_from':
            return {...state, from: actions.payload}

        case 'BrocaList_last_page':
            return {...state, last_page: actions.payload}

        // Cercospora Consultor
        case 'get_areas_cercospora':
            return{
                ...state,
                areas:actions.payload
            }
        case 'Broca':
            return{
                ...state,
                Broca:actions.payload
            }
        case 'set_alert_modal_cercospora':
            return{
                ...state,
                alertModal: actions.payload
            }
        default:
            return state;
    }
}
export default BrocaReducer;