import React from 'react'
import { Link } from 'react-router-dom'

export default function Areas({ areas, onDelete, id_role, typeArea }) {
    const area = areas || []
    return area.map(desc=>(
        <tr key={desc.area_id}>
            {/* <td>{desc.type_area}</td> */}
            <td>{desc.consultant}</td>
            <td>{desc.area_size_ha}</td>
            <td>{desc.farm}</td>
            <td>{desc.city_name}</td>
            <td>{desc.irrigation_name}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#/">
                            <i className="fas fa-ellipsis-h" />             
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>                  
                            <Link to={`/talhao/${desc.area_id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                            {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                            {id_role === 1 ? "" :(<Link to={`/talhao/${desc.area_id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>)}
                            <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.area_id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                        </div>
                    </li>
                </ul>		
            </td>                
        </tr>
    ))
    
}
