import { cidadesporUFAPI, getUFSAPI } from '../../../../Services/endereco'

import {getArea, get_area} from '../../../../Services/talhao'


/**
* @param event 
*  Função que pega o talhão selecionado
*/
export const changeNomeTalhao = event => {
    return [{
        type: 'Change_Nome_Talhao',
        payload: event.target.value
    }]
}

/**
* @param event 
*  Função que pega o talhão selecionado
*/
export const changeTalhao = event => {
    return [{
        type: 'Change_Talhao',
        payload: event.target.value
    }]
}

/**
* @param event 
*  Função que pega o produtor selecionado
*/
export const changeProdutor = event => {
    return [{
        type: 'Change_Produtor',
        payload: event.target.value
    }]
}

/**
* @param event 
*  Função que pega o nome digitado
*/
export const changeFazenda = event => {
    return [{
        type: 'Change_Fazenda',
        payload: event.target.value
    }]
}

/**
 * Função que pega a UF selecionado
 * @param {*} event 
 */
export const changeEstado = event => {
    return[{
        type:'change_Estado',
        payload:event.target.value
    },getCitys()]
}

/**
 * Função que pega a cidade selcionada
 * @param {*} event 
 */
export const changeCidade = event => {
    return[{
        type:'change_Cidade_Talhao',
        payload:event.target.value

    }]
}

/**
* 
* @param event
* Função que pega a tamanho total da fazenda.
*/
export const changeTotalFazenda  = event => {
    return [{
        type: 'Change_Total_Fazenda',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    }]
}

/**
* 
* @param event
* Função que pega o tamanho do talhao
*/
export const changeTamanhoTalhao  = event => {
    return [{
        type: 'Change_Tamanho_Talhao',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    },changePlantasUtilTalhao(),changeTotalPlantas()]
}

/**
* 
* @param event
* Função que pega a data do plantio
*/
export const changeDataPlantio  = event => {
    return [{
        type: 'Change_Data_Plantio',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega o espaçamento entre linhas(m)
*/
export const changeEspacamentoEntreLinhas  = event => {
    return [{
        type: 'Change_Espacamento_Linhas',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    },changePlantas()]
}

/**
* 
* @param event
* Função que pega o espaçamento entre plantas(m)
*/
export const changeEspacamentoPlantas  = event => {
    return [{
        type: 'Change_Espacamento_Plantas',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    },changePlantas()]
}

/**
* 
* @param event
* Função que pega a plantas/HA
*/
export const changePlantas = () => {
    return [(dispatch,getState)=> {
        const espacamento_linhas = getState().talhao.espacamento_linhas
        const espacamento_plantas = getState().talhao.espacamento_plantas
        dispatch({type:'Change_Plantas',payload:parseInt(10000/(parseFloat(espacamento_linhas) * parseFloat(espacamento_plantas)))})
    },changePlantasUtilTalhao(),changeTotalPlantas()]
}


/**
* 
* @param event
* Função que pega a cultivar
*/
export const changeCultivar = event => {
    return [{
        type: 'Change_Cultivar',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega a Maturção
*/
export const changeMaturacao = event => {
    return [{
        type: 'Change_Maturacao',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega a Irrigação
*/
export const changeIrrigacao = event => {
    return [{
        type: 'Change_Irrigacao',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega tratos culturais
*/
export const changeTratosCulturais = event => {
    return [{
        type: 'Change_Tratos_Culturais',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega a Latitude
*/
export const changeLatitude = event => {
    return [{
        type: 'Change_Latitude',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega a Latitude
*/
export const changeLongitude = event => {
    return [{
        type: 'Change_Longitude',
        payload: event.target.value
    }]
}

/**
* 
* @param event
* Função que pega a Altitude
*/
export const changeAltitude = event => {
    return [{
        type: 'Change_Altitude',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    }]
}

/**
* 
* @param event
* Função que pega as Falhas(ha)
*/
export const changeFalhas = event => {
    return [{
        type: 'Change_Falhas',
        payload: event.target.value.replace('.','').replace('.','').replace(',','.')
    },changePlantasUtilTalhao(),changeTotalPlantas()]
}

/**
* 
* @param event
* Função que pega as Plantas util/Talhao
*/
export const changePlantasUtilTalhao = () => {
    return [
        (dispatch,getState)=> {
            const plantas = getState().talhao.plantas
            const falhas = getState().talhao.falhas
            
            dispatch({type:'Change_Plantas_util_talhao',payload:parseFloat(plantas) - parseFloat(falhas)})
        }]
}

export const changeTotalPlantas = () => {
    return [
        (dispatch,getState)=> {
            const plantas = getState().talhao.plantas
            const falhas = getState().talhao.falhas
            const tamanho_talhao = getState().talhao.tamanho_talhao
            dispatch({type:'Change_Total_Plantas',payload:(parseFloat(plantas) - parseFloat(falhas))*parseFloat(tamanho_talhao)})
            
        }]
}

export const getUFS = () => {
    return dispatch => {
        getUFSAPI()
        .then(resp => dispatch({type:'Get_UfsTalhao', payload:resp.data}))        
    }
}

export const getCitys = () => {
    return (dispatch,getState) => {
        const state_id = getState().talhao.estado
        cidadesporUFAPI(state_id)
        .then(resp => dispatch({type:'Get_CitysTalhao', payload:resp.data}))
    }
}

export const getAtributo = (action) => {
    return dispatch => {
        getArea(action)
        .then(resp => dispatch({
            type:'Get_Atributos', 
            producer:resp.data.producer,
            maturation:resp.data.maturation,
            irrigation:resp.data.irrigation,
            variety:resp.data.variety,
            cultivation:resp.data.cultivation,
             
        }))
    }
}

/**
 * 
 * @param event 
 * Função que define a quantidade de itens à ser exibido por página
 */
export const qtdPerPage = event => {
    return [{
        type: 'qtdPerPageProdutor',
        payload: event.target.value
    },
    // get(1)
    ]
}

/**
 * 
 * @param event 
 * Função que pega o valor digitado no filtro
 */
export const getChangeFiltro = event => {
    return [{
        type: 'changeFiltroTalhao',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 * Função que limpa o filtro
 */
export const clearFiltro = event => {
    return [{
        type: 'ClearFiltroTalhao',
        payload: ''
    },
    //  get(1)
    ]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().talhao.order
        const th = getState().talhao.th
        const qtd_per_page = getState().talhao.qtd_per_page
        const pesquisa = getState().talhao.pesquisa || ''
      
        get_area(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'get_area', payload:resp.data}))

    }]
}


//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().talhao.asc
      
        if(asc === true){
            dispatch({type:'GetTalhao_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetTalhao_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_talhao',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaTalhao',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaTalhao',
        payload:''
    },get(1)]
}

export const editView = (data) => {
    return dispatch => {
        if(data){
            const plantas = 10000/(data.line_spacing*data.plants_spacing)
            
            dispatch({ 
                type:'editViewTalhao',                
                nome_talhao:data.area_name,
                produtor:data.producer_id,
                fazenda:data.farm,
                city:data.city,
                estado:data.state,
                total_fazenda:data.farm_size_ha,
                tamanho_talhao:data.area_size_ha,
                data_plantio:data.planting_date,
                espacamento_linhas:data.line_spacing,
                espacamento_plantas:data.plants_spacing,
                cultivar:data.variety_id,
                maturacao:data.maturation_id,
                irrigacao:data.irrigation_id,
                tratos_culturais:data.cultivation_id,
                latitude:data.latitude,
                longitude:data.longitude,
                altitude:data.altitude,
                falhas:data.failure_ha,
                plantas:plantas,
                plantas_util_talhao:Math.round(plantas - data.failure_ha),
                total_plantas:Math.round((plantas - data.failure_ha) * data.area_size_ha),
                consultant_id:data.consultant_id             
            }) 
        }
    }           
}

export const clearInput = () => {
    return dispatch => {
        dispatch({
            type: 'clearInputTalhao',
            nome_talhao: '',
            talhao: '',
            produtor: '',
            fazenda: '',
            city: '',
            estado: '',
            total_fazenda: '',
            tamanho_talhao: '',
            data_plantio: '',
            espacamento_linhas: '',
            espacamento_plantas: '',
            cultivar: '',
            maturacao: '',
            irrigacao: '',
            tratos_culturais: '',
            latitude: '',
            longitude: '',
            altitude: '',
            falhas: '',
            plantas: '',
            plantas_util_talhao: '',         
            total_plantas: '',
            consultant_id: '',
        })
                 
    }
}

