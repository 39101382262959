export const HeaderCSV = [
    {label: 'Nome do Consultor',key:'consultant_name'}, 
    {label: 'Nome do Produtor', key:'name'},
    {label: 'CPF', key:'document'}, 
    {label: 'Email', key:'email'},
    {label: 'Telefone', key:'phone'},
    {label: 'Data de Nascimento', key:'birth_date'},
    {label: 'CEP', key:'zipcode'},
    {label: 'Rua', key:'street'},
    {label: 'Número', key:'number_home'},
    {label: 'Bairro', key:'neighborhood'},
    {label: 'Estado', key:'state'},
    {label: 'Cidade', key:'city'},
]
