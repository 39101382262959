import {getPhomas} from "../../../../Services/phoma";

//Função que traz os dados do banco de dados
export const get = (page) => {
    return [(dispatch, getState) => {

        if (typeof page === "undefined") {
            page = getState().phoma.phomasList.current_page
        }
        const order = getState().phoma.order
        const th = getState().phoma.th
        const qtd_per_page = getState().phoma.qtd_per_page
        const pesquisa = getState().phoma.pesquisa || ''
        const typeArea = getState().phoma.type_area || ''

        getPhomas(page, qtd_per_page, typeArea, order, th, pesquisa)
            .then(resp => {
                dispatch({type: 'PhomasList', payload: resp.data})
            })
    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch, getState) => {
        const asc = getState().phoma.asc

        if (asc === true) {
            dispatch({type: 'GetPhomas_Desc', desc: true, asc: false, order: 'desc', th: coluna})

        }
        if (asc === false) {
            dispatch({type: 'GetPhomas_Asc', asc: true, desc: false, order: 'asc', th: coluna})

        }

    }, get(1)]
}

export const change_qtd_per_page = event => {
    return [{
        type: 'qtd_per_page_Phomas',
        payload: event.target.value
    }, get(1)]
}

export const changePesquisa = event => {
    return [{
        type: 'pesquisaPhomas',
        payload: event.target.value
    }]
}

export const clearPesquisa = event => {
    return [{
        type: 'clearPesquisaPhomas',
        payload: ''
    }, get(1)]
}