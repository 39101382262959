import { app } from './Api'

const getArea = async (action) => {
    return app.get(`area/create?action=${action}`)
}


const post_talhao = async (data) => {
    return app.post('area',data)
}

const get_view_area = async (id) => {
    return app.get(`area/${id}`)
}

const put_area = async (data,id) => {
    return app.put(`area/${id}`,data)
}

const post_area = async (data) => {
    return app.post('area',data)
}

const get_area = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`area?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const delete_area = async (id) => {
    return app.delete(`area/${id}`)
}

export { post_talhao, getArea, post_area, put_area, get_view_area, delete_area, get_area }