const TalhaoReducer = (state = {}, actions)=>{
    switch(actions.type){
        
        case 'Change_Nome_Talhao':
            return{...state, nome_talhao:actions.payload}

        case 'change_Cidade_Talhao':
            return{...state, city:actions.payload}
      
        case 'Change_Talhao':
            return{...state, talhao:actions.payload}

        case 'changeAreaType':
            return{...state, type_area:actions.payload}
        
        case 'Change_Produtor':
            return{...state, produtor:actions.payload}
        
        case 'Change_Fazenda':
            return{...state, fazenda:actions.payload}
        
        case'change_Estado':
            return{...state, estado:actions.payload}
        
        case 'Change_Cidade':
            return{...state, cidade:actions.payload}
        
        case 'Change_Total_Fazenda':
            return{...state, total_fazenda:parseFloat(actions.payload)}
            
        case 'Change_Tamanho_Talhao':
            return{...state, tamanho_talhao:parseFloat(actions.payload)}
        
        case 'Change_Data_Plantio':
            return{...state, data_plantio:actions.payload}
        
        case 'Change_Espacamento_Linhas':
            return{...state, espacamento_linhas:parseFloat(actions.payload)}
        
        case 'Change_Espacamento_Plantas':
            return{...state, espacamento_plantas:parseFloat(actions.payload)}
        
        case 'Change_Plantas':
            return{...state, plantas:actions.payload}
        
        case 'Change_Cultivar':
            return{...state, cultivar:actions.payload}
        
        case 'Change_Maturacao':
            return{...state, maturacao:actions.payload}
        
        case 'Change_Irrigacao':
            return{...state, irrigacao:actions.payload}
        
        case 'Change_Tratos_Culturais':
            return{...state, tratos_culturais:actions.payload}

        case 'Change_Latitude':
            return{...state, latitude:actions.payload}

        case 'Change_Longitude':
            return{...state, longitude:actions.payload}

        case 'Change_Altitude':
            return{...state, altitude:parseFloat(actions.payload)}
        
        case 'Change_Falhas':
            return{...state, falhas:parseFloat(actions.payload)}
        
        case 'Change_Plantas_util_talhao':
            return{...state, plantas_util_talhao:actions.payload}  

        case 'Change_Total_Plantas':
            return{...state, total_plantas:actions.payload}  

        case 'Get_UfsTalhao':
            return{...state, ufs:actions.payload}
        
        case 'Get_CitysTalhao':
            return{...state, cidades:actions.payload}
        
        case 'Get_Atributos':
            return{...state, 
                producer:actions.producer,
                maturation:actions.maturation,
                irrigation:actions.irrigation,
                variety:actions.variety,
                type_area:actions.type_area,
                cultivation:actions.cultivation
            }
        
        case 'GetTalhao_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetTalhao_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_talhao':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaTalhao':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaTalhao':
            return{...state, pesquisa:actions.payload}

        case 'get_area':
            return{...state, talhoes:actions.payload}

        case 'editViewTalhao':
            return{...state,
                nome_talhao:actions.nome_talhao,
                talhao:actions.talhao,
                produtor:actions.produtor,
                fazenda:actions.fazenda,
                city:actions.city,
                estado:actions.estado,
                total_fazenda:actions.total_fazenda,
                tamanho_talhao:actions.tamanho_talhao,
                data_plantio:actions.data_plantio,
                espacamento_linhas:actions.espacamento_linhas,
                espacamento_plantas:actions.espacamento_plantas,
                cultivar:actions.cultivar,
                maturacao:actions.maturacao,
                irrigacao:actions.irrigacao,
                tratos_culturais:actions.tratos_culturais,
                latitude:actions.latitude,
                longitude:actions.longitude,
                altitude:actions.altitude,
                falhas:actions.falhas,
                plantas_util_talhao:actions.plantas_util_talhao,
                consultant_id:actions.consultant_id,
                plantas:actions.plantas,
                total_plantas:actions.total_plantas,
            }
        case 'clearInputTalhao':
            return{...state,
                nome_talhao: actions.nome_talhao,
                talhao: actions.talhao,
                produtor: actions.produtor,
                fazenda: actions.fazenda,
                city: actions.city,
                estado: actions.estado,
                total_fazenda: actions.total_fazenda,
                tamanho_talhao: actions.tamanho_talhao,
                data_plantio: actions.data_plantio,
                espacamento_linhas: actions.espacamento_linhas,
                espacamento_plantas: actions.espacamento_plantas,
                cultivar: actions.cultivar,
                maturacao: actions.maturacao,
                irrigacao: actions.irrigacao,
                tratos_culturais: actions.tratos_culturais,
                latitude: actions.latitude,
                longitude: actions.longitude,
                altitude: actions.altitude,
                falhas: actions.falhas,
                plantas: actions.plantas,
                plantas_util_talhao: actions.plantas_util_talhao,         
                total_plantas: actions.total_plantas,
                consultant_id: actions.consultant_id,
                
            }

        default:
            return state;      
    }
}
export default TalhaoReducer;