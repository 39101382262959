import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Wrapper, LogoStyle } from './styles'
import { updatePassword } from '../../Services/auth'
import Alert from '../../Alerts/alert'
import Logo from '../../../Image/aliancaCafe.png';

class Login extends Component {

    constructor(props){
        super(props)
        this.state={password:'',confirm_password:'',error:'',success:''}
        
    }

    handleForm=(e)=>{
        e.preventDefault();
        const data = {password:this.state.password,confirm_password:this.state.confirm_password}
        if(this.state.password !== this.state.confirm_password){
            this.setState({error:'As senhas não conferem !'})
        }else{
            
            updatePassword(data)
            .then(resp => {this.setState({success:resp.data.message, error:''})}) 
            .then(resp =>(
                setTimeout(()=>{
                    this.logout()  
                    window.location.reload()
    
                },3000)
            ))
           
            .catch(e => this.setState({error: e.response.data.error}))
        }
        
    }

    logout = () =>{
        localStorage.removeItem('token')
        localStorage.removeItem('id_role')
        localStorage.removeItem('consultant')
    } 

    changePassword =(e)=> {
        e.preventDefault();
        const password = e.target.value
        this.setState({password:password})
    }

    changeConfirmPassword =(e)=> {
        e.preventDefault();
        const confirm_password = e.target.value
        this.setState({confirm_password:confirm_password})
    }

    render() {
        const error = this.state.error
        const sucesso = this.state.sucesso
        return (
            <React.Fragment>
                <Wrapper>
                    <div className="login-box">            
                        {/* /.login-logo */}
                        <div className="card">
                                <LogoStyle>
                                    <img src={Logo} alt="Logo Aliança Café" width="100%" height="100px" className="d-block" />
                                </LogoStyle>
                            <div className="card-body login-card-body">
                            {error.errors ?( <p className="errors" style={{color: '#DC143C'}}>{error.errors}</p>):(sucesso)}
                                <i style={{marginLeft:'125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                <h5 className="login-box-msg">Altere sua senha inicial</h5>
                                <p className="login-box-msg">Digite sua nova senha</p>
                                {
                                    this.state.error ? (
                                        <div>
                                            <Alert type="danger" hidden="true">
                                                {this.state.error}
                                            </Alert>
                                        </div>
                                    ) :('')                                         
                                }
                                {
                                    this.state.success ? (
                                        <div>
                                            <Alert type="success" hidden="true">
                                                {this.state.success}
                                            </Alert>
                                        </div>
                                    ) :('')                                         
                                }
                                <form onSubmit={this.handleForm}>
                                    <div className="input-group mb-3">
                                        <input onChange={this.changePassword} type="password" name="passoword" className="form-control" placeholder="Digite sua senha" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fa fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input onChange={this.changeConfirmPassword} type="password" name="passoword_confirm" className="form-control" placeholder="Confirme sua senha" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fa fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">      
                                        <button type="submit" className="btn btn-primary btn-block">Confirmar</button>
                                    </div>
                                </form>
                                <div className="social-auth-links text-center mb-3">
                                    
                                </div>
                            
                            </div>
                        {/* /.login-card-body */}
                        </div>
                    </div>
                </Wrapper>
                {/* /.login-box */}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    consultant:state.auth.consultant,
    id_user:state.auth.user.id_user
      
});
export default connect(mapStateToProps,null)(Login);