import styled from "styled-components";
import imageLogin from '../../../Image/login.jpeg';

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${imageLogin});
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;



export const LogoStyle = styled.div`
    /* position:relative;
    left:50%;
    margin-left:-65px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
    
`;

