/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Header from "../../../Header/header";
import Sidebar from "../../../Sidebar/sidebar";
import Form from "../../../Forms/form";
import Footer from "../../../Footer/footer";
import StylePage from "../../../Style Page/style_page";
import { get_module, post_module } from "../../../Services/moduloAvaliacao";
import Alert from "../../../Alerts/alert";
import { Content } from "../../Auth/styleLoading";
import { ContentButton } from "../../../Button/style";

export default function ModuloAvaliacao(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [assessmentModule, setAssessmentModule] = useState("1");
  const [phomaECercospora, setPhomaECercospora] =
    useState("Phoma e Cercospora");
  const [bichoMineiroEBroca, setBichoMineiroEBroca] = useState(
    "Bicho Mineiro e Broca"
  );
  const timeMessage = 4000;

  useEffect(() => {
    setLoading(true);
    getModuloAvaliacao();
  }, []);

  const getModuloAvaliacao = () => {
    get_module()
      .then((resp) => {
        setLoading(false);
        switch (resp.data.pests_id) {
          case 1:
            setAssessmentModule(phomaECercospora);
            break;
          case 2:
            setAssessmentModule(bichoMineiroEBroca);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        setSuccess("");
        setError(err.response.data.error);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  const handleChange = (event) => {
    setAssessmentModule(event.target.value);
  };

  const handleForm = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {
      pests_id: "",
    };

    switch (assessmentModule) {
      case phomaECercospora:
        data.pests_id = 1;

        break;
      case bichoMineiroEBroca:
        data.pests_id = 2;

        break;
      default:
        break;
    }

    post_module(data)
      .then((resp) => {
        setLoading(false);
        setError("");
        setSuccess(resp.data.success);
        setTimeout(() => {
          setSuccess("");
          history.go(0);
        }, timeMessage);
      })
      .then(() => getModuloAvaliacao())
      .catch((err) => {
        setLoading(false);
        setSuccess("");
        setError(err.response.data.error);
        setTimeout(() => {
          setError("");
        }, timeMessage);
      });
  };

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <StylePage title={"Módulo de Avaliações"}>
        {alert && (
          <div>
            <Alert type="danger">{alert}</Alert>
          </div>
        )}
        {loading === true ? (
          <Content>
            <ReactLoading
              type="spinningBubbles"
              color="blue"
              height={200}
              width={100}
            />
          </Content>
        ) : (
          ""
        )}
        {success ? (
          <Alert type="success" hidden>
            {success}
          </Alert>
        ) : (
          ""
        )}
        {error ? (
          <Alert type="danger" hidden>
            {error}
          </Alert>
        ) : (
          ""
        )}

        <div style={{ display: loading === true ? "none" : "block" }}>
          <Form onSubmit={handleForm}>
            <div className="mt-2">
              <FormLabel id="demo-row-radio-buttons-group-label">
                Selecione o Módulo de Avaliações:
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={assessmentModule}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={phomaECercospora}
                  control={<Radio color="primary" />}
                  label={phomaECercospora}
                />
                <FormControlLabel
                  value={bichoMineiroEBroca}
                  control={<Radio color="primary" />}
                  label={bichoMineiroEBroca}
                />
              </RadioGroup>
            </div>

            <div className="text-right" style={{ marginTop: "50px" }}>
              <ContentButton>
                <button
                  style={{ marginRight: "10px" }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-primary"
                  disabled={props.match.params.action === "view" ? true : false}
                  type="submit"
                >
                  Salvar
                </button>
              </ContentButton>
            </div>
          </Form>
        </div>
      </StylePage>
      <Footer />
    </div>
  );
}
