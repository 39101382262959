import { app } from './Api'

const get_areas = async (typeArea) => {
    return app.get(`productivity/create?typeArea=${typeArea}`)
}

const post_produtividade = async (data) => {
    return app.post(`productivity`, data)
}

const view_produtividade = async (id) => {
    return app.get(`productivity/${id}`)
}

const put_produtividade = async (data,id) => {
    return app.put(`productivity/${id}`,data)
}

const get_produtividade = async (page,qtd_per_page,typeArea,order,th,pesquisa) => {
    return app.get(`productivity?page=${page}&qtd=${qtd_per_page}&typeArea=${typeArea}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const delete_produtividade = async (id) => {
    return app.delete(`productivity/${id}`)
}

const getResource = async (type_area_id) => {
    return app.get(`productivity/create?type_area_id=${type_area_id || ''}`)
}

const postProductivity = async (data) => {
    return app.post(`productivity`, data)
}
const putProductivity = async (id,data) => {
    return app.put(`productivity/${id}`, data)
}

const viewPerId = async (id) => {
    return app.get(`productivity/${id}`)
}

export { get_areas, post_produtividade, view_produtividade, put_produtividade, get_produtividade, delete_produtividade, getResource, postProductivity, putProductivity, viewPerId }