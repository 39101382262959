import React, {useState, useEffect} from 'react'
import Header from '../../../Header/header'
import Sidebar from '../../../Sidebar/sidebar'
import Footer from '../../../Footer/footer'
import Table from '../../../Table/table'
import Thead from '../../../Table/thead'
import Tbody from '../../../Table/tbody'
import Tfoot from '../../../Table/tfoot'
import StylePage from '../../../Style Page/style_page'
import {useDispatch, useSelector} from 'react-redux'
import Cabecalho from '../../../Table/cabecalho'
import Alert from '../../../Alerts/alert'
import ReactLoading from 'react-loading'
import {Content} from '../../Auth/styleLoading'
import {post_broca, get_broca, get_area_broca} from '../../../Services/broca'
import {get_module} from '../../../Services/moduloAvaliacao'
import {useHistory} from 'react-router'
import NumberFormat from 'react-number-format'
import {Select, MenuItem, SelectChangeEvent, Input} from '@material-ui/core'
import {useRef} from 'react'
import {Link} from "react-router-dom";

import { FaChevronDown, FaChevronUp, FaCheck } from 'react-icons/fa'; // Importe os ícones conforme necessário

function GridBroca(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState(false)
    const [requiredPonto1, setRequiredPonto1] = useState(false)
    const [requiredPonto2, setRequiredPonto2] = useState(false)
    const [requiredPonto3, setRequiredPonto3] = useState(false)
    const [requiredPonto4, setRequiredPonto4] = useState(false)
    const typeArea = props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2
    const areas = useSelector(state => state.phoma.areas)
    const id_role = useSelector(state => state.auth.user.id_role);

    const [assentmentModule, setAssentmentModule] = useState("");
    const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');

    const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);

    useEffect(() => {
        // Atualiza a largura da tela
       const atualizarLarguraDaTela = () => {
           setLarguraDaTela(window.innerWidth);
       };

       // Evento para detectar mudanças na largura da tela
       window.addEventListener('resize', atualizarLarguraDaTela);

       return () => {
           // Remove o evento 
           window.removeEventListener('resize', atualizarLarguraDaTela);
       };
    }, []);

    // GET Modulo avaliação
    useEffect(() => {
        getModuloAvaliacao(assentmentModule);
      }, []);
    
      const getModuloAvaliacao = () => {
        get_module().then((resp) => {
          setAssentmentModule(resp.data.pests_id);
        });
      };

    const [flagPreenchido, setFlagPreenchido] = useState(
        Array.from({ length: 7 }, () => true)
    );

    // Constante data
    // const [dataPhome, setDataPhome] = useState("");
    const [dataPhome, setDataPhome] = useState(
        Array.from({ length: 7 }, () => "")
    );

    // Constante ponto
    // const [ponto1, setPonto1] = useState([
    //     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    // ])
    const [ponto1, setPonto1] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
    ]));
    // const [ponto2, setPonto2] = useState([
    //     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    // ])
    const [ponto2, setPonto2] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
    ]));
    // const [ponto3, setPonto3] = useState([
    //     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    // ])
    const [ponto3, setPonto3] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
    ]));
    // const [ponto4, setPonto4] = useState([
    //     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    // ])
    const [ponto4, setPonto4] = useState([...Array(7)].map(() => [
        {ramo: 0, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 1, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 2, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 3, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 4, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 5, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 6, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 7, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 8, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
        {ramo: 9, folhaTotal: null, perfuradosRamo: null, sementesDanificadas: null, avaliacao: 0},
    ]));
    // const selectAvaliationRef = useRef(null)
    const [avaliation, setAvaliation] = useState('')
    // const optionsAvaliation = [
    //     {label: '1', value: '1'},
    //     {label: '2', value: '2'},
    //     {label: '3', value: '3'},
    //     {label: '4', value: '4'}
    // ]

    window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
    window.addEventListener('popstate', (event) => {
        if (event.state) {
            return ''
        }
    }, false);

    // const handleChange = (event) => {
    //     dispatch({type: 'set_alert_modal_phoma', payload: 1})
    //     setAvaliation(event.target.value);
    //     getPhomas(event.target.value)
    // };

    // useEffect(() => {
    //     if (areas.length > 0) {
    //         if (id_role === 1) {
    //             setAvaliation(() => parseInt(props.match.params.avaliacao));
    //             getPhomas(props.match.params.avaliacao);
    //             return () => {
    //                 getPhomas(props.match.params.avaliacao);
    //             }
    //         } else {
    //             for (let avaliationId = 1; avaliationId <= 7; avaliationId++) {
    //                 getPhomas(avaliationId);
    //             }
    //             return () => {
    //                 for (let avaliationId = 1; avaliationId <= 7; avaliationId++) {
    //                     getPhomas(avaliationId);
    //                 }
    //             }
    //         }
    //     }
    // }, [areas]);

    // Função que vai setar os valores do banco nas variaveis ou setar o default caso nao tenha
    const getUpdatedPonto = (results, pontoName) => {
        return results.map((result, index) => {
            if (result.data[pontoName] && result.data[pontoName].length === 10) {
                return result.data[pontoName]; // Retorna o array completo do ponto
            }
    
            // Caso nao tenha dados para ser retornado crio um array padrão com objetos independentes
            return Array.from({ length: 10 }, (_, subIndex) => ({
                ramo: subIndex,
                folhaTotal: null,
                perfuradosRamo: null,
                sementesDanificadas: null,
                avaliacao: 0
            }));
        });
    };

    useEffect(() => {
        if (areas.length > 0) {
            if (id_role === 1) {
                setAvaliation(() => parseInt(props.match.params.avaliacao));
                getPhomas(props.match.params.avaliacao);
            } else {
                const promises = [];
    
                for (let avaliationId = 1; avaliationId <= 7; avaliationId++) {
                    promises.push(getPhomas(avaliationId));
                }
    
                Promise.all(promises)
                    .then(results => {
                        const updatedDataPhome = results.map(result => {
                            if (result.data.broca1 && result.data.broca1.length > 0) {
                                return result.data.broca1[0].dataPhome;
                            }
                            return null;
                        });
                        
                        const updatedPonto1 = getUpdatedPonto(results, 'broca1');
                        const updatedPonto2 = getUpdatedPonto(results, 'broca2');
                        const updatedPonto3 = getUpdatedPonto(results, 'broca3');
                        const updatedPonto4 = getUpdatedPonto(results, 'broca4');

                        setDataPhome(updatedDataPhome);
                        setPonto1(updatedPonto1);
                        setPonto2(updatedPonto2);
                        setPonto3(updatedPonto3);
                        setPonto4(updatedPonto4);
                    })
                    .catch(err => {
                        console.error("Error:", err);
                    });
            }
        }
    }, [areas]);
    
    useEffect(() => {
        getAreas();
        return () => {
            getAreas()
        }
    }, []);

    useEffect(() => {
        let required = avaliation || ponto1[0].avaliation
        setAvaliation(required)
    }, [ponto1]);

    useEffect(() => {
        let required = dataPhome || ponto1[0].dataPhome
        setDataPhome(required)
    }, [ponto1]);

    useEffect(() => {
        let required = ponto1.map(value => value.folhaTotal != null)
        setRequiredPonto1(required.find(value => value == true ? true : false))
    }, [ponto1]);

    useEffect(() => {
        let required = ponto2.map(value => value.folhaTotal != null)
        setRequiredPonto2(required.find(value => value == true ? true : false))
    }, [ponto2]);

    useEffect(() => {
        let required = ponto3.map(value => value.folhaTotal != null)
        setRequiredPonto3(required.find(value => value == true ? true : false))
    }, [ponto3]);

    useEffect(() => {
        let required = ponto4.map(value => value.folhaTotal != null)
        setRequiredPonto4(required.find(value => value == true ? true : false))
    }, [ponto4]);

    // Adiciona phoma a store do redux
    useEffect(() => {
        if (ponto1.length > 0 && ponto2.length > 0 && ponto3.length > 0 && ponto4.length > 0) {
            let data = {
                ponto1,
                ponto2,
                ponto3,
                ponto4,
                area_id: areas[0]?.id,
                typeArea: typeArea,
                avaliation: avaliation,
                dataPhome: dataPhome
            }

            dispatch({type: 'phomas', payload: data})
        }
    }, [ponto1, ponto2, ponto3, ponto4, avaliation, dataPhome]);

// Adiciona phoma a store do redux
// useEffect(() => {
//     for (let avaliationId = 0; avaliationId <= 7; avaliationId++) {
//         if (
//             ponto1[avaliationId]?.length > 0 &&
//             ponto2[avaliationId]?.length > 0 &&
//             ponto3[avaliationId]?.length > 0 &&
//             ponto4[avaliationId]?.length > 0 &&
//             dataPhome[avaliationId]
//         ) {
//             let data = {
//                 ponto1: ponto1[avaliationId],
//                 ponto2: ponto2[avaliationId],
//                 ponto3: ponto3[avaliationId],
//                 ponto4: ponto4[avaliationId],
//                 area_id: areas[0]?.id,
//                 typeArea: typeArea,
//                 avaliation: avaliationId,
//                 dataPhome: dataPhome[avaliationId]
//             }

//             dispatch({ type: 'phomas', payload: data });
//         }
//     }
// }, [ponto1, ponto2, ponto3, ponto4, dataPhome]);


    useEffect(() => {
        if (id_role === 2) {
            dispatch({type: 'phomas', payload: []})
        }
    }, [typeArea]);

    const getAreas = () => {
        setLoading(true)
        get_area_broca(id_role === 1 ? props.match.params.consultantId : null)
            .then((resp) => {
                if (resp.data.length <= 0 && id_role === 2) {
                    dispatch({type: 'set_alert_modal_phoma', payload: 0})
                    dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                    setAlert(`Você ainda não tem um talhão cadastrado. É necessário o cadastro primeiro.`)
                    setLoading(false)
                    setTimeout(() => {
                        setAlert(false)
                        history.push(typeArea === 1 ? '/talhoes/manejo_syngenta' : '/talhoes/manejo_produtor')
                    }, 4000)
                } else {
                    setLoading(false)
                }
                return resp
            })
            .then(resp => {
                dispatch({type: 'get_areas_phomo', payload: resp.data})
            })
            .catch(err => {
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    // const getPhomas = (avaliationId) => {
    //     get_phoma(areas[0].id, typeArea, avaliationId)
    //         .then(resp => {
    //             console.log(avaliationId)
    //             if (resp.data.phoma1.length === 10 &&
    //                 resp.data.phoma2.length === 10 &&
    //                 resp.data.phoma3.length === 10 &&
    //                 resp.data.phoma4.length === 10
    //             ) {
    //                 // setPonto1(resp.data.phoma1)
    //                 // setPonto2(resp.data.phoma2)
    //                 // setPonto3(resp.data.phoma3)
    //                 // setPonto4(resp.data.phoma4)

    //                 const newValueData = resp.data.phoma1[0].dataPhome;
    //                 const newArrayData = [...dataPhome]
    //                 newArrayData[avaliationId - 1] = newValueData

    //                 setDataPhome(newArrayData)

    //                 let data = {
    //                     ponto1: resp.data.phoma1,
    //                     ponto2: resp.data.phoma2,
    //                     ponto3: resp.data.phoma3,
    //                     ponto4: resp.data.phoma4,
    //                     area_id: areas[0].id,
    //                     typeArea: typeArea,
    //                     avaliation: resp.data.phoma1[0].avaliation,
    //                     dataPhome: resp.data.phoma1[0].dataPhome
    //                 }
    //                 console.log(dataPhome)
    //                 dispatch({type: 'phomas', payload: data})
                    

    //             } else {
    //                 setPonto1([
    //                     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                 ])
    //                 setPonto2([
    //                     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                 ])
    //                 setPonto3([
    //                     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                 ])
    //                 setPonto4([
    //                     {ramo: 0, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 1, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 2, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 3, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 4, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 5, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 6, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 7, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 8, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                     {ramo: 9, folhaTotal: null, phomaTotal: null, phomaFruto: null, avaliacao: 0},
    //                 ])
    //             }
    //         })
    //         .catch(err => {
    //             setError(err.response.data.error);
    //             setTimeout(() => {
    //                 setError('');
    //             }, 3500)
    //             console.log(err)
    //         })
    // }

    const getPhomas = (avaliationId) => {
        return new Promise((resolve, reject) => {
            get_broca(areas[0].id, typeArea, avaliationId)
                .then(resp => {
                    resolve(resp); 
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 3500);
                    reject(err);
                });
        });
    };
    // const handleChangeFirstPoint = (e, index) => {
    //     dispatch({type: 'set_alert_modal_phoma', payload: 1})
    //     switch (e.target.name) {
    //         case 'folha':
    //             let itens = ponto1[index].map(elem => elem);
    //             let valor = e.target.value
    //             let ramo = e.target.id
    //             itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
    //             console.log(itens)
    //             setPonto1()
                // (e) => {
                //     const newValue = e.target.value;
                //     const newArray = [...dataPhome]
                //     newArray[index] = newValue
                //     setDataPhome(newArray)
            
                //     dispatch({ type: 'set_alert_modal_phoma', payload: 1 });
                // }
                // break;
            // case 'phoma':
            //     let item = ponto1.map(elem => elem);
            //     let valorPhoma = e.target.value;
            //     let ramoPhoma = e.target.id;
            //     item[ramoPhoma].phomaTotal = parseInt(valorPhoma);
            //     setPonto1(item)
            //     break;
            // case 'fruto':
            //     let itemFruto = ponto1.map(elem => elem);
            //     let valorFruto = e.target.value;
            //     let ramoFruto = e.target.id
            //     itemFruto[ramoFruto].phomaFruto = parseInt(valorFruto);
            //     setPonto1(itemFruto)
            //     break;
            // case 'presenca1':
            //     let checkPresenca = ponto1.map(elem => elem);
            //     checkPresenca.map(elem => elem.avaliacao = +e.target.value)
            //     setPonto1(checkPresenca);
            //     break;
    //         default:
    //             break;
    //     }
    // }
    
    // (handleChange) PONTOS
    const handleChangeFirstPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });
  
        const { name, value, id } = e.target;
    
        setPonto1(prevPonto1 => {
            const newPonto1 = [...prevPonto1]; // Cria copia de ponto1
            switch (name) {
                case 'folha':
                    newPonto1[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto1[index][id].perfuradosRamo = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto1[index][id].sementesDanificadas = parseInt(value);
                    break;
                case 'presenca1':
                    newPonto1[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto1; // Retorna atualizado a copia do ponto1
        });
    };

    // const handleChangeSecondPoint = (e) => {
    //     dispatch({type: 'set_alert_modal_phoma', payload: 1})
    //     switch (e.target.name) {
    //         case 'folha':
    //             let itens = ponto2.map(elem => elem);
    //             let valor = e.target.value
    //             let ramo = e.target.id
    //             itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
    //             setPonto2(itens)
    //             break;
    //         case 'phoma':
    //             let item = ponto2.map(elem => elem);
    //             let valorPhoma = e.target.value
    //             let ramoPhoma = e.target.id
    //             item[ramoPhoma].phomaTotal = parseInt(valorPhoma);
    //             setPonto2(item)
    //             break;
    //         case 'fruto2':
    //             let itemFruto = ponto2.map(elem => elem);
    //             let valorFruto = e.target.value
    //             let ramoFruto = e.target.id
    //             itemFruto[ramoFruto].phomaFruto = parseInt(valorFruto);
    //             setPonto2(itemFruto)
    //             break;
    //         case 'presenca2':
    //             let checkPresenca = ponto2.map(elem => elem);
    //             checkPresenca.map(elem => elem.avaliacao = +e.target.value)
    //             setPonto2(checkPresenca);
    //             break;
    //         default:
    //             break;
    //     }
    // }

    const handleChangeSecondPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto2(prevPonto2 => {
            const newPonto2 = [...prevPonto2]; // Cria copia de ponto2
            switch (name) {
                case 'folha':
                    newPonto2[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto2[index][id].perfuradosRamo = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto2[index][id].sementesDanificadas = parseInt(value);
                    break;
                case 'presenca2':
                    newPonto2[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto2; // Retorna atualizado a copia do ponto2
        });
    };

    // const handleChangeThirdPoint = (e) => {
    //     dispatch({type: 'set_alert_modal_phoma', payload: 1})
    //     switch (e.target.name) {
    //         case 'folha':
    //             let itens = ponto3.map(elem => elem);
    //             let valor = e.target.value
    //             let ramo = e.target.id
    //             itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
    //             setPonto3(itens)
    //             break;
    //         case 'phoma':
    //             let item = ponto3.map(elem => elem);
    //             let valorPhoma = e.target.value
    //             let ramoPhoma = e.target.id
    //             item[ramoPhoma].phomaTotal = parseInt(valorPhoma);
    //             setPonto3(item)
    //             break;
    //         case 'fruto3':
    //             let itemFruto = ponto3.map(elem => elem);
    //             let valorFruto = e.target.value
    //             let ramoFruto = e.target.id
    //             itemFruto[ramoFruto].phomaFruto = parseInt(valorFruto);
    //             setPonto3(itemFruto)
    //             break;
    //         case 'presenca3':
    //             let checkPresenca = ponto3.map(elem => elem);
    //             checkPresenca.map(elem => elem.avaliacao = +e.target.value)
    //             setPonto3(checkPresenca);
    //             break;
    //         default:
    //             break;
    //     }
    // }

    const handleChangeThirdPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto3(prevPonto3 => {
            const newPonto3 = [...prevPonto3]; // Cria copia de ponto3
            switch (name) {
                case 'folha':
                    newPonto3[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto3[index][id].perfuradosRamo = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto3[index][id].sementesDanificadas = parseInt(value);
                    break;
                case 'presenca3':
                    newPonto3[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto3; // Retorna atualizado a copia do ponto3
        });
    };

    // const handleChangeFourthPoint = (e) => {
    //     dispatch({type: 'set_alert_modal_phoma', payload: 1})
    //     switch (e.target.name) {
    //         case 'folha':
    //             let itens = ponto4.map(elem => elem);
    //             let valor = e.target.value
    //             let ramo = e.target.id
    //             itens[ramo].folhaTotal = valor ? parseInt(valor) : null;
    //             setPonto4(itens)
    //             break;
    //         case 'phoma':
    //             let item = ponto4.map(elem => elem);
    //             let valorPhoma = e.target.value
    //             let ramoPhoma = e.target.id
    //             item[ramoPhoma].phomaTotal = parseInt(valorPhoma);
    //             setPonto4(item)
    //             break;
    //         case 'fruto4':
    //             let itemFruto = ponto4.map(elem => elem);
    //             let valorFruto = e.target.value
    //             let ramoFruto = e.target.id
    //             itemFruto[ramoFruto].phomaFruto = parseInt(valorFruto);
    //             setPonto4(itemFruto)
    //             break;
    //         case 'presenca4':
    //             let checkPresenca = ponto4.map(elem => elem);
    //             checkPresenca.map(elem => elem.avaliacao = +e.target.value)
    //             setPonto4(checkPresenca);
    //             break;

    //         default:
    //             break;
    //     }
    // }

    const handleChangeFourthPoint = (e, index) => {
        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });

        const { name, value, id } = e.target;
    
        setPonto4(prevPonto4 => {
            const newPonto4 = [...prevPonto4]; // Cria copia de ponto4
            switch (name) {
                case 'folha':
                    newPonto4[index][id].folhaTotal = value ? parseInt(value) : null;
                    break;
                case 'ativas':
                    newPonto4[index][id].perfuradosRamo = value ? parseInt(value) : null;
                    break;
                case 'inativas':
                    newPonto4[index][id].sementesDanificadas = parseInt(value);
                    break;
                case 'presenca4':
                    newPonto4[index].forEach(elem => elem.avaliacao = +value);
                    break;
                default:
                    break;
            }
    
            return newPonto4; // Retorna atualizado a copia do ponto4
        });
    };

    // function firstPoint(index) {
    //     return ponto1.map(elem => (
    //         elem.ramo >= 0 ?
    //             <>
    //                 <Tbody>
    //                     <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 1}</td>
    //                     <td style={{textAlign: 'center'}}>
    //                         <NumberFormat
    //                             value={elem.folhaTotal}
    //                             id={elem.ramo}
    //                             name={'folha'}
    //                             onChange={handleChangeFirstPoint}
    //                             type='text'
    //                             autoComplete="off"
    //                             onFocus={(e) => e.target.select()}
    //                             className="form-control"
    //                             fixedDecimalScale={true}
    //                             decimalScale={0}
    //                             style={{
    //                                 border: 'none',
    //                                 outline: 'none',
    //                                 textAlign: 'center',
    //                                 width: '95%',
    //                                 minWidth: '50px',
    //                                 marginLeft: '3px',
    //                                 marginTop: '3px',
    //                             }}
    //                             allowNegative={false}
    //                             isAllowed={(values) => {
    //                                 const {floatValue, formattedValue} = values;
    //                                 return formattedValue === "" || floatValue <= 99999999;
    //                             }}
    //                             required={requiredPonto1}
    //                             disabled={id_role === 1}
    //                         />
    //                     </td>
    //                     <td style={{textAlign: 'center'}}>
    //                         <NumberFormat
    //                             value={elem.phomaTotal}
    //                             id={elem.ramo}
    //                             name={'phoma'}
    //                             onChange={handleChangeFirstPoint}
    //                             type='text'
    //                             autoComplete="off"
    //                             onFocus={(e) => e.target.select()}
    //                             className="form-control"
    //                             fixedDecimalScale={true}
    //                             decimalScale={0}
    //                             style={{
    //                                 border: 'none',
    //                                 outline: 'none',
    //                                 width: '95%',
    //                                 minWidth: '50px',
    //                                 textAlign: 'center',
    //                                 marginLeft: '3px',
    //                                 marginTop: '3px'
    //                             }}
    //                             allowNegative={false}
    //                             isAllowed={(values) => {
    //                                 const {floatValue, formattedValue} = values;
    //                                 return formattedValue === "" || floatValue <= 99999999;
    //                             }}
    //                             disabled={id_role === 1}
    //                         />
    //                     </td>
    //                     {index === 3 || index === 4 ?
    //                         <>
    //                             <td style={{textAlign: 'center'}}>
    //                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //                                     <div>
    //                                         <label className='ml-1' for='phomaFrutoSimPonto1' style={{ marginRight: '0.5rem'}}>Sim</label>
    //                                         <input
    //                                             type='checkbox'
    //                                             id={elem.ramo}
    //                                             label= 'Sim'
    //                                             value='1'
    //                                             name='fruto'
    //                                             onChange={handleChangeFirstPoint}
    //                                             checked={ponto1.map(value => value.phomaFruto === 1 ? true : false)[elem.ramo]}
    //                                             disabled={id_role === 1}
    //                                         />
    //                                     </div>
    //                                     <div>
    //                                         <label className='ml-1' for='phomaFrutoNaoPonto1' style={{ marginRight: '0.4rem',  }}>Não</label>
    //                                         <input
    //                                         type='checkbox'
    //                                         id={elem.ramo}
    //                                         value='0'
    //                                         name='fruto'
    //                                         onChange={handleChangeFirstPoint}
    //                                         checked={ponto1.map(value => value.phomaFruto === 0 ? true : false)[elem.ramo]}
    //                                         disabled={id_role === 1}
    //                                     />
    //                                     </div> 
    //                                 </div>
    //                             </td>
    //                         </>
    //                         : null}

    //                 </Tbody>
    //             </> : null
    //     ))
    // }

    function firstPoint(index) {
        return ponto1[index].map(elem => (
            elem.ramo >= 0 ?
                <>
                    <Tbody>
                        <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 1}</td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.folhaTotal}
                                id={elem.ramo}
                                name={'folha'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    textAlign: 'center',
                                    width: '95%',
                                    minWidth: '50px',
                                    marginLeft: '3px',
                                    marginTop: '3px',
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                required={requiredPonto1}
                                disabled={id_role === 1}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.perfuradosRamo}
                                id={elem.ramo}
                                name={'ativas'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.sementesDanificadas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                    </Tbody>
                </> : null
        ))
    }

    function secondPoint(index) {
        return ponto2[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 11}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeSecondPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto2}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.perfuradosRamo}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeSecondPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.sementesDanificadas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    function thirdPoint(index) {
        return ponto3[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'inherit', textAlign: 'center'}}>{(elem.ramo) + 21}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeThirdPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto3}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.perfuradosRamo}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeThirdPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.sementesDanificadas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    function fourthPoint(index) {
        return ponto4[index].map(elem => (
            <>
                <Tbody>
                    <td style={{verticalAlign: 'middle', textAlign: 'center'}}>{(elem.ramo) + 31}</td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.folhaTotal}
                            type='text'
                            id={elem.ramo}
                            name={'folha'}
                            onChange={(e) => handleChangeFourthPoint(e, index)}
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            required={requiredPonto4}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                        <NumberFormat
                            value={elem.perfuradosRamo}
                            id={elem.ramo}
                            name={'ativas'}
                            onChange={(e) => handleChangeFourthPoint(e, index)}
                            type='text'
                            autoComplete="off"
                            onFocus={(e) => e.target.select()}
                            className="form-control"
                            fixedDecimalScale={true}
                            decimalScale={0}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '95%',
                                textAlign: 'center',
                                minWidth: '50px',
                                marginLeft: '3px',
                                marginTop: '3px',
                            }}
                            allowNegative={false}
                            isAllowed={(values) => {
                                const {floatValue, formattedValue} = values;
                                return formattedValue === "" || floatValue <= 99999999;
                            }}
                            disabled={id_role === 1}
                        />
                    </td>
                    <td style={{textAlign: 'center'}}>
                            <NumberFormat
                                value={elem.sementesDanificadas}
                                id={elem.ramo}
                                name={'inativas'}
                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                type='text'
                                autoComplete="off"
                                onFocus={(e) => e.target.select()}
                                className="form-control"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '95%',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    marginLeft: '3px',
                                    marginTop: '3px'
                                }}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const {floatValue, formattedValue} = values;
                                    return formattedValue === "" || floatValue <= 99999999;
                                }}
                                disabled={id_role === 1}
                            />
                        </td>
                </Tbody>
            </>
        ))
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     setLoading(true)

    //     const data = {
    //         ponto1,
    //         ponto2,
    //         ponto3,
    //         ponto4,
    //         area_id: areas[0].id,
    //         typeArea: typeArea,
    //         avaliation: avaliation,
    //         dataPhome: dataPhome
    //     }

    //     await post_phoma(data)
    //         .then(resp => {
    //             setLoading(false)
    //             setSuccess(resp.data.success);
    //             dispatch({type: 'set_alert_modal_phoma', payload: 0})
    //             dispatch({type: 'set_alert_modal_cercospora', payload: 0})
    //             setTimeout(() => {
    //                 setSuccess('');
    //                 getPhomas(avaliation)
    //             }, 3500)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //             setError(err.response.data.error);
    //             setTimeout(() => {
    //                 setError('');
    //             }, 3500)
    //         })
    // }

    const handleSubmit = async (e, index) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            ponto1: ponto1[index],
            ponto2: ponto2[index],
            ponto3: ponto3[index],
            ponto4: ponto4[index],
            area_id: areas[0].id,
            typeArea: typeArea,
            avaliation: index + 1,
            dataPhome: dataPhome[index],
            assentmentModule: assentmentModule
        }

        await post_broca(data)
            .then(resp => {
                setLoading(false)
                setSuccess(resp.data.success);
                dispatch({type: 'set_alert_modal_phoma', payload: 0})
                dispatch({type: 'set_alert_modal_cercospora', payload: 0})
                setTimeout(() => {
                    setSuccess('');
                    // getPhomas(avaliation)
                }, 3500)
            })
            .catch(err => {
                setLoading(false)
                setError(err.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 3500)
            })
    }

    // Validação para icone de checked se todos (folhaTotal) estão preenchidos.
    const validatePoints = (index) => {
        let isValid = true;
      
        for (let i = 0; i <= 9; i++) {
          if (
            ponto1[index][i].folhaTotal === null ||
            ponto2[index][i].folhaTotal === null ||
            ponto3[index][i].folhaTotal === null ||
            ponto4[index][i].folhaTotal === null
          ) {
            isValid = false;
            break;
          }
        }
      
        return isValid ? (
          <FaCheck
            style={{
              marginRight: '8px',
              color: '#4ED941',
              marginTop: '1rem',
              fontSize: '24px',
            }}
          ></FaCheck>
        ) : (
          ''
        );
      };

    //CONSTANTES REFERENTE AO CARD
    const [expandedIndices, setExpandedIndices] = useState([]);

    const toggleCard = (index) => {
        if (expandedIndices.includes(index)) {
          setExpandedIndices(expandedIndices.filter((item) => item !== index));
        } else {
          setExpandedIndices([...expandedIndices, index]);
        }
      };

    //CSS CARDS
    const cardStyle = {
        marginBottom: '30px' 
    };
    
    const iconCardStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        fontSize: '24px',
        fontWeight: 600,
    };
    
    const titleStyle = {
        fontSize: '24px',
        fontWeight: 600,
        padding: '15px',
        alignItems: 'center',
        justifyContent: 'flex-start'
    };

    function createTitleStyle(background, color, fontSize='1rem') {
        return {
            background: background,
            textAlign: 'center',
            fontSize: fontSize,
            verticalAlign: 'middle',
            fontWeight: 'bold',
            color: color
        };
    }
    const titlePonto = createTitleStyle('none', 'black', '1.2rem');
    const titleRamo = createTitleStyle('none', 'black');
    const titleTotal = createTitleStyle('#337840', 'white');
    const titleMinasAtivas = createTitleStyle('#C73939', 'white', '0.9rem');
    const titleMinasInativas = createTitleStyle('#7FAA52', 'white', '0.9rem');

    return (
        <div className="wrapper">
            <Header/>
            <Sidebar/>
            <StylePage title={typeArea === 1 ? 'Avaliação Broca Manejo Syngenta' : 'Avaliação Broca Manejo Produtor'}>
                {alert ? (
                    <div>
                        <Alert type="danger" hidden={true}>{alert}</Alert>
                    </div>
                ) : ("")}
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100}/>
                    </Content>

                ) : ('')}
                {success ? (
                    <div>
                        <Alert type="success" hidden={true}>{success}</Alert>
                    </div>
                ) : ("")}
                {error ? (
                    <div>
                        <Alert type="danger" hidden={true}>{error}</Alert>
                    </div>
                ) : ("")}
                <div style={{display: loading === true || alert !== false ? ('none') : ('block'), background: '#f8f9fa'}}>
                <div style={{background: '#f8f9fa'}}>
                    {[...Array(7)].map((_, index) => (
                        <div className="card" style={cardStyle} key={index}>
                            <div className="card-header d-flex justify-content-between">
                                <div className="align-items-center justify-content-start">
                                    <h3 className="card-title" style={titleStyle}>Avaliação {index + 1}</h3>
                                    {validatePoints(index)}
                                </div>
                                <div className="icon-container align-items-center ml-auto" style={iconCardStyle} onClick={() => toggleCard(index)}>
                                    {expandedIndices.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
                                </div>
                            </div>
                            {expandedIndices.includes(index) && (
                                <div className="card-content">
                                    <form onSubmit={handleSubmit} autocomplete="off">
                                        <div className="row" style={{marginBottom: '0.5rem'}}>
                                            {/* <div className="col-md-2">
                                                <label style={{margin: '1rem'}}>Avaliação</label>
                                                <Select
                                                    style={{marginLeft: '1rem', textAlign: 'center'}}
                                                    fullWidth
                                                    name="avaliation"
                                                    required={true}
                                                    value={avaliation}
                                                    ref={selectAvaliationRef}
                                                    label="Avaliação"
                                                    options={optionsAvaliation}
                                                    onChange={handleChange}
                                                    disabled={id_role === 1}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                </Select>

                                                {!avaliation &&
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: "100%",
                                                            height: 0,
                                                            position: "absolute"
                                                        }}
                                                        defaultValue={avaliation}
                                                        onFocus={() => selectAvaliationRef.current.focus()}
                                                        required={true}
                                                    />
                                                }
                                            </div> */}
                                            <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                                <label style={{ marginRight: '1.1rem', marginLeft: '2.3rem', marginTop: '1rem', fontSize: '1.2rem' }}>Data:</label>
                                                <Input
                                                    style={{ marginTop: '0.5rem', fontSize: '1.1rem' }}
                                                    icon="fas fa-calendar"
                                                    label="Data Admissão"
                                                    name={`dataPhome_${index}`}
                                                    required={true}
                                                    type="date"
                                                    placeholder=""
                                                    value={dataPhome[index]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        const newArray = [...dataPhome]
                                                        newArray[index] = newValue
                                                        setDataPhome(newArray)
                                                
                                                        dispatch({ type: 'set_alert_modal_phoma', payload: 1 });
                                                    }}
                                                    disabled={id_role === 1}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                        <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 1</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                        <tr>
                                                            <th colspan="4" style={titlePonto}>Ponto 1</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={titleRamo} rowspan="2">Ramo</th>
                                                            <th colspan="3" style={titleTotal}>Frutos</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={titleTotal}>Total</th>
                                                            <th style={titleMinasAtivas}>Perfurados no Ramo</th>
                                                            <th style={titleMinasInativas}>Sementes danificadas</th>
                                                        </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}

                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */} 
                                                    </Thead>

                                                    {firstPoint(index)}
                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`presenca1${index}`}
                                                                                value='1'
                                                                                name='presenca1'
                                                                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                                                                checked={ponto1[index].map(value => value.avaliacao == 1 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2' for={`presenca1${index}`}>Presença</label>
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`ausencia1${index}`}
                                                                                value='0'
                                                                                name='presenca1'
                                                                                onChange={(e) => handleChangeFirstPoint(e, index)}
                                                                                checked={ponto1[index].map(value => value.avaliacao == 0 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`ausencia1${index}`}>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Segundo Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 2</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 2</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Frutos</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Perfurados no Ramo</th>
                                                        <th style={titleMinasInativas}>Sementes danificadas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {secondPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`presenca2${index}`}
                                                                                value="1"
                                                                                name='presenca2'
                                                                                checked={ponto2[index].map(value => value.avaliacao == 1 ? true : false)[0]}
                                                                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`presenca2${index}`}>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input type='radio'
                                                                                id={`ausencia2${index}`}
                                                                                value="0"
                                                                                name='presenca2'
                                                                                onChange={(e) => handleChangeSecondPoint(e, index)}
                                                                                checked={ponto2[index].map(value => value.avaliacao == 0 ? true : false)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`ausencia2${index}`}>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Terceito Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>
                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 3</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 3</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Frutos</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Perfurados no Ramo</th>
                                                        <th style={titleMinasInativas}>Sementes danificadas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {thirdPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`presenca3${index}`}
                                                                                value="1"
                                                                                name='presenca3'
                                                                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                                                                checked={ponto3[index].map(value => value.avaliacao == 1)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`presenca3${index}`}>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`ausencia3${index}`}
                                                                                value="0"
                                                                                name='presenca3'
                                                                                onChange={(e) => handleChangeThirdPoint(e, index)}
                                                                                checked={ponto3[index].map(value => value.avaliacao == 0)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`ausencia3${index}`}>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>

                                            {/* Quarto Ponto */}
                                            <div className={`col-sm-12 col-md-6 ${larguraDaTela < 1750 ? 'col-lg-6' : 'col-lg-3'}`}>                                                <Table
                                                    cardHeader={'noHeader'}
                                                    // component={
                                                    //     <div className='row justify-content-center'>
                                                    //         <p style={titlePonto}>Ponto 4</p>
                                                    //     </div>
                                                    // }
                                                    cabecalho={
                                                        <Cabecalho cabecalho={true}>
                                                        </Cabecalho>
                                                    }>
                                                    <tr>
                                                        <th colspan="4" style={titlePonto}>Ponto 4</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleRamo} rowspan="2">Ramo</th>
                                                        <th colspan="3" style={titleTotal}>Frutos</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Perfurados no Ramo</th>
                                                        <th style={titleMinasInativas}>Sementes danificadas</th>
                                                    </tr>
                                                    <Thead>
                                                        {/* <th style={titleRamo}>Ramo</th>
                                                        <th style={titleTotal}>Total</th>
                                                        <th style={titleMinasAtivas}>Com minas ativas</th>
                                                        <th style={titleMinasInativas}>Com minas inativas</th> */}
                                                        {/* {(index + 1) === 3 || (index + 1) === 4 ?
                                                            <>
                                                                <th style={{background: '#74d175'}}>Presença de Phoma no Fruto</th>
                                                            </>
                                                            : null} */}
                                                    </Thead>

                                                    {fourthPoint(index)}

                                                    <Tfoot
                                                        estiloBackground='#D8E5F0'
                                                        component={
                                                            <>
                                                                <td style={{fontWeight: 'bold'}}>Avaliação</td>
                                                                <td colSpan={3}>
                                                                    <div className='row justify-content-around'>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`presenca4${index}`}
                                                                                value="1"
                                                                                name='presenca4'
                                                                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                                                                checked={ponto4[index].map(value => value.avaliacao == 1)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`presenca4${index}`}>Presença</label>

                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type='radio'
                                                                                id={`ausencia4${index}`}
                                                                                value="0"
                                                                                name='presenca4'
                                                                                onChange={(e) => handleChangeFourthPoint(e, index)}
                                                                                checked={ponto4[index].map(value => value.avaliacao == 0)[0]}
                                                                                disabled={id_role === 1}
                                                                            />
                                                                            <label className='ml-2'
                                                                                for={`ausencia4${index}`}>Ausência</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        }>
                                                    </Tfoot>
                                                </Table>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-12 text-right mb-2 mr-3">
                                                <div className="text-right mb-2 mr-3">
                                                    {/* <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>{}}>Voltar</button>                            */}
                                                    {id_role === 2 && 
                                                        (
                                                        // <button type="submit" className="btn btn-primary">Salvar</button>
                                                        <button className="btn btn-primary" onClick={(e)=>{handleSubmit(e, index)}}>Salvar</button>
                                                        )
                                                    }
                                                    {id_role === 1 && (<Link to={`/phomas/${props.match.params.typeArea}`}
                                                                            className="btn btn-primary">Retornar</Link>)}
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                </div>

            </StylePage>
            <Footer/>
        </div>
    )
}

export default (GridBroca)
