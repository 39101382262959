export const harvests = [
    {label: 'Safra 19/20', value: 'Safra 19/20'},
    {label: 'Safra 20/21', value: 'Safra 20/21'}
]

export const applications = [
    {label: '1ª', value: '1ª'},
    {label: '2ª', value: '2ª'},
    {label: '3ª', value: '3ª'},
    {label: '4ª', value: '4ª'},
    {label: '5ª', value: '5ª'},
    {label: '6ª', value: '6ª'},
    {label: '7ª', value: '7ª'},
    {label: '8ª', value: '8ª'},
    {label: '9ª', value: '9ª'},
    {label: '10ª', value: '10ª'},
    {label: '11ª', value: '11ª'},
    {label: '12ª', value: '12ª'},
    {label: '13ª', value: '13ª'},

]
