import {Link} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

export default function TOrder(props) {
    const {columnName, orderColumn, asc, onOrder} = props;
    return (
        <Link to="#/" style={{float: 'right', color: orderColumn === columnName ? null : "lightgray" }} onClick={() => onOrder(columnName)}>
            <i className={`right fas fa-sort-amount-${orderColumn === columnName ? (asc ? ('down') : ('up')) : ('down')}-alt`}></i></Link>
    )
}
TOrder.propTypes = {
    columnName: PropTypes.string,
    orderColumn: PropTypes.string,
    asc: PropTypes.bool,
    onOrder: PropTypes.func
}