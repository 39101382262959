import React from 'react'
import {Link} from 'react-router-dom'

export default function Consultants({consultants, onDelete}) {
    const consultores = consultants || []
    return consultores.map(desc=>(
        <tr key={desc.id}>
            <td>{desc.cpf}</td>
            <td>{desc.name}</td>
            <td>{desc.email}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#/">
                            <i className="fas fa-ellipsis-h" />             
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>                  
                            <Link to={`/consultor/${desc.id_user}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                            {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                            <Link to={`/consultor/${desc.id_user}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                            <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                        </div>
                    </li>

                </ul>		
            </td>
            
        </tr>
    ))
}
