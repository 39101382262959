import React from 'react'
import { Link } from 'react-router-dom'

export default function Productivitys({ produtividades, onDelete, typeArea, id_role }) {
    const productivitys = produtividades || []
    return productivitys.map(desc=>(
        <tr key={desc.id}>
            {id_role === 1 && (<td>{desc.consultant}</td>)}
            <td>{desc.doenca}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#/">
                            <i className="fas fa-ellipsis-h" />             
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>                  
                            <Link to={`/produtividade/${typeArea}/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                            {id_role === 1 ? "" :(<Link to={`/produtividade/${typeArea}/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>)}
                            <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                        </div>
                    </li>
                </ul>		
            </td>                
        </tr>
    ))
    
}
