import {getPhomas} from "../../../../Services/phoma";

import {get_avaliacao, get_pagination} from "../../../../Services/broca";

//Função que traz os dados do banco de dados
export const get = (page) => {
    return [(dispatch, getState) => {

        if (typeof page === "undefined") {
            page = getState().phoma.phomasList.current_page
        }
        const order = getState().phoma.order
        const th = getState().phoma.th
        const qtd_per_page = getState().phoma.qtd_per_page
        const pesquisa = getState().phoma.pesquisa || ''
        const typeArea = getState().phoma.type_area || ''

        let search = '';

        if(pesquisa.includes('-')){
            search = pesquisa.replace(/-/g , "/");
        }
        if(pesquisa.includes('/')){
            search = pesquisa.replace(/\//g , "-");
        }

        getPhomas(page, qtd_per_page, typeArea, order, th, search)
            .then(resp => {
                dispatch({type: 'PhomasList', payload: resp.data})
            })
    }]
}

export const getBrocaAvaliacao = (page) => {
    
    return [(dispatch, getState) => {   
        if (typeof page === "undefined") {
            page = getState().broca.current_page
        }
        
        const order = getState().broca.order
        const th = getState().broca.th
        const qtd_per_page = getState().broca.qtd_per_page
        const pesquisa = getState().broca.pesquisa || ''
        const typeArea = getState().broca.type_area || ''
        
       
        // let search = '';

        // if(pesquisa.includes('-')){
        //     search = pesquisa.replace(/-/g , "/");
        // }
        // if(pesquisa.includes('/')){
        //     search = pesquisa.replace(/\//g , "-");
        // }
        
        get_avaliacao(page, qtd_per_page, typeArea, pesquisa)
            .then((resp) => {
                
                dispatch({type: 'BrocaList', payload: resp.data.avaliations});
            });

    }]    
}

export const getPagination = (page) => {
    return [(dispatch, getState) => {   
        if (typeof page === "undefined") {
            page = getState().broca.current_page
        }
        
        const qtd_per_page = getState().broca.qtd_per_page
        const pesquisa = getState().broca.pesquisa || ''
        const typeArea = getState().broca.type_area || ''

        // let search = '';

        // if(pesquisa.includes('-')){
        //     search = pesquisa.replace(/-/g , "/");
        // }
        // if(pesquisa.includes('/')){
        //     search = pesquisa.replace(/\//g , "-");
        // }

        get_pagination(page, qtd_per_page, typeArea, pesquisa).then(resp => {
            
            dispatch({type: 'BrocaList_to', payload: resp.data.to});
            dispatch({type: 'qtd_per_page_Broca', payload: resp.data.per_page});
            dispatch({type: 'BrocaList_current_page', payload: resp.data.current_page});
            dispatch({type: 'BrocaList_last_page', payload: resp.data.last_page});
            dispatch({type: 'BrocaList_from', payload: resp.data.from});
            dispatch({type: 'Broca_total', payload: resp.data.total});
        });
    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch, getState) => {
        const asc = getState().phoma.asc

        if (asc === true) {
            dispatch({type: 'GetPhomas_Desc', desc: true, asc: false, order: 'desc', th: coluna})

        }
        if (asc === false) {
            dispatch({type: 'GetPhomas_Asc', asc: true, desc: false, order: 'asc', th: coluna})

        }

    }, get(1)]
}

export const change_qtd_per_page = event => {
    return [{
        type: 'qtd_per_page_Phomas',
        payload: event.target.value
    }, get(1)]
}

export const changePesquisa = event => {
    return [{
        type: 'pesquisaPhomas',
        payload: event.target.value
    }]
}

export const clearPesquisa = event => {
    return [{
        type: 'clearPesquisaPhomas',
        payload: ''
    }, get(1)]
}