import React, { useEffect, useState, Component } from "react";
import NavItem from "./nav-item";
import NavItemDropdown from "./nav-item-dropdown";
import { connect } from "react-redux";
import IconPoda from "../Icones/openmoji_scissors.png";
import IconHome from "../Icones/dashboard.png";
import IconExit from "../Icones/Vector.png";
import IconNotifications from "../Icones/notifications.png";
import IconConfiguration from "../Icones/settings.png";
import IconPhoma from "../Icones/message.png";
import IconCompact from "../Icones/soil.png";
import IconProductivity from "../Icones/twemoji_p-button.png";
import IconCopy from "../Icones/ant-design_file-add-filled.png";
import IconCropProtection from "../Icones/ic_twotone-agriculture.png";
import IconSoilFertilizer from "../Icones/message.png";
import IconPrecipitation from "../Icones/carbon_accumulation-precipitation.png";
import IconSoilTexture from "../Icones/SoilTexture.png";
import IconArea from "../Icones/Talhao.png";
import IconWork from "../Icones/work.png";
import IconPowerBi from "../Icones/power-bi.png";
import IconCercospora from "../Icones/cercospora.png";
import { get_module, post_module } from "../Services/moduloAvaliacao";
import { useSelector } from "react-redux";

function Menu(props) {
  const id_role = useSelector((state) => state.auth.user.id_role);
  const [assentmentModule, setAssentmentModule] = useState("");
  const [ bichoMineiroEBroca, setBichoMineiroEBroca ] = useState('Bicho Mineiro e Broca');

  useEffect(() => {
    getModuloAvaliacao();
  }, []);

  const getModuloAvaliacao = () => {
    get_module().then((resp) => {
      setAssentmentModule(resp.data.pests_id);
    });
  };

  return (
    <React.Fragment>
      <NavItem to="/index" item="Home" icon={IconHome} />
      <NavItemDropdown dropItem="Cadastros" icon={IconCopy}>
        {parseInt(props.id_role) === 1 ? (
          <React.Fragment>
            <NavItem
              item="Usuário"
              iconCircle="fa fa-user nav-icon"
              to="/register"
            />
            <NavItem
              item="Consultores"
              iconCircle="fa fa-user nav-icon"
              to="/consultores"
            />
            <NavItem
              item="Tópicos"
              iconCircle="fa fa-user nav-icon"
              to="/topicos"
            />
            <NavItem item="Módulo de Avaliações" iconCircle="" to="/modulo" />
          </React.Fragment>
        ) : (
          ""
        )}
        <NavItem
          item="Perfil"
          iconCircle="fa fa-user nav-icon"
          to={`/consultor/${props.id_user}/edit`}
        />
        <NavItem
          item="Produtor"
          iconCircle="fa fa-user nav-icon"
          to="/produtores"
        />
        {/* <NavItem item="Talhão" iconCircle="fa fas fa-campground nav-icon" to="/talhoes"/> */}
      </NavItemDropdown>
      <NavItemDropdown dropItem="Talhão" icon={IconArea}>
        <NavItem item="Cadastro Talhão" to={"/talhoes/"} />
      </NavItemDropdown>
      {assentmentModule === 1 ? (
                <NavItemDropdown dropItem="Avaliação Phoma" icon={IconPhoma}>
                    <NavItem assentmentModule={assentmentModule} item="Manejo Syngenta" to={'/phomas/manejo_syngenta'}/>
                    <NavItem assentmentModule={assentmentModule} item="Manejo Produtor" to={'/phomas/manejo_produtor'}/>
                </NavItemDropdown>
            ) : (
                <NavItemDropdown dropItem="Avaliação Bicho Mineiro" icon={IconPhoma}>
                    <NavItem assentmentModule={assentmentModule} item="Manejo Syngenta" to={'/bicho_mineiro/manejo_syngenta'}/>
                    <NavItem assentmentModule={assentmentModule} item="Manejo Produtor" to={'/bicho_mineiro/manejo_produtor'}/>
                </NavItemDropdown>
            )
            }
      {/* <NavItemDropdown dropItem="Avaliação Phoma" icon={IconPhoma}>
        <NavItem item="Manejo Syngenta" to={"/phomas/manejo_syngenta"} />
        <NavItem item="Manejo Produtor" to={"/phomas/manejo_produtor"} />
      </NavItemDropdown> */}
      {assentmentModule === 1 ?
        <NavItemDropdown dropItem="Avaliação Cercospora" icon={IconPhoma}>
          <NavItem assentmentModule={assentmentModule} item="Manejo Syngenta" to={"/cercosporas/manejo_syngenta"} />
          <NavItem assentmentModule={assentmentModule} item="Manejo Produtor" to={"/cercosporas/manejo_produtor"} />
        </NavItemDropdown>
      : 
        <NavItemDropdown dropItem="Avaliação Broca" icon={IconPhoma}>
          <NavItem assentmentModule={assentmentModule} item="Manejo Syngenta" to={'/broca/manejo_syngenta'}/>
          <NavItem assentmentModule={assentmentModule} item="Manejo Produtor" to={'/broca/manejo_produtor'}/>
        </NavItemDropdown>
      }
      {/* <NavItem to="/solos" item="Análise de Solo" icon={IconSoil} /> */}
      {/* <NavItem to="/podas" item="Poda" icon={IconPoda} /> */}
      {/* <NavItemDropdown dropItem="Poda" icon={IconPoda}>   
                <NavItem item="Alta Produtividade"  to={'/podas/alta_produtividade'}/>
                <NavItem item="Baixa Produtividade"  to={'/podas/baixa_produtividade'}/>
            </NavItemDropdown>
            <NavItemDropdown dropItem="Produtividade" icon={IconProductivity}>   
                <NavItem item="Alta Produtividade"  to={'/produtividades/alta_produtividade'}/>
                <NavItem item="Baixa Produtividade"  to={'/produtividades/baixa_produtividade'}/>
            </NavItemDropdown> */}
      {/* <NavItem to="/produtividades" item="Produtividade" icon={IconProductivity} /> */}
      {/* <NavItemDropdown dropItem="Manejo de Solo" icon={IconSoilFertilizer}>
                <NavItemDropdown dropItem="Adubação">   
                    <NavItem item="Alta Produtividade"  to={'/adubacoes/alta_produtividade'}/>
                    <NavItem item="Baixa Produtividade"  to={'/adubacoes/baixa_produtividade'}/>
                </NavItemDropdown>
                <NavItemDropdown dropItem="Correção">   
                    <NavItem item="Alta Produtividade"  to={'/correcoes/alta_produtividade'}/>
                    <NavItem item="Baixa Produtividade"  to={'/correcoes/baixa_produtividade'}/>
                </NavItemDropdown> */}
      {/* <NavItem item="Adubação"  to="/adubacoes" />
                <NavItem item="Correção"  to="/correcoes" /> */}

      {/* </NavItemDropdown> */}
      <NavItemDropdown dropItem="Proteção da Lavoura" icon={IconCropProtection}>
        <NavItem item="Manejo em Comum" to="/pulverizacoes/manejo_em_comum" />
        <NavItem item="Manejo Syngenta" to="/pulverizacoes/manejo_syngenta" />
        <NavItem item="Manejo Produtor" to="/pulverizacoes/manejo_produtor" />
        {/* <NavItemDropdown dropItem="Controle do Mato" to="#/">
                    <NavItem item="Alta Produtividade" to="/controles_matos/alta_produtividade"/>
                    <NavItem item="Baixa Produtividade" to="/controles_matos/baixa_produtividade"/>
                </NavItemDropdown>
                <NavItemDropdown dropItem="Pragas e Doenças Via Solo" to="#/">
                    <NavItem item="Alta Produtividade" to="/pragas_doencas_solos/alta_produtividade"/>
                    <NavItem item="Baixa Produtividade" to="/pragas_doencas_solos/baixa_produtividade"/>
                </NavItemDropdown>
                <NavItem item="Controle do Mato"  to="#/"/>
                <NavItem item="Controle de Pragas e Doenças"  to="#/" disabled="disabled"/> */}
      </NavItemDropdown>
      {id_role == 2 ? (
        assentmentModule == 1 ? (
          <>
            <NavItemDropdown dropItem="Produtividade" icon={IconProductivity}>
              <NavItem
                item="Manejo Syngenta"
                to="/produtividades/manejo_syngenta"
              />
              <NavItem
                item="Manejo Produtor"
                to="/produtividades/manejo_produtor"
              />
            </NavItemDropdown>
          </>
        ) : (
          <></>
        )
      ) : (
        <NavItemDropdown dropItem="Produtividade" icon={IconProductivity}>
          <NavItem
            item="Manejo Syngenta"
            to="/produtividades/manejo_syngenta"
          />
          <NavItem
            item="Manejo Produtor"
            to="/produtividades/manejo_produtor"
          />
        </NavItemDropdown>
      )}

      {/* <NavItemDropdown dropItem="Precipitação" icon={IconPrecipitation}>
                
                <NavItem item="Alta Produtividade"  to={`/${props.id_role === 1 ? 'precipitacoes/alta_produtividade' : 'precipitacao/alta_produtividade'}`}/>
                <NavItem item="Baixa Produtividade"  to={`/${props.id_role === 1 ? 'precipitacoes/baixa_produtividade' : 'precipitacao/baixa_produtividade'}`}/>
                
                
            </NavItemDropdown>
            <NavItemDropdown dropItem="Compactação" icon={IconCompact}>                
                <NavItem item="Alta Produtividade"  to={`/${props.id_role === 1 ? 'compactacoes/alta_produtividade' : 'compactacao/alta_produtividade'}`}/>
                <NavItem item="Baixa Produtividade"  to={`/${props.id_role === 1 ? 'compactacoes/baixa_produtividade' : 'compactacao/baixa_produtividade'}`}/>
            </NavItemDropdown> */}

      {/* <NavItemDropdown dropItem="Textura do Solo" icon={IconSoilTexture}>
                <NavItem item="Alta Produtividade"  to={`/${props.id_role === 1 ? 'soil_textures/alta_produtividade' : 'soil_texture/alta_produtividade'}`}/>
                <NavItem item="Baixa Produtividade"  to={`/${props.id_role === 1 ? 'soil_textures/baixa_produtividade' : 'soil_texture/baixa_produtividade'}`}/>
            </NavItemDropdown> */}

      {/* <div className="mt-3" style={{borderBottom: "1px solid #dee2e6"}}></div>
            <a className="nav-link"><p style={{fontSize: '10px', color: "#708090"}}>Configurações</p></a> */}
      {/* <NavItem to="/index" item="Principais Configurações" icon={IconConfiguration} />
            <NavItem to="/index" item="Notificações" icon={IconNotifications} /> */}
      {/* <NavItem to="/tasks" item="Tarefas" icon={IconConfiguration} /> */}

      {/* <NavItem to='/tasks' item='Tarefas' icon={IconWork} /> 
            <NavItem to='#' item='Notificações' icon={IconNotifications}/> */}

      {/* { props.id_role === 1 && <NavItem to='/powerbi' icon={IconPowerBi} item='PowerBi'/>} */}

      <NavItem
        item="Interação Online Ao Vivo"
        icon={IconCompact}
        to="/interacao_online_ao_vivo"
      />
      <NavItem to="/login" item="Sair" icon={IconExit} />

      {/* <NavItem to="#/" item="Precipitação" icon="fa fa-tree" />
            <NavItem to="#/" item="Compactação" icon="fa fa-feather" /> */}

      {/* <NavItemDropdown dropItem="Insumos" icon="fa fa-copy">
                <NavItem item="Adubação"  to="#/"/>
                <NavItem item="Corretivos"  to="#/" disabled="disabled"/>
                <NavItem item="Adubação Orgânica"  to="#/" disabled="disabled"/>
            </NavItemDropdown> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  id_perfil: state.auth.user.id_perfil,
  id_role: state.auth.user.id_role,
  id_user: state.auth.user.id_user,
});
export default connect(mapStateToProps)(Menu);
