/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Header from "../../../Header/header";
import Sidebar from "../../../Sidebar/sidebar";
import Form from "../../../Forms/form";
import Footer from "../../../Footer/footer";
import StylePage from "../../../Style Page/style_page";
import {
  getResource,
  postProductivity,
  putProductivity,
  viewPerId,
  get_produtividade,
} from "../../../Services/produtividade";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Input from "../../../Forms/input";
import Alert from "../../../Alerts/alert";
import ReactLoading from "react-loading";
import { Content } from "../../Auth/styleLoading";
import { ContentButton } from "../../../Button/style";
import { useSelector } from "react-redux";
import { get_module } from "../../../Services/moduloAvaliacao";

export default function Productivity(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [productivitysGet, setProductivitysGet] = useState([]);
  const consultor = "consultant.name";
  const type_area = props.match.params.typeArea === "manejo_syngenta" ? 1 : 2;
  const id_view_edit = props.match.params.id;
  const id_role = useSelector((state) => state.auth.user.id_role);
  const [totalProduction, setTotalProduction] = useState("");
  const [expectedReturn, setExpectedReturn] = useState("");
  const [numPlantsHarvested, setNumPlantsHarvested] = useState("");
  const [greenFruits, setGreenFruits] = useState("");
  const [cherryFruits, setCherryFruits] = useState("");
  const [dryFruits, setDryFruits] = useState("");
  const [pests, setPests] = useState([]);
  const [pestId, setPestId] = useState([]);
  const [module, setModule] = useState([]);

  const page = useSelector((state) => state.produtividade.page || 1);
  const qtd_per_page = useSelector((state) => state.produtividade.qtd_per_page);
  const th = useSelector((state) => state.produtividade.th);
  const order = useSelector((state) => state.produtividade.order);
  const pesquisa = "";

  const selectPestRef = useRef(null);

  let idRole = localStorage.getItem("id_role");
  useEffect(() => {
    getAvaliationModuleAccess();
  }, []);

  useEffect(() => {
    veriyQtdProdutividade();
  }, []);

  useEffect(() => {
    getResources(type_area);
    return () => {
      getResources(type_area);
    };
  }, [type_area]);

  useEffect(() => {
    if (props.match.params.action) {
      viewProductivity(id_view_edit);
      return () => {
        viewProductivity(id_view_edit);
      };
    }
  }, [id_view_edit]);

  /**
   * Record view function by ID and states updates
   * @param {int} id
   */
  function viewProductivity(id) {
    viewPerId(id).then((resp) => {
      setTotalProduction(resp.data.productivity[0].production);
      setExpectedReturn(resp.data.productivity[0].expected_return);
      setGreenFruits(resp.data.productivity[0].green_fruits);
      setCherryFruits(resp.data.productivity[0].cherry_fruits);
      setDryFruits(resp.data.productivity[0].dry_fruits);
      setNumPlantsHarvested(resp.data.productivity[0].num_plants_harvested);
      setPestId({
        label: resp.data.productivity[0].pest,
        value: resp.data.productivity[0].id_pest,
      });
    });
  }



  function getAvaliationModuleAccess() {
    get_module()
      .then((resp) => {
        if(resp.data.pests_id == 2 && idRole == 2)
          return (window.location.href = "/index");
      })
  }

  /**
   * Dependency search function
   * @param {int} type_area
   */
  function getResources(type_area) {
    getResource(type_area).then((resp) => {
      setPests([
        { label: "-- SELECIONE --", value: "" },
        ...resp.data.type_doenca.map((desc) => ({
          label: desc.name,
          value: desc.id,
        })),
      ]);
    });
  }

  function veriyQtdProdutividade() {
    get_produtividade(page, qtd_per_page, type_area, order, th, pesquisa)
      .then((resp) => {
        if (id_role === 2 && !props.match.params.action) {
          if (resp.data.total >= 1) {
            setAlert("Limite de produtividades no máximo");
            setTimeout(() => {
              props.history.push(
                `/produtividades/${props.match.params.typeArea}`
              );
            }, 2000);
          }
        }
      })
  }

  const handleForm = (e) => {
    e.preventDefault();
    setLoading(true);
    const object = {
      id_type_area: type_area,
      id_type_pest: pestId.value,
      production: totalProduction,
      num_plants_harvested: numPlantsHarvested,
      expected_return: expectedReturn,
      cherry_fruits: cherryFruits,
      green_fruits: greenFruits,
      dry_fruits: dryFruits,
    };

    if (props.match.params.action === "edit") {
      putProductivity(props.match.params.id, object)
        .then((resp) => {
          setLoading(false);
          setError("");
          setSuccess(resp.data.success);
        })
        .then((resp) =>
          setTimeout(() => {
            window.location.reload();
          }, 2500)
        )
        .catch((e) => {
          setLoading(false);
          setSuccess("");
          setError(e.response.data.error);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    } else {
      postProductivity(object)
        .then((resp) => {
          setLoading(false);
          setError("");
          setSuccess(resp.data.success);
        })
        .then((resp) =>
          setTimeout(() => {
            props.history.push(
              `/produtividades/${props.match.params.typeArea}`
            );
          }, 2500)
        )
        .catch((e) => {
          setLoading(false);
          setSuccess("");
          setError(e.response.data.error);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <StylePage
        title={
          props.match.params.action === "edit"
            ? `${
                type_area === 1
                  ? "Edição de Produtividade de Manejo Syngenta"
                  : "Edição de Produtividade de Manejo Produtor"
              }`
            : props.match.params.action === "view"
            ? `${
                type_area === 1
                  ? "Visualização de Produtividade de Manejo Syngenta"
                  : "Visualização de Produtividade de Manejo Produtor"
              }`
            : `${
                type_area === 1
                  ? "Cadastro de Produtividade de Manejo Syngenta"
                  : "Cadastro de Produtividade de Manejo Produtor"
              }`
        }
      >
        {alert && (
          <div>
            <Alert type="danger">{alert}</Alert>
          </div>
        )}
        {loading === true ? (
          <Content>
            <ReactLoading
              type="spinningBubbles"
              color="blue"
              height={200}
              width={100}
            />
          </Content>
        ) : (
          ""
        )}
        {success ? <Alert type="success">{success}</Alert> : ""}
        {error ? <Alert type="danger">{error}</Alert> : ""}

        <div style={{ display: loading === true ? "none" : "block" }}>
          <Form onSubmit={handleForm}>
            <div className="row mt-2">
              <div className="col-md-3">
                <label>Doença</label>
                <Select
                  value={pestId}
                  label="Single select"
                  options={pests}
                  onChange={(e) => setPestId(e)}
                  isDisabled={
                    props.match.params.action === "view" ? true : false
                  }
                  ref={selectPestRef}
                />
                {pestId.length <= 0 && (
                  <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{
                      opacity: 0,
                      width: "100%",
                      height: 0,
                      position: "absolute",
                    }}
                    defaultValue={pestId}
                    onFocus={() => selectPestRef.current.focus()}
                    required={true}
                  />
                )}
              </div>
              <div className="col-md-3">
                <NumberFormat
                  label="Produção (litros total)"
                  icon="far fa-window-minimize"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite a produção"
                  onChange={(e) =>
                    setTotalProduction(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={totalProduction}
                  required={true}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 9999999;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
              <div className="col-md-3">
                <NumberFormat
                  label="Número de plantas colhidas"
                  icon="fa fas fa-seedling"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite o número"
                  onChange={(e) =>
                    setNumPlantsHarvested(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={numPlantsHarvested}
                  required={true}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 9999999;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
              <div className="col-md-3">
                <NumberFormat
                  label="Rendimento esperado (litros para 1 saca)"
                  icon="far fa-window-minimize"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite o rendimento"
                  onChange={(e) =>
                    setExpectedReturn(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={expectedReturn}
                  required={true}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 9999999;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <NumberFormat
                  label="% de frutos verde"
                  icon="fa fas fa-spa"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite a % de frutos verde"
                  onChange={(e) =>
                    setGreenFruits(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={greenFruits}
                  required={false}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
              <div className="col-md-3">
                <NumberFormat
                  label="% de frutos cereja"
                  icon="fa fas fa-spa"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite a % de frutos cereja"
                  onChange={(e) =>
                    setCherryFruits(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={cherryFruits}
                  required={false}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
              <div className="col-md-3">
                <NumberFormat
                  label="% de frutos seco"
                  icon="fa fas fa-spa"
                  name="nome"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  customInput={Input}
                  placeholder="Digite a % de frutos seco"
                  onChange={(e) =>
                    setDryFruits(
                      parseFloat(
                        e.target.value
                          .replace(".", "")
                          .replace(".", "")
                          .replace(",", ".")
                      )
                    )
                  }
                  value={dryFruits}
                  required={false}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  disabled={props.match.params.action === "view" ? true : false}
                />
              </div>
            </div>
            <div className="text-right" style={{ marginTop: "50px" }}>
              <ContentButton>
                <button
                  style={{ marginRight: "10px" }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
                {props.match.params.action === "view" ? (
                  <Link
                    to={`/produtividade/${props.match.params.typeArea}/${props.match.params.id}/edit`}
                    style={{
                      marginRight: "10px",
                      cursor: id_role === 1 ? "not-allowed" : "",
                      color: id_role === 1 ? "#FFF" : "",
                      backgroundColor: id_role === 1 ? "#007BFF" : "",
                      borderColor: id_role === 1 ? "#007BFF" : "",
                      opacity: id_role === 1 ? 0.65 : "",
                      boxShadow: id_role === 1 ? "none" : "",
                    }}
                    onClick={id_role === 1 ? (e) => e.preventDefault() : ""}
                    className="btn btn-primary"
                  >
                    Editar
                  </Link>
                ) : (
                  <button
                    className="btn btn-primary"
                    disabled={
                      props.match.params.action === "view" ? true : false
                    }
                    type="submit"
                  >
                    Salvar
                  </button>
                )}
                {/* <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                            */}
              </ContentButton>
            </div>
          </Form>
        </div>
      </StylePage>
      <Footer />
    </div>
  );
}
