import React, { useState, useEffect, useRef } from 'react'
import Sidebar from '../../../Sidebar/sidebar'
import Header from '../../../Header/header'
import Footer from '../../../Footer/footer'
import Style from '../../../Style Page/style_page'
import Form from '../../../Forms/form'
import Input from '../../../Forms/input'
import ReactLoading from 'react-loading'
import { Content } from '../../Auth/styleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import {  put_area, post_area, get_view_area, get_area, getArea } from '../../../Services/talhao'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import Alert from '../../../Alerts/alert'
import { cidadesporUFAPI, getUFSAPI } from '../../../Services/endereco'
import { verify } from 'jsonwebtoken'

export default function Area(props) {
    let history = useHistory();
    const [ nameArea, setNameArea ] = useState('')
    const [ areas, setAreas ] = useState([])
    const [ producers, setProducers ] = useState([])
    const [ producerId, setProducerId ] = useState([])
    const [ farm, setFarm ] = useState('')
    const [ states, setStates ] = useState([])
    const [ stateId, setStateId ] = useState([])
    const [ citys, setCitys ] = useState([])
    const [ cityId, setCityId ] = useState([])
    const [ farmSize, setFarmSize ] = useState('')
    const [ areaSize, setAreaSize ] = useState('')
    const [ plantingDate, setPlantingDate ] = useState('')
    const [ lineSpacing, setLineSpacing ] = useState('')
    const [ plantsSpacing, setPlantsSpacing ] = useState('')
    const [ plants, setPlants ] = useState('')
    const [ varietys, setVarietys ] = useState([])
    const [ varietyId, setVarietyId ] = useState([])
    const [ maturations, setMaturations ] = useState([])
    const [ maturationId, setMaturationId ] = useState([])
    const [ irrigations, setIrrigations ] = useState([])
    const [ irrigationId, setIrrigationId ] = useState([])
    const [ cultivations, setCultivations ] = useState([])
    const [ cultivationId, setCultivationId ] = useState([])
    const [ height, setHeight ] = useState('')
    const [ length, setLength ] = useState('')
    const [ level, setLevel ] = useState('')
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ title, setTitle ] = useState('')   
    // const typeArea = props.match.params.typeArea === 'manejo_syngenta' ? 1 : 2
    const consultantId = useSelector(state => state.auth.user.id)
    const id_role = useSelector(state => state.auth.user.id_role)
    const page = useSelector(state => state.talhao.page || 1)
    const qtd_per_page = useSelector(state => state.talhao.qtd_per_page)
    const th = useSelector(state => state.talhao.th)
    const order = useSelector(state => state.talhao.order)
    const pesquisa = ''

    //Referências para Required de Selects
    const selectProducerRef = useRef(null)
    const selectStateRef = useRef(null)
    const selectCityRef = useRef(null)
    const selectVarietyRef = useRef(null)
    const selectMaturationRef = useRef(null)
    const selectIrrigationRef = useRef(null)
    const selectCultivationRef = useRef(null)

    useEffect(() => {
        veriyQtdArea()
    }, [])

    useEffect(() => {
        getProducers()
    }, [])

    useEffect(() => {
        getStates()
    }, [])

    useEffect(() => {
        calculePlants()
    }, [lineSpacing,plantsSpacing])

    useEffect(() => {
        getCitys()
    }, [stateId])

    useEffect(() => {
        onTitle()
    }, [props.match.params.action])

    useEffect(() => {
        viewPerId()
    }, [props.match.params.id])

    function getStates(){
        getUFSAPI()
        .then(resp => {
            setStates(resp.data.map(desc => ({
                label: desc.name,
                value: desc.uf
            })))
        })
    }

    function getCitys(){
        setCitys([])
        if(!props.match.params.action){
            setCityId('')
        }
        cidadesporUFAPI(stateId.value)
        .then(resp => {
            setCitys(resp.data.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
        })
    }

    function calculePlants(){
        setPlants(parseInt(10000/(parseFloat(lineSpacing) * parseFloat(plantsSpacing))))
    }

    function veriyQtdArea(){
        get_area(page,qtd_per_page,order,th,pesquisa)
        .then(
            resp=>{
                if(id_role === 2 && !props.match.params.action){
            
                    if(resp.data.total >= 1){
                        setAlert("Limite de talhões no máximo")
                        setTimeout(()=>{
                            props.history.push(`/talhoes/`)
                        },2000)
                        
                    }
                }

            }
        )
    }

    function viewPerId(){
        if(props.match.params.action === 'edit' || props.match.params.action === 'view'){
            get_view_area(props.match.params.id)
            .then(resp => {
                let calculePlants = 10000/(resp.data[0].line_spacing * resp.data[0].plants_spacing)
                setNameArea(resp.data[0].area_name) 
                setProducerId({
                    label: resp.data[0].name_producer,
                    value: resp.data[0].producer_id
                })  
                setFarm(resp.data[0].farm)
                setStateId({
                    label: resp.data[0].state_name,
                    value: resp.data[0].state
                })                
                setCityId({
                    label: resp.data[0].city,
                    value: resp.data[0].id_city
                })
                setFarmSize(resp.data[0].farm_size_ha)
                setAreaSize(resp.data[0].area_size_ha)
                setPlantingDate(resp.data[0].planting_date)
                setLineSpacing(resp.data[0].line_spacing)
                setPlantsSpacing(resp.data[0].plants_spacing)
                setHeight(resp.data[0].latitude)
                setLength(resp.data[0].longitude)
                setLevel(resp.data[0].altitude)
                setPlants(calculePlants)
                setCultivationId({
                    label: resp.data[0].name_cultivation,
                    value: resp.data[0].cultivation_id
                })
                setVarietyId({
                    label: resp.data[0].variety_name,
                    value: resp.data[0].variety_id
                })
                setMaturationId({
                    label: resp.data[0].maturation_name,
                    value: resp.data[0].maturation_id
                })
                setIrrigationId({
                    label: resp.data[0].irrigation_name,
                    value: resp.data[0].irrigation_id
                })
            })
          .catch(e => {
                if(e.response.data.error){
                    props.history.push('/talhoes/manejo_syngenta')
                }
          })
          
        }
    }

    function onTitle(){
        if(props.match.params.action === 'view'){
            setTitle("Visualização de Talhão")
        }
        else if(props.match.params.action === 'edit'){
            setTitle("Edição de Talhão")
        }
        else if(!props.match.params.action){
            setTitle("Cadastro de Talhão")
        }
    }


    function getProducers(){
        getArea()
        .then(resp => {
            setProducers(resp.data.producer.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            setVarietys(resp.data.variety.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            setMaturations(resp.data.maturation.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            setIrrigations(resp.data.irrigation.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
            setCultivations(resp.data.cultivation.map(desc => ({
                label: desc.name,
                value: desc.id
            })))
        })
    }

    const handleForm=(e)=>{
        setLoading(true)
        const action = props.match.params.action
        const id = props.match.params.id
        e.preventDefault();   
        const data = {
            name: nameArea,
            producer_id: producerId.value,
            farm: farm,
            city_id: cityId.value,
            state_id: stateId.value,
            farm_size_ha: farmSize,
            area_size_ha: areaSize,
            planting_date: plantingDate,
            line_spacing: lineSpacing,
            plants_spacing: plantsSpacing,
            cultivation_id: cultivationId.value,
            maturation_id: maturationId.value,
            irrigation_id: irrigationId.value,
            variety_id: varietyId.value,
            latitude: height,
            longitude: length,
            altitude: level,
            consultant_id: consultantId
        }   

        if(action === 'edit'){
            put_area(data,id)
            .then(resp => {
                setLoading(false)
                setSuccess(resp.data.success)
                setError('')
            })
            .then(resp => (setTimeout(()=>{
                window.location.reload()
            },2000)))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setTimeout(() => {
                    setError('')
                },3000) 
            })

        }else{

            post_area(data)
            .then(resp => {
                setLoading(false)
                setSuccess(resp.data.success)
                setError('')
            })
            .then(resp => (setTimeout(()=>{
               props.history.push(`/talhoes/`)
            },2000)))
            .catch(e => (
                setLoading(false),
                setSuccess(''),
                setError(e.response.data.error),
                setTimeout(() => {
                    setError('')
                },3000)
                
            ))
        }

    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <Style title={title}>   
                {alert && 
                    <div>
                        <Alert type="danger">{alert}</Alert>
                    </div>
                }
                {error && 
                    <div>
                        <Alert type="danger">{error}</Alert>
                    </div>
                }
                {success &&
                    <div>
                        <Alert type="success">{success}</Alert>
                    </div>
                }
                {loading === true && (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                )}
                <div style={{display: loading === true ? ('none'):('block')}}>            
                    <Form onSubmit={handleForm}>
                        <div className="row">    
                            <div className="col-md-6">
                                <Input  
                                    label="Nome Talhão" 
                                    icon="fas fa-hat-cowboy-side" 
                                    name="nome" 
                                    type="text" 
                                    placeholder="Digite o nome do Talhão" 
                                    onChange={(e)=>setNameArea(e.target.value)}
                                    value={nameArea}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />      
                                {error.name ? (
                                    <p style={{color:'red'}}>{error.name}</p>
                                ):(
                                    ''
                                )}                      
                            </div>                   
                            
                            <div className="col-md-6">
                                <label>Produtor</label>
                                <Select
                                    value={producerId}
                                    label="Single select"
                                    options={producers}
                                    onChange={(e)=>setProducerId(e)}
                                    ref={selectProducerRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}              
                                />
                                {producerId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={producerId}
                                        onFocus={() => selectProducerRef.current.focus()}
                                        required={true}
                                    />
                                }                         
                                {error.producer_id ? (
                                    <p style={{color:'red'}}>{error.producer_id}</p>
                                ):(
                                    ''
                                )}                         
                            </div>                       
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <Input  
                                    label="Fazenda" 
                                    icon="fas fa-hat-cowboy-side" 
                                    name="nome" 
                                    type="text" 
                                    placeholder="Fazenda"
                                    onChange={(e)=>setFarm(e.target.value)} 
                                    value={farm}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />                     
                                {error.farm ? (
                                    <p style={{color:'red'}}>{error.farm}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                            <div className="col-md-3">
                                <label>Estado</label>
                                <Select
                                    value={stateId}
                                    label="Single select"
                                    options={states}
                                    onChange={(e)=>setStateId(e)}
                                    ref={selectStateRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}               
                                />
                                {stateId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={stateId}
                                        onFocus={() => selectStateRef.current.focus()}
                                        required={true}
                                    />
                                } 
                                {error.state_id ? (
                                    <p style={{color:'red'}}>{error.state_id}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                            <div className="col-md-3">                                
                                <label>Cidade</label>
                                <Select
                                    value={cityId}
                                    label="Single select"
                                    options={citys}
                                    onChange={(e)=>setCityId(e)}
                                    ref={selectCityRef}   
                                    isDisabled={props.match.params.action === 'view' ? true : false}            
                                />
                                {cityId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={cityId}
                                        onFocus={() => selectCityRef.current.focus()}
                                        required={true}
                                    />
                                } 
                                {error.city_id ? (
                                    <p style={{color:'red'}}>{error.city_id}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Tamanho da fazenda (ha)" 
                                    icon="far fa-window-minimize" 
                                    name="nome" 
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    customInput={Input}
                                    placeholder="Digite o tamanho to)tal da Fazenda"
                                    onChange={(e)=>setFarmSize(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                    value={farmSize}
                                    required={true}
                                    isAllowed={(values) => { 
                                        const {floatValue,formattedValue} = values;  
                                        return formattedValue === "" || floatValue <= 9999999;
                                    }} 
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                /> 
                                {error.farm_size_ha ? (
                                    <p style={{color:'red'}}>{error.farm_size_ha}</p>
                                ):(
                                    ''
                                )}  
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Tamanho do Talhão (ha)" 
                                    icon="fas fa-campground" 
                                    name="nome" 
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    placeholder="Digite o tamanho do Talhão"
                                    customInput={Input}
                                    onChange={(e)=>setAreaSize(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                    value={areaSize}
                                    required={true}
                                    isAllowed={(values) => { 
                                        const {floatValue,formattedValue} = values;  
                                        return formattedValue === "" || floatValue <= 9999999;
                                    }}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                                {error.area_size_ha ? (
                                    <p style={{color:'red'}}>{error.area_size_ha}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                            <div className="col-md-3">
                                <Input 
                                    label="Data do Plantio" 
                                    icon="fas fa-tractor" 
                                    name="data_plantio" 
                                    type="date" 
                                    placeholder="Digite a data do plantio"
                                    customInput={Input}
                                    onChange={(e)=>setPlantingDate(e.target.value)} 
                                    value={plantingDate}
                                    min="1900-01-01" 
                                    max={props.currentDate}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                            </div>
                            <div className="col-md-3">
                                <NumberFormat
                                    label="Espaçamento linhas (m)" 
                                    icon="fas fa-grip-lines-vertical" 
                                    name="nome" 
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    placeholder="Digite o Espaçamento entre as linhas"
                                    customInput={Input}
                                    onChange={(e)=>setLineSpacing(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                    value={lineSpacing}
                                    required={true}
                                    isAllowed={(values) => { 
                                        const {floatValue,formattedValue} = values;  
                                        return formattedValue === "" || floatValue <= 9999999;
                                    }}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                                {error.line_spacing ? (
                                    <p style={{color:'red'}}>{error.line_spacing}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Espaçamento plantas (m)" 
                                    icon="fas fa-seedling" 
                                    name="nome" 
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","} 
                                    placeholder="Digite o Espaçamento entre as plantas"
                                    customInput={Input}
                                    onChange={(e)=>setPlantsSpacing(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                    value={plantsSpacing}
                                    required={true}
                                    isAllowed={(values) => { 
                                        const {floatValue,formattedValue} = values;  
                                        return formattedValue === "" || floatValue <= 9999999;
                                    }}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                                {error.plants_spacing ? (
                                    <p style={{color:'red'}}>{error.plants_spacing}</p>
                                ):(
                                    ''
                                )} 
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Plantas/ha" 
                                    icon="fas fa-spa" 
                                    name="nome" 
                                    id="plantas_ha"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","} 
                                    placeholder=""
                                    onChange={(e)=>setPlants(e.target.value)} 
                                    customInput={Input}
                                    value={plants}
                                    required={true}
                                    disabled={true}
                                />
                            </div> 
                            <div className="col-md-3">                      
                                <label>Cultivar</label>
                                <Select
                                    value={varietyId}
                                    label="Single select"
                                    options={varietys}
                                    onChange={(e)=>setVarietyId(e)}
                                    ref={selectVarietyRef} 
                                    isDisabled={props.match.params.action === 'view' ? true : false}              
                                />
                                {varietyId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={varietyId}
                                        onFocus={() => selectVarietyRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>
                            <div className="col-md-3">
                                <label>Maturação</label>
                                <Select
                                    value={maturationId}
                                    label="Single select"
                                    options={maturations}
                                    onChange={(e)=>setMaturationId(e)}
                                    ref={selectMaturationRef} 
                                    isDisabled={props.match.params.action === 'view' ? true : false}              
                                />
                                {maturationId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={maturationId}
                                        onFocus={() => selectMaturationRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>
                            <div className="col-md-3">                      
                                <label>Irrigação</label>
                                <Select
                                    value={irrigationId}
                                    label="Single select"
                                    options={irrigations}
                                    onChange={(e)=>setIrrigationId(e)}
                                    ref={selectIrrigationRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}               
                                />
                                {irrigationId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={irrigationId}
                                        onFocus={() => selectIrrigationRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>                      
                        </div>
                        <div className="row mt-2"> 
                            <div className="col-md-3">                                              
                                <label>Tratos Culturais</label>
                                <Select
                                    value={cultivationId}
                                    label="Single select"
                                    options={cultivations}
                                    onChange={(e)=>setCultivationId(e)}
                                    ref={selectCultivationRef} 
                                    isDisabled={props.match.params.action === 'view' ? true : false}              
                                />
                                {cultivationId.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={cultivationId}
                                        onFocus={() => selectCultivationRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>                        
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Latitude" 
                                    icon="fas fa-arrows-alt-v" 
                                    name="nome" 
                                    format={"##.######"}
                                    mask="" 
                                    placeholder=""
                                    customInput={Input}
                                    onChange={(e)=>setHeight(e.target.value)} 
                                    value={height}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                            </div>
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="longitude" 
                                    icon="fas fa-globe-americas" 
                                    name="nome" 
                                    format={"##.######"}
                                    mask=""
                                    customInput={Input} 
                                    placeholder=""
                                    onChange={(e)=>setLength(e.target.value)} 
                                    value={length}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                            </div> 
                            <div className="col-md-3">
                                <NumberFormat  
                                    label="Altitude (m)" 
                                    icon="fas fa-arrows-alt-h" 
                                    name="nome" 
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    customInput={Input} 
                                    placeholder=""
                                    onChange={(e)=>setLevel(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                    value={level}
                                    required={true}
                                    disabled={props.match.params.action === 'view'  ? (true):(false)}
                                />
                            </div>                     
                        </div>
                        <div className="text-right">  
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' 
                                ? 
                                    (<Link to={`/talhao/${props.match.params.id}/edit`}
                                        style={
                                                {
                                                    marginRight: '10px',
                                                    cursor: id_role === 1 ? "not-allowed" : "",
                                                    color: id_role === 1 ? "#FFF" : "",
                                                    backgroundColor: id_role === 1 ? "#007BFF" : "",
                                                    borderColor: id_role === 1 ? "#007BFF" : "",
                                                    opacity: id_role === 1 ? .65 : "",
                                                    boxShadow: id_role === 1 ? "none" : "",
                                                }
                                            } 
                                        onClick={id_role === 1 ? e=>e.preventDefault() : ""}
                                        className="btn btn-primary">Editar</Link>) 
                                : 
                                <button className="btn btn-primary" disabled={props.match.params.action === 'view' ? true : false} type="submit">Salvar</button>  
                            }
                            {/* <button disabled={props.match.params.action === 'view'  ? (true):(false)} type="submit" className="btn btn-primary">Salvar</button>  */}
                        </div>
                    </Form>
                </div>         
            </Style>
            <Footer />
        </div>    
    )
}
