import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../../Header/header'
import Sidebar from '../../../../Sidebar/sidebar'
import Footer from '../../../../Footer/footer'
import Table from '../../../../Table/table'
import Thead from '../../../../Table/thead'
import Tbody from '../../../../Table/tbody'
import Tfoot from '../../../../Table/tfoot'
import StylePage from '../../../../Style Page/style_page'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { delPulverization } from '../../../../Services/pulverization'
import {pagination} from '../../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../../Table/pagination'
import Cabecalho from '../../../../Table/cabecalho'
import Alert from '../../../../Alerts/alert'
import ModalExcluir from '../../../../Modal/modal'
import ReactLoading from 'react-loading'
import { Content } from '../../../../Loading/Style'
import Spraying from './Spraying'
import { useDispatch } from 'react-redux'
import { HeaderCSV } from './Docs/HeaderCSV'
import { CSVLink } from "react-csv"
import { getSpraying } from '../../../../Services/pulverization'
import { get_module } from '../../../../Services/moduloAvaliacao'

function GridPulverization(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const [ pulverizationGetExport, setPulverizationGetExport ] = useState([])
    const [module,  setModule] = useState('');

    const consultant = 'consultant.name'
    // const harvest = 'pulverization.harvest'
    const application = 'pulverization.application'
    const method = 'pulverization.id_method_aplication'
    const application_date = 'pulverization.application_date' 
    const spraying = useSelector(state => state.pulverization.spraying);
    console.log(spraying)
    const dispatch = useDispatch();
    const fileUpload = useRef()
    const changeArea = () => {
        dispatch({type:'changeTypeArea', payload:props.match.params.typeArea === 'manejo_em_comum' ? 1 : props.match.params.typeArea === 'manejo_syngenta' ? 2 : 3})
    }

    useEffect(() => {
        changeArea();
        return () => {
            changeArea()   
        }
        
    },[props.match.params.typeArea])
    
    const getCorrecoes = () => {
        props.get();
    }

    useEffect(() => {
        getCorrecoes();
        return () => {
            getCorrecoes()
        }
        
    },[])
    useEffect(() => {
        getModule();
        return () => {
            getModule()
        }
        
    },[])
    
    const getModule = () => {
        get_module().then((resp) =>{
            setModule(resp.data.pests_id);
        });
    }

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delPulverization(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    const download = event => { 
        
        getSpraying(props.current_page, props.qtd_per_page, props.typeArea, props.order_var, props.th_var, props.pesquisa, 'csv')
        .then(resp => {
            
            let res = resp.data.data
            let data = []
            res.forEach((item) => {
                    if(item.applications.length == 0){
                        item.applications.push(0)                        
                    }

                    item.applications.forEach((applications) => {
                        let obj = {...item, applications}
                        data.push(obj)

                    })
                })
            setPulverizationGetExport(data) 
        })
        .then(resp => {
            // console.log(refunds)
            fileUpload.current.link.click()
        });
    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <ModalExcluir
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Pulverização"
                    content="Tem certeza que deseja excluir a pulverização ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"                  
                />
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from || 0} à {props.to || 0} do total de {props.qtd_total ||0}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho
                                buttonCsv={
                                    props.id_role === 1 &&
                                    <React.Fragment>
                                        <button className="btn btn-outline-success btn-sm" onClick={download}>
                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                        </button>                          
                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={HeaderCSV}
                                            hidden={true}
                                            separator={";"}
                                            ref={fileUpload}
                                            data={pulverizationGetExport || []}
                                    
                                        >
                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                        </CSVLink>
                                    </React.Fragment>
                                } 
                                to="#/" 
                                action={props.get} 
                                clear={props.clearPesquisa} 
                                valuePesquisa={props.pesquisa} 
                                value={props.qtd_per_page} 
                                onChange={props.change_qtd_per_page} 
                                changePesquisa={props.changePesquisa} 
                            />
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page)}</Pagination>} title={props.match.params.typeArea === 'manejo_em_comum' ? 'Registros de aplicações - Manejo Comum' : props.match.params.typeArea === 'manejo_syngenta' ? 'Registros de aplicações - Manejo Syngenta' : 'Registros de aplicações - Manejo Produtor'}
                        button={
                            props.id_role !== 1 && (
                                <Link to={`/pulverizacao/${props.match.params.typeArea}`} className="btn btn-primary">Cadastro</Link>

                            )
                        }>
                        <Thead>
                            {props.id_role === 1 && (<th>CONSULTOR<Link to="#/"style={{float:'right'}} onClick={()=>props.order(consultant)}><i className={`right fas fa-sort-amount-${props.th === 'consultant.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>)}
                            {module === 2 ? 
                                <th>TIPO DE APLICAÇÃO<Link to="#/"style={{float:'right'}} 
                                onClick={()=>props.order(method)}><i className={`right fas fa-sort-amount-${props.th === 'pulverization.id_method_aplication' ? 
                                (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th> : <></>
                            }
                            <th>APLICAÇÃO<Link to="#/"style={{float:'right'}} onClick={()=>props.order(application)}><i className={`right fas fa-sort-amount-${props.th === 'pulverization.application' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>DATA<Link to="#/"style={{float:'right'}} onClick={()=>props.order(application_date)}><i className={`right fas fa-sort-amount-${props.th === 'pulverization.application_date' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>Ações</th>
                        </Thead>
                        <Tbody>
                            <Spraying typeArea={props.match.params.typeArea} module={module} id_role={props.id_role} spraying={props.spraying} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            {props.id_role === 1 && (<th>CONSULTOR</th>)}
                            {module === 2 ? <th>TIPO DE APLICAÇÃO</th> : <></>} 
                            <th>APLICAÇÃO</th>                           
                            <th>DATA</th>
                            <th>Ações</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    spraying:state.pulverization.spraying.data,
    current_page:state.pulverization.spraying.current_page,
    last_page:state.pulverization.spraying.last_page,
    from:state.pulverization.spraying.from,
    to:state.pulverization.spraying.to,
    qtd_total:state.pulverization.spraying.total,
    qtd_per_page:state.pulverization.qtd_per_page,
    pesquisa:state.pulverization.pesquisa || '',
    id_role:state.auth.user.id_role,
    asc:state.pulverization.asc,
    th:state.pulverization.th,
    order_var:state.pulverization.order || '',
    th_var:state.pulverization.th || '' ,
    page:state.pulverization.page,
    typeArea:state.pulverization.type_area 


   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa, 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridPulverization)
