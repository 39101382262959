import React, { Component } from 'react'
import { Link } from 'react-router-dom'


export default class cabecalho extends Component {

    enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.props.action()
        } 
    }

    render() {
       
        return (
            <React.Fragment>
                {this.props.children}
                { !this.props.cabecalho ? 
                    <>
                    <div className="col-sm-12 col-md-4">
                        <div className="dataTables_length" id="example1_length">
                            <label> Visualizar  
                                <select style={{marginLeft: '5px'}} value={parseInt(this.props.value)} onChange={this.props.onChange} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">

                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select> Registros
                            </label>
                        </div>
                      
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div id="example1_filter" className="dataTables_filter">
                            <label>Filtrar:
                                <input onKeyPress={this.enterPressed} value={this.props.valuePesquisa} type="text" onChange={this.props.changePesquisa} className="form-control form-control-sm" aria-controls="example1"/>    
                            </label>
                            <Link to={this.props.to} onClick={this.props.action} style={{marginLeft:'25px'}} className="btn btn-outline-info btn-sm">Pesquisar</Link>
                            <Link to={this.props.to} onClick={this.props.clear} style={{marginLeft:'15px', marginRight:'15px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link>
                            {this.props.buttonCsv}
                        </div>
                    </div>
                    </>
                    : ('')
                }

            </React.Fragment>  
        )
    }
}



